<template>

    <div id="container" >
        <div class="padding glass flex gridGap_large fitContent">

            <b>Gewinde</b>
            <div class="flex gridGap">
                <div class="flex padding_small radius" v-for="(t, i) in values.threads" :key="i">
                    <img @click="thread_selected = i" class="radius_small pointer" :style="{backgroundColor: t.color}" src="https://dashboard.pixelprime.de/assets/icons/categories/interaction_clickmap.svg" title="Fläche auswählen">
                </div>
                <div id="threadAdd" class="dark padding_small pointer radius flex fitContent" @click="addThread()">
                    <img style="height: 1.5em; width: 1.5em; margin:auto" src="https://dashboard.pixelprime.de/assets/icons/add.svg" alt="">
                    <p class="marginAuto">Gewinde hinzufügen</p>
                </div>
            </div>

        </div>

        <div id="settings" class="padding glass">
            <div v-if="t">
                <b>Gewinde {{1+thread_selected}}:</b>
                <div class="infoGrid indent" :style="{borderColor: t.color}">
                    <p :class="{blue: t.type == 'drilling'}" class="dark padding_small pointer radius flex fitContent" @click="changeThreadProperty(thread_selected, 'type', 'drilling')">Bohren</p>
                    <p :class="{blue: t.type == 'milling'}" class="dark padding_small pointer radius flex fitContent" @click="changeThreadProperty(thread_selected, 'type', 'milling')">Fräsen</p>
                    <p class="textspacing"></p>
                    <p title="Gewindesteigung">Anzahl:</p>
                    <input type="number" min="1" :value="t.count" @input="changeThreadProperty(thread_selected, 'count', $event.target.value)">
                    <p>mm</p>
                    <p title="Schnittgeschwindigkeit">vc:</p>
                    <input type="number" steps="10" :value="t.vc" @input="changeThreadProperty(thread_selected, 'vc', $event.target.value)">
                    <p>m/min</p>
                    <p v-if="t.type == 'milling'" title="Vorschub pro Schneide">fz:</p>
                    <input v-if="t.type == 'milling'" type="number" min="0.05" :value="t.fz" @input="changeThreadProperty(thread_selected, 'fz', $event.target.value)">
                    <p v-if="t.type == 'milling'">mm/U</p>
                    <p v-if="t.type == 'milling'" title="Zähnezahl">z:</p>
                    <input v-if="t.type == 'milling'" type="number" min="1" :value="t.z" @input="changeThreadProperty(thread_selected, 'z', $event.target.value)">
                    <p v-if="t.type == 'milling'"></p>
                    <p v-if="t.type == 'milling'">Fräserlänge:</p>
                    <input v-if="t.type == 'milling'" type="number" min="1" :value="t.toolLength" @input="changeThreadProperty(thread_selected, 'toolLength', $event.target.value)">
                    <p v-if="t.type == 'milling'">mm</p>
                    <p title="Gewindesteigung">P:</p>
                    <input type="number" min="0.1" :value="t.p" @input="changeThreadProperty(thread_selected, 'p', $event.target.value)">
                    <p>mm</p>
                    <p>Durchmesser:</p>
                    <input type="number" min="0.1" :value="t.diameter" @input="changeThreadProperty(thread_selected, 'diameter', $event.target.value)">
                    <p>mm</p>
                    <p>Länge:</p>
                    <input type="number" min="0.1" :value="t.length" @input="changeThreadProperty(thread_selected, 'length', $event.target.value)">
                    <p>mm</p>
                </div>
                <img class="pointer" @click="removeThread(thread_selected)" src="https://dashboard.pixelprime.de/assets/icons/delete.svg" alt="">
            </div>
        </div>
    </div>

</template>


<!--<template>
    <div id="box">
        <div id="subProcessOptions" class="card" v-if="subProcess_selected">
            <h3 class="card_title">Nötige Prozessparameter</h3>
            <div class="padding" id="gridContainer">
                <div class="padding">
                    <img id="threadImage" src="https://development.primecalc.de/assets/images/milling_thread.svg" alt="">
                </div>
                <div>
                    <p><b>Gewinde</b></p>
                    <div class="flex padding_small radius" v-for="(t, i) in values.thread" :key="i">
                        <p>Länge:</p>
                        <input type="number" min="1" :value="t.length" @input="changeThreadProperty(i, 'length', $event.target.value)">
                        <p>mm</p>
                        <p class="diameter">∅</p>
                        <input type="number" min="1" :value="t.diameter" @input="changeThreadProperty(i, 'diameter', $event.target.value)">
                        <p class="diameter">mm</p>
                        <p>Steigung P:</p>
                        <input type="number" min="0.1" :value="t.p" @input="changeThreadProperty(i, 'p', $event.target.value)">
                        <p>mm</p>
                        <img class="pointer" @click="removeThread(i)" src="https://dashboard.pixelprime.de/assets/icons/delete.svg" alt="">
                    </div>
                    <div id="threadAdd" class="dark padding_small pointer radius flex fitContent" @click="addThread()">
                        <img style="height: 1.5em; width: 1.5em;" src="https://dashboard.pixelprime.de/assets/icons/add.svg" alt="">
                        <p class="marginAuto">Gewinde hinzufügen</p>
                    </div>
                </div>
                <p v-if="verify" id="next" @click="$store.dispatch('subProcesses_next')" class="blue padding_small radius fitContent pointer" style="margin: .5em 0 0 auto;">weiter</p>
            </div>
        </div>

        <div id="manufacturingtime" class="card" v-if="verify">
            <h3 class="card_title">Prozess genauer definieren</h3>
            <div class="padding">
                <p><b>Schnitte pro Gewinde</b></p>
                <input type="number" :value="values.cuts" @input="changeValue('cuts', $event.target.value)">
            </div>
        </div>
    </div>
</template>-->

<script>
export default {
    data() {
        return {
            // threads: [],
            thread_selected: -1
        }
    },
    mounted() {
        const card = document.getElementById("viewerBox").getBoundingClientRect()
        document.getElementById("settings").style.left = card.width-200+"px"
        document.getElementById("settings").style.top = card.height+"px"//this.$store.getters.viewer.renderer.domElement.style.height
    },
    methods: {
        addThread() {
            this.$store.commit("pushSubprocessValue", ["thread", "threads", {
                length: false, 
                diameter: false, 
                type: "milling",
                p: 1,
                vc: 150,
                fz: 0.1,
                z: 4,
                toolLength: 12,
                count: 1,
                color: this.$store.getters.color()
            }])
            this.$store.getters.viewer.renderer.render(this.$store.getters.viewer.scene, this.$store.getters.viewer.camera);
            this.thread_selected = this.values.thread.length
            this.$store.dispatch("refresh")
        },
        removeThread(i) {
            this.$store.commit("deleteSubprocessList", ["thread", "threads", i])
            this.$store.dispatch("refresh")
        },
        changeThreadProperty(tIndex, key, value) {
            this.$store.commit("changeSubprocessListValue", ["thread", "threads", tIndex, key, value])
            this.$store.dispatch("refresh")
        },
        dimensionsFromShape(info) {
            if (info[0].type == "Cylinder") {
                let diameter = Math.round(2*info[0].radius*100)/100
                let length = Math.round((info[0]["V"][1]-info[0]["V"][0])*100)/100
                // console.log(diameter, length);
                this.$store.commit("changeSubprocessListValue", ["thread", "threads", this.thread_selected, 'diameter', diameter])
                this.$store.commit("changeSubprocessListValue", ["thread", "threads", this.thread_selected, 'inside', info[0].orientation])
                this.$store.commit("changeSubprocessListValue", ["thread", "threads", this.thread_selected, 'length', length])
                this.$store.dispatch("refresh")
            }
            else {
                console.log(info[0]);
            }
        }
    },
    computed: {
        values() {
            return this.$store.getters.subProcess("thread")
        },
        t() {
            if(this.thread_selected < 0) return 0
            return this.values.threads[this.thread_selected] || 0
        },
        verify() {
            return this.$store.getters.verify.processes[0].subProcesses["thread"]
        },
    },
    watch: {
        thread_selected() {
            var viewer = this.$store.getters.viewer
            if (this.thread_selected == -1) delete viewer.onHover
            else {
                viewer.onHover = (mesh) => {
                    viewer.resetMaterials()
                    if(viewer.mouseDown) return
                    mesh.object.material = viewer.material_get("hover")
                    // console.log(mesh);
                    // setTimeout(() => {
                    //     mesh.object.material.color.set(0x00426D);
                    // }, 500)
                }
                viewer.onClick = (mesh, button) => {
                    if (button == "left") {
                        delete viewer.faceMaterials[this.t.face]
                        viewer.faceMaterials[mesh.object.name] = viewer.material_get("selected", this.values.threads[this.thread_selected].color)
                        this.dimensionsFromShape(mesh.object.information)

                        this.$store.commit("changeSubprocessListValue", ["thread", "threads", this.thread_selected, 'face', mesh.object.name])
                    }
                    else {
                        delete viewer.faceMaterials[mesh.object.name]
                    }
                    viewer.resetMaterials()                        
                    // mesh.object.material.color.set(0x004200);
                    // console.log(mesh);
                    // setTimeout(() => {
                    //     mesh.object.material.color.set(0x00426D);
                    // }, 500)
                }
                
            }   
        }
    }
}
// export default {
//     data() {
//         return {
//             threads: [],
//         }
//     },
//     methods: {
//         changeValue(key, value) {
//             this.$store.commit("changeSubprocessValue", ["thread", key, value]);
//             this.$store.dispatch("refresh")
//         },  
//         addThread() {
//             this.$store.commit("pushSubprocessValue", ["thread", "thread", {length: 12, diameter: 10, standard: "-"}])
//             this.$store.dispatch("refresh")
//         },
//         removeThread(i) {
//             this.$store.commit("deleteSubprocessList", ["thread", "thread", i])
//             this.$store.dispatch("refresh")
//         },
//         changeThreadProperty(tIndex, key, value) {
//             this.$store.commit("changeSubprocessListValue", ["thread", "thread", tIndex, key, value])
//             this.$store.dispatch("refresh")
//         },
//         diameterNeeded(std) {
//             if(this.standardsWithDiameters.indexOf(std) == -1) return 1
//             else return 0
//         },
//     },
//     computed: {
//         values() {
//             let values = this.$store.getters.values("value")
//             if (!values.cuts) this.changeValue('cuts', 8)
//             return this.$store.getters.values("value")
//         },
//         subProcess_selected() {
//             return this.$store.state.subProcess_selected
//         },
//         verify() {
//             return this.$store.getters.verify.processes[this.$store.state.process_selected].subProcesses[this.$store.state.subProcess_selected]
//         },
//     }
// }
</script>

<style scoped>
    .infoGrid{
        display: grid;
        grid-template-columns: auto auto auto;
        grid-column-gap: .5em;
        grid-row-gap: .25em;
        border-left: 4px solid;
        padding-left: 8px;
        border-radius: 4px 0 0 4px;
    }
    .flex>input{width: 4em;}
    .flex>p:nth-child(3n){margin-right: 1em;}
    #gridContainer{
        display: grid;
        grid-template-columns: auto auto;
        grid-column-gap: 2em;
    }
    #threadImage{
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        height: 12em;
        width: 17em;
        margin: 1em auto;
    }
    #next{
        position: absolute;
        right: .5em;
        bottom: .5em;
    }
    .diameter{
        transform-origin: center;
        transform: scale(1.5);
        margin-right: 5px;
    }
    select{background-color: rgba(0, 0, 0, 0);}
</style>