
var toolActions = {
    evaluateTools() {
        let toolData = {}
        for (const tool in tools.state.toolTimes) {
            toolData[tool] = {
                src: toolActions.getToolImage(tool),
                time: tools.state.toolTimes[tool],
                tool_price: !tools.state.tools[tool] || tools.state.tools[tool].tool_price === undefined ? 5 : tools.state.tools[tool].tool_price,
                tool_life: !tools.state.tools[tool] || tools.state.tools[tool].tool_life === undefined ? 45 : tools.state.tools[tool].tool_life,
            }
        }
        // for (const t in tools) {
        //     toolData[t] = {
        //         src: toolActions.getToolImage(t),
        //         time: 0,
        //         used: 0
        //     }
        //     if (!tools[t].length) continue
            
        //     toolData[t].used = 1
        //     tools[t].forEach(toolProcess => {
        //         toolData[t].time = toolProcess.time
        //     });

        // }
        toolActions.changeToolsInCalculation(toolData)
    },
    getToolImage(tool) {
        let images = {
            roughing: "https://primecalc.de/assets/icons/tools/C.png",
            grooving: "https://primecalc.de/assets/icons/tools/N.png",
            drilling: "https://primecalc.de/assets/icons/tools/bohrer.png",
            insideturning: "https://primecalc.de/assets/icons/tools/V.png",
            threading: "https://primecalc.de/assets/icons/tools/M.png",
            milling: "https://primecalc.de/assets/icons/tools/endmill.png"
        }
        return images[tool] || "https://kalkulation.primecalc.de/assets/images/icons/tools.svg"
    },
    changeToolsInCalculation(toolData) {
        Vue.set(store.state.calculation, "tools", {})
        for (const t in toolData) {
            for (const key in toolData[t]) {
                if (!store.state.calculation.tools[t]) Vue.set(store.state.calculation.tools, t, {})
                Vue.set(store.state.calculation.tools[t], key, toolData[t][key])
            }
        }
    }
}

import Vue from "vue";
import store from '@/store/index.js'
const tools = {
    state: {
        toolTimes: {

        },
        tools: {
            // roughing: [],
            // finishing: [],
            // grooving: [],
            // threading: [],
            // drilling: [],
            // insideturning: [],
            // milling: []
        }
    },
    mutations: {
        tools_evaluate(state) {
            Vue.set(state, "toolTimes", {})
            // let tool;
            let lotsize = store.state.calculation.information.lotsize
            if (!store.state.calculation.other.rejected) store.state.calculation.other.rejected = 0
            lotsize *= 1 + store.state.calculation.other.rejected / 100 
            for (const key in store.getters.tools_raw) {
                state.toolTimes[key] = store.getters.tools_raw[key].time / 60 * lotsize
                // tool = {
                //     time: tools[key].time / 60 * lotsize
                // }
                // if (!state.tools[key]) Vue.set(state.tools, key, [])
                // state.tools[key].push(tool)                
            }
            toolActions.evaluateTools();
        },
        // addToolUse(state, [type, time]) {

        // },
        setToolValue(state, [tool, key, value]) {
            if (!state.tools[tool]) Vue.set(state.tools, tool, {})
            Vue.set(state.tools[tool], key, value)
            toolActions.evaluateTools();
            // if (!store.state.calculation.tools[tool]) Vue.set(store.state.calculation.tools, tool, {})
            // Vue.set(store.state.calculation.tools[tool], key, value)
        }
    },
    getters: {
        tools: () => store.state.calculation.tools
    }
}

export default tools

