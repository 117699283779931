<template>
    <div id="container">
        <div class="padding glass">
            <b>Seiten</b>
            <div class="flex gridGap">
                <div id="gridContainer">
                    <div class="pointer padding_small dark radius fitContent" :class="{blue: selected == 1}" @click="setSides(1)">
                        <!-- <img src="https://development.primecalc.de/assets/images/sides_1.png" alt=""> -->
                        <p>Einseitige Bearbeitung</p>
                    </div>
                    <div class="pointer padding_small dark radius fitContent" :class="{blue: selected == 2}" @click="setSides(2)">
                        <!-- <img src="https://development.primecalc.de/assets/images/sides_0.png" alt=""> -->
                        <p>Beidseitige Bearbeitung</p>
                    </div>
                </div>        
                <div id="manufacturingtime" v-if="selected == 2">
                    <div :class="{blue: values.sidechange_manual}" class="dark padding_small radius fitContent flex">
                        <p>Manuelles Umspannen</p>
                        <input class="marginAuto" type="checkbox" :checked="values.sidechange_manual" @change="changeValue('sidechange_manual', $event.target.checked)">
                    </div>
                </div>

            </div>
            <!-- <div id="subProcessOptions" class="card" v-if="subProcess_selected">
                <h3 class="card_title">Nötige Prozessparameter</h3>
                <div class="padding" id="dimensioning">
                    <p v-if="verify" @click="$store.dispatch('subProcesses_next')" class="blue padding_small radius fitContent pointer" style="margin: .5em 0 0 auto;">weiter</p>
                </div>
            </div> -->

        </div>
        
    </div>
</template>

<script>
export default {
    computed: {
        values() {
            return this.$store.getters.subProcess()
        },
        selected() {
            return this.$store.state.calculation.processes[this.$store.state.process_selected].subProcesses["sides"].values.selected.value
        },
        // verify() {
        //     return this.$store.getters.verify.processes[this.$store.state.process_selected].subProcesses[this.$store.state.subProcess_selected]
        // }
    },
    methods: {
        changeValue(key, value) {
            this.$store.commit("changeSubprocessValue", ["sides", key, value]);
            this.$store.dispatch("refresh")
        }, 
        setSides(value) {
            // if (value == this.selected) this.$store.dispatch('subProcesses_next')
            this.setValue("selected", value)
            this.$store.dispatch("refresh")
        },
        setValue(key, value) {
            this.$store.commit("changeSubprocessValue", ["sides", key, value]);
            this.$store.dispatch("refresh")
        },
        subProcess_selected() {
            return this.$store.state.subProcess_selected
        }
    }
}
</script>

<style scoped>
    #box>.card:first-child{margin-bottom: 2em;}
    #box>.card{width: fit-content;}

    #gridContainer{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 2em;
    }
    img{
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        height: 6em;
        width: 6em;
        margin: .5em auto;
    }
    p{
        text-align: center;
        white-space: nowrap;
    }
</style>