<template>
    <div id="container">
        <div class="padding glass flex gridGap_large">
            <div id="dimensioning">
                <!-- <img src="https://development.primecalc.de/assets/images/dimension.png" alt=""> -->
                <div style="height: fit-content; margin: auto">
                    <p class="textspacing"><b>Bauteilmaße</b></p>
                    <div class="spacingbottom indent">
                        <div class="flex boxspacing" v-for="(pDim, p) in $store.getters.material_dimensionsNeeded" :key="p">
                            <p style="width: 4em" class="spacingright">{{dict(pDim)}}:</p>
                            <input v-if="template.type == 'general'" type="number" :value="values[pDim]" @input="setValue(pDim, $event.target.value)">
                            <p v-else>{{values[pDim]}}</p>
                            <p>mm</p>
                        </div>
                        
                        <!-- <p class="spacingright">Länge:</p>
                        <input type="number" :value="values.length" @input="setValue('length', $event.target.value)" min=1>
                        <p>mm</p>
                        <p class="spacingright">Breite:</p>
                        <input type="number" :value="values.width" @input="setValue('width', $event.target.value)" min=1>
                        <p>mm</p>
                        <p class="spacingright">Höhe:</p>
                        <input type="number" :value="values.height" @input="setValue('height', $event.target.value)" min=1>
                        <p>mm</p> -->
                    </div>
                </div>
                <!-- <p></p>
                <p v-if="verify" @click="$store.dispatch('subProcesses_next')" class="blue padding_small radius fitContent pointer" style="margin: .5em 0 0 auto;">weiter</p> -->
            </div>
            <!-- <div id="subProcessOptions" class="card" v-if="subProcess_selected">
                <h3 class="card_title">Nötige Prozessparameter</h3>
            </div> -->
            <div>
                <p class="textspacing"><b>Halbzeugmaße</b></p>
                <div class="indent">
                    <div class="flex boxspacing" v-for="(pDim, p) in $store.getters.material_dimensionsNeeded" :key="p">
                        <p style="width: 4em" class="spacingright">{{dict(pDim)}}:</p>
                        <input type="number" :value="values[`raw_${pDim}`]" @input="setValue(`raw_${pDim}`, $event.target.value)">
                        <p>mm</p>
                    </div>
                    <!-- <p class="spacingright">Länge:</p>
                    <input type="number" :value="values.raw_length" @input="setValue('raw_length', $event.target.value)">
                    <p>mm</p>
                    <p class="spacingright">Breite:</p>
                    <input type="number" :value="values.raw_width" @input="setValue('raw_width', $event.target.value)">
                    <p>mm</p>
                    <p class="spacingright">Höhe:</p>
                    <input type="number" :value="values.raw_height" @input="setValue('raw_height', $event.target.value)">
                    <p>mm</p> -->
                </div>
            </div>

             <!-- <div class="card" v-if="verify">
                <h3 class="card_title">Prozess genauer definieren</h3>
            </div>  -->
        </div>
        
    </div>
</template>

<!--
<template>
    <div id="box">
        <div id="subProcessOptions" class="card" v-if="subProcess_selected">
            <h3 class="card_title">Nötige Prozessparameter</h3>
            <div class="padding" id="dimensioning">
                <img src="https://development.primecalc.de/assets/images/milling_dimensions.svg" alt="">
                <div style="height: fit-content; margin: auto">
                    <p class="textspacing"><b>Bauteilmaße</b></p>
                    <div class="spacingbottom indent dimGrid">
                        <p class="spacingright">Länge:</p>
                        <input type="number" :value="values.length" @input="setValue('length', $event.target.value)">
                        <p>mm</p>
                        <p class="spacingright">Tiefe:</p>
                        <input type="number" :value="values.width" @input="setValue('width', $event.target.value)">
                        <p>mm</p>
                        <p class="spacingright">Höhe:</p>
                        <input type="number" :value="values.height" @input="setValue('height', $event.target.value)">
                        <p>mm</p>
                    </div>
                </div>
                <p></p>
                <p v-if="verify" @click="$store.dispatch('subProcesses_next')" class="blue padding_small radius fitContent pointer" style="margin: .5em 0 0 auto;">weiter</p>
            </div>
        </div>

        <div class="card" v-if="verify">
            <h3 class="card_title">Halbzeug Wählen</h3>
            <div class="padding">
                <div class="textspacing flex">
                    <p class="marginAuto" style="margin-right: 1em">Halbzeugform:</p>
                    <searchlist :values="raw_typesAvailable" :displayvalues="raw_typesNames" :placeholder="$store.getters.dict(values.raw_type)" :firstsel="0" @select="setValue('raw_type', raw_typesAvailable[raw_typesNames.indexOf($event)])"></searchlist>
                    <select class="blue" style="font-size: 1em" @input="setValue('raw_type', $event.target.value)">
                        <option value="prismatic">Quader</option>
                        <option value="round">Rund</option>
                        <option value="mold"></option>
                    </select>
                </div>
                <div class="indent dimGrid">                    
                    <p v-if="values.raw_type.match(/round/)" class="spacingright">Durchmesser:</p>
                    <input v-if="values.raw_type.match(/round/)" type="number" :value="values.raw_diameter" @input="setValue('raw_diameter', $event.target.value)" :min="values.length">
                    <p v-if="values.raw_type.match(/round/)">mm</p>

                    <p v-if="values.raw_type.match(/prismatic/)" class="spacingright">Länge:</p>
                    <input v-if="values.raw_type.match(/prismatic/)" type="number" :value="values.raw_length" @input="setValue('raw_length', $event.target.value)" :min="values.length">
                    <p v-if="values.raw_type.match(/prismatic/)">mm</p>
                  
                    <p v-if="values.raw_type.match(/prismatic/)" class="spacingright">Tiefe:</p>
                    <input v-if="values.raw_type.match(/prismatic/)" type="number" :value="values.raw_width" @input="setValue('raw_width', $event.target.value)" :min="values.width">
                    <p v-if="values.raw_type.match(/prismatic/)">mm</p>

                    <p class="spacingright">Höhe:</p>
                    <input type="number" :value="values.raw_height" @input="setValue('raw_height', $event.target.value)" :min="values.height">
                    <p>mm</p>
                </div>
            </div>
        </div>
    </div>
</template>
-->

<script>
export default {
    methods: {
        dict(c) {  
            if(!this.$store.state.dict) return c
            return this.$store.state.dict[c] || c
        },
        setValue(key, value) {
            // switch (key) {
            //     case "length":
            //         value <= 0 ? value = 1 : ""
            //         break;
            //     case "diameter":
            //         value <= 0 ? value = 1 : ""
            //         break;
            //     case "raw_length":
            //         value < 0 ? value = 1 : ""
            //             console.log(parseFloat(value) , parseFloat(this.values.length));
            //             parseFloat(value) < parseFloat(this.values.length) ? value = this.values.length : ""
            //             console.log(value);
            //         break;
            //     case "raw_diameter":
            //             value < 0 ? value = 1 : ""
            //             parseFloat(value) < parseFloat(this.values.diameter) ? value = this.values.diameter : ""
            //         break;
            // }
            this.$store.commit("changeSubprocessValue", ["dimensions", key, value]);
            this.$store.dispatch("refresh")
        }
    },
    computed: {
        values() {
            return this.$store.getters.subProcess("dimensions")
            // let values = this.$store.getters.subProcess("dimensions")
            // console.log(this.step);
            // if (values.diameter === "" && this.step.dimensions) {
            //     values.diameter = Math.round(this.step.dimensions.value.diameter)
            //     this.$store.commit("changeSubprocessValue", ["dimensions", "diameter", values.diameter]);
            // }
            // if (values.length === "" && this.step.dimensions) {
            //     values.length = Math.round(this.step.dimensions.value.length)
            //     this.$store.commit("changeSubprocessValue", ["dimensions", "length", values.length]);
            // }
            // return values
        },
        template() {
            return this.$store.getters.subProcess("template")
        },
        verify() {
            return this.$store.getters.verify.processes[0].subProcesses["dimensions"]
        },
    }
}


// export default {
//     data() {
//         return {
//             raw_typesAvailable: ["prismatic",  "round"],
//             raw_typesNames: ["Quader","Rund"]
//         }
//     },
//     methods: {
//         setValue(key, value) {
//             switch (key) {
//                 case "length":
//                 case "diameter":
//                         value <= 0 ? value = 1 : ""
//                     break;
//                 case "raw_length":
//                         value < 0 ? value = 1 : ""
//                         console.log(parseFloat(value) , parseFloat(this.values.length));
//                         parseFloat(value) < parseFloat(this.values.length) ? value = this.values.length : ""
//                         console.log(value);
//                     break;
//                 case "raw_diameter":
//                         value < 0 ? value = 1 : ""
//                         parseFloat(value) < parseFloat(this.values.diameter) ? value = this.values.diameter : ""
//                     break;
//             }
//             this.$store.commit("changeSubprocessValue", ["dimensions", key, value]);
//             this.$store.dispatch("refresh")
//         }
//     },
//     computed: {
//         values() {
//             if(!this.$store.getters.values("value").raw_type) this.setValue("raw_type", "prismatic")
//             return this.$store.getters.values("value")
//         },
//         subProcess_selected() {
//             return this.$store.state.subProcess_selected
//         },
//         verify() {
//             return this.$store.getters.verify.processes[this.$store.state.process_selected].subProcesses[this.$store.state.subProcess_selected]
//         },
//     }
// }
</script>

<style scoped>
    #box>.card:first-child{margin-bottom: 2em;}
    #box>.card{width: fit-content;}
    #dimensioning{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    img{
        margin: auto;
        width: auto;
        /* height: 100%; */
        height: 10em;

    }
    .dimGrid{
        display: grid;
        grid-gap: 4px;
        grid-template-columns: 2fr 4em 1fr;
    }
</style>