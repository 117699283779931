<template>

    <div id="container">
        <div class="padding glass flex gridGap_large">
            <div>
                <input type="file" id="read" multiple="0" accept=".stp, .step, .pdf" @change="selectedFile()">
                <div v-if="!values.existing" class="flex">
                    <label for="read" class="dark padding_small radius pointer fitContent" style="margin-right: 1em">STP einlesen</label>
                    <p v-if="!values.id" @click="step_fromPreview = true" class="dark padding_small radius pointer fitContent" style="margin-right: 1em">Aus Vorlage</p>
                    <!-- <p @click="noStp(1)" class="dark padding_small radius pointer fitContent">keine STP vorhanden</p> -->
                </div>
                <div v-else>
                    <p>{{values.name}}</p>
                    <p @click="noStp()" class="dark padding_small radius pointer fitContent">STP Datei entfernen</p>
                </div>
                <p v-if="errorMsg">{{errorMsg}}</p>
                <p v-if="uploading">Lade hoch...</p>
            </div>

            <div class="dimGrid" v-if="values.volume">
                <!-- <p>Volumen Halbzeug</p>
                <p>{{volume_raw.toFixed(0)}} cm^3</p> -->
                <p>Masse Halbzeug</p>
                <p>{{(volume_raw*7.85).toFixed(0)}} g</p>

                <!-- <p>Volumen Endgeometrie</p>
                <p>{{(values.volume.mesh_volume*10**-3).toFixed(0)}} cm^3</p> -->
                <p>Masse Endgeometrie</p>
                <p>{{(values.volume.mesh_volume*1000**-3*7850000).toFixed(0)}} g</p>
            </div>
            <div class="dimGrid" v-if="values.volume">
                <p>Lange:</p>
                <p>{{values.volume.bounding_box_z_length.toFixed(0)}} mm</p>
                <p>Tiefe:</p>
                <p>{{values.volume.bounding_box_x_length.toFixed(0)}} mm</p>
                <p>Höhe:</p>
                <p>{{values.volume.bounding_box_y_length.toFixed(0)}} mm</p>
            </div>

        </div>
        
    </div>

</template>

<script>
// import threeViewer from "@/store/three/viewer.js";
import apiCall from '@/apiCall'
import s3Upload from '@/s3Upload'

export default {
    data() {
        return {
            uploading: false,
            step_fromPreview: false,
            errorMsg: ""
        }
    },
    mounted() {
        // if (!this.values.existing) {    //  If Volume Information not Saved
        //     let thisObj = this
        // }
        if (this.values.id) { 
            this.$store.commit("viewer_load", [this.$store.getters.company, this.values.id])
        }
        let viewer = this.$store.getters.viewer
        viewer.onClick = (mesh) => {
            viewer.resetMaterials()
            if(viewer.mouseDown) return
            mesh.object.material = viewer.material_get("hover")
            this.$store.commit("debug_3d", [mesh])
        }
    },
    methods: {
        selectedFile() {
            let file = document.getElementById("read").files[0]
            let thisObj = this;
            var reader = new FileReader();
            reader.readAsText(file, "utf-8");
            reader.onload = function (evt) {
                const id = `${new Date().getTime()}${parseInt(Math.random()*1000)}`
                thisObj.$store.commit("changeSubprocessValue", ["template", "id", id]);
                thisObj.$store.commit("changeSubprocessValue", ["template", "name", file.name]);
                thisObj.$store.dispatch("refresh")
                thisObj.uploading = true
                s3Upload(`${thisObj.$store.state.company}/${id}/${id}.stp`, evt.target.result).then(thisObj.getVolume)
            }
            reader.onerror = () => {
                thisObj.errorMsg = "Ein Fehler ist aufgetreten."
            }
        },
        noStp(next=0) {
            this.$store.commit("changeSubprocessValue", ["template", "existing", false]);
            this.$store.commit("changeSubprocessValue", ["template", "volume", false]);
            this.$store.commit("changeSubprocessValue", ["template", "id", false]);
            this.$store.commit("changeSubprocessValue", ["template", "name", false]);
            this.$store.commit("changeSubprocessValue", ["template", "volume_raw", false]);
            this.$store.commit("changeSubprocessValue", ["template", "dimensions", false]);
            this.$store.dispatch("refresh")
            if(next) this.$store.dispatch('subProcesses_next')
        },
        getVolume() {
            this.values.volume = 0
            if (this.values.volume) return
            fetch(this.$store.getters.path_data, {
                method: "POST",
                mode: "no-cors",
                headers: {'Content-Type':'application/x-www-form-urlencoded'},
                body: `method=file_step_getVolume&id=${this.values.id}&company=${this.$store.getters.company}`
            }).then(()=>{
                console.log("ok");
                this.$store.commit("viewer_load", [this.$store.getters.company, this.values.id])
            })
        },

        saveFileInformation() {
            apiCall({
                method: "file_step_saveInformation",
                information: {
                    id: this.values.id,
                    name: this.values.name,
                    volume: this.values.volume,
                    uploader: this.$store.state.username,
                    calculationId: this.$store.state.calculation.id,
                    calculationName: this.$store.state.calculation.name
                },
            })
        }
    },
    computed: {
        values() {
            return this.$store.getters.subProcess("step")
        },
        volume_raw() {
            const delXY = Math.abs(this.values.volume.bounding_box_x_length - this.values.volume.bounding_box_y_length)
            const delYZ = Math.abs(this.values.volume.bounding_box_y_length - this.values.volume.bounding_box_z_length)
            const delXZ = Math.abs(this.values.volume.bounding_box_x_length - this.values.volume.bounding_box_z_length)
            let volume_raw
            let dimensions = {length: "", diameter: ""}
            if (Math.min(delXY, delYZ, delXZ) == delXY) {
                volume_raw = Math.PI*this.values.volume.bounding_box_x_length**2/4*this.values.volume.bounding_box_z_length*10**-3
                dimensions.length = this.values.volume.bounding_box_z_length
                dimensions.diameter = this.values.volume.bounding_box_x_length 
            }
            else if(Math.min(delXY, delYZ, delXZ) == delYZ) {
                volume_raw = Math.PI*this.values.volume.bounding_box_y_length**2/4*this.values.volume.bounding_box_x_length*10**-3
                dimensions.length = this.values.volume.bounding_box_x_length
                dimensions.diameter = this.values.volume.bounding_box_y_length 
            }
            else {
                volume_raw = Math.PI*this.values.volume.bounding_box_x_length**2/4*this.values.volume.bounding_box_y_length*10**-3
                dimensions.length = this.values.volume.bounding_box_y_length
                dimensions.diameter = this.values.volume.bounding_box_x_length 
            }
            //  Change Dimensions Subprocess
            // this.$store.commit("changeSubprocessValue", ["dimensions", "length", Math.round(dimensions.length)]);
            // this.$store.commit("changeSubprocessValue", ["dimensions", "diameter", Math.round(dimensions.diameter)]);
            //  Ratio of material removal (for contour)
            let volume = this.values.volume.mesh_volume/1000
            let volumeRatio = 2
            if(volume/volume_raw > 0.4) volumeRatio = 1
            else if (volume/volume_raw > .75) volumeRatio = 0
            // this.$store.commit("changeSubprocessValue", ["contour", "selected", volumeRatio]);

            // this.$store.commit("changeSubprocessValue", ["step", "volume_raw", volume_raw*1000]);
            // this.$store.commit("changeSubprocessValue", ["step", "dimensions", dimensions]);
            console.log(volumeRatio);
            return volume_raw
        }
    }
}
</script>

<style scoped>
    .card{
        width: 100%!important;
    }

    label, p{
        white-space: nowrap;
    }
    input{
        display: none;
    }
    .dimGrid{
        display: grid;
        grid-gap: 4px;
        grid-template-columns: 2fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        grid-column-gap: 2em;
    }
</style>

