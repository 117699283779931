<template>

    <div class="card">
        <h3 class="card_title">STP Datei einlesen</h3>
        <div class="padding">
            <input type="file" id="read" multiple="0" accept=".stp, .step, .pdf" @change="selectedFile()">
            <div v-if="!values.existing" class="flex">
                <label for="read" class="dark padding_small radius pointer fitContent" style="margin-right: 1em">STP einlesen</label>
                <p @click="noStp(1)" class="dark padding_small radius pointer fitContent">keine STP vorhanden</p>
            </div>
            <div v-else>
                <p>{{values.name}}</p>
                <p @click="noStp()" class="dark padding_small radius pointer fitContent">STP Datei entfernen</p>
            </div>
            <p v-if="errorMsg">{{errorMsg}}</p>
            <p v-if="uploading">Lade hoch...</p>
            <br>
            <div class="dimGrid" v-if="values.volume">
                <p>Volumen Halbzeug</p>
                <p>{{volume_raw.toFixed(0)}} cm^3</p>
                <p>Masse Halbzeug</p>
                <p>{{(volume_raw*7.85).toFixed(0)}} g</p>

                <p>Volumen Endgeometrie</p>
                <p>{{(values.volume.mesh_volume*10**-3).toFixed(0)}} cm^3</p>
                <p>Masse Endgeometrie</p>
                <p>{{(values.volume.mesh_volume*1000**-3*7850000).toFixed(0)}} g</p>
                
                <p>Lange:</p>
                <p>{{values.volume.bounding_box_z_length.toFixed(0)}} mm</p>
                <p>Tiefe:</p>
                <p>{{values.volume.bounding_box_x_length.toFixed(0)}} mm</p>
                <p>Höhe:</p>
                <p>{{values.volume.bounding_box_y_length.toFixed(0)}} mm</p>

            </div>
        </div>
    </div>

</template>

<script>
import apiCall from '@/apiCall'
import s3Upload from '@/s3Upload'

export default {
    data() {
        return {
            uploading: false,
            errorMsg: ""
        }
    },
    methods: {
        selectedFile() {
            let file = document.getElementById("read").files[0]
            let thisObj = this;
            var reader = new FileReader();
            reader.readAsText(file, "utf-8");
            reader.onload = function (evt) {
                const id = `${new Date().getTime()}_${thisObj.$store.state.username}_stp`
                thisObj.$store.commit("changeSubprocessValue", ["step", "id", id]);
                thisObj.$store.commit("changeSubprocessValue", ["step", "name", file.name]);
                thisObj.$store.dispatch("refresh")
                thisObj.uploading = true
                s3Upload(id, evt.target.result).then(thisObj.getVolume)
            }
            reader.onerror = () => {
                thisObj.errorMsg = "Ein Fehler ist aufgetreten."
            }
        },
        noStp(next=0) {
            this.$store.commit("changeSubprocessValue", ["step", "existing", false]);
            this.$store.commit("changeSubprocessValue", ["step", "volume", false]);
            this.$store.commit("changeSubprocessValue", ["step", "id", false]);
            this.$store.commit("changeSubprocessValue", ["step", "volume_raw", false]);
            this.$store.commit("changeSubprocessValue", ["step", "dimensions", false]);
            this.$store.dispatch("refresh")
            if(next) this.$store.dispatch('subProcesses_next')
        },
        getVolume() {
            this.values.volume = 0
            console.log(this.values.id);
            if (this.values.volume) return
            let thisObj = this;
            // console.log("ok");
            fetch(this.$store.getters.path_data, {
                method: "POST",
                mode: "no-cors",
                headers: {'Content-Type':'application/x-www-form-urlencoded'},
                body: "method=file_step_getVolume&id="+this.values.id
            }).then(()=>{
                apiCall({
                    method: "file_step_getVolume",
                    filename: thisObj.values.id,
                }).then(response => {
                    if (response.statusCode == 200) {
                        thisObj.$store.commit("changeSubprocessValue", ["step", "volume", JSON.parse(response.body.info)]);
                        thisObj.$store.commit("changeSubprocessValue", ["step", "existing", true]);
                        // Vue.delete(state.saves, id)
                        thisObj.uploading = false
                    }
                })
            })
        }
    },
    computed: {
        values() {
            return this.$store.getters.values("value")
        },
        volume_raw() {
            const dimensions = {
                raw_length: this.values.volume.bounding_box_y_length,
                raw_width: this.values.volume.bounding_box_z_length,
                raw_height: this.values.volume.bounding_box_x_length
            }
            const volume_raw = dimensions.raw_length * dimensions.raw_width * dimensions.raw_height / 1000
            //  Change Dimensions Subprocess
            this.$store.commit("changeSubprocessValue", ["dimensions", "length", dimensions.raw_length]);
            this.$store.commit("changeSubprocessValue", ["dimensions", "width", dimensions.raw_width]);
            this.$store.commit("changeSubprocessValue", ["dimensions", "height", dimensions.raw_height]);
            //  Ratio of material removal (for contour)
            this.$store.commit("changeSubprocessValue", ["step", "volume_raw", volume_raw*1000]);
            this.$store.commit("changeSubprocessValue", ["step", "dimensions", dimensions]);
            return volume_raw
        }
    }
}
</script>

<style scoped>
    input{
        display: none;
    }
    .dimGrid{
        display: grid;
        grid-gap: 4px;
        grid-template-columns: 2fr 1fr;
        grid-column-gap: 2em;
    }
</style>

