import Vue from "vue";
import apiCall from '@/apiCall'
// import store from '@/store/index.js'

const machines = {
    state: {
        catalogue_selected: "",
        machineInformation: {},
        machines: 0,
        information_raw: {}
    },
    mutations: {
        machineInformation_set(state, data) {
            state.machineInformation[data[0]] = data[1]
        },
        machineInformation_change(state, data) {
            state.machineInformation[data[0]][data[1]].value = data[2]
        },
        machine_setInformation(state, data) {
            state.calculation.machine[data[0]] = data[1]
        },
        changeMachineValue(state, [rootState, process, key, value]) {
            if (!rootState.calculation.processes[process].machine) Vue.set(rootState.calculation.processes[process], "machine", {})
            Vue.set(rootState.calculation.processes[process].machine, key, value)
        },

        machine_changeSelected(state, machine) {
            state.catalogue_selected = machine
        },
        // machineInformation_change(state, data) {
        //     state.machineInformation[data[0]][data[1]] = data[2]
        // },
        // machine_setInformation(state, [rootState, key, value]) {
        //     Vue.set(rootState.calculation[rootState.process_selected].machine, key, value)
        // },
        machine_saveNew(state, machine) {
            let newMachine = {}
            for (const key in machine) {
                newMachine[key] = { value: machine[key]}
            }
            apiCall({
                method: "machine_saveNew",
                machine: newMachine
            }).then(response => {
                if (response.statusCode == 200) {
                    Vue.set(state, "machines", 0)
                }
            })
        },
        machine_delete(state, machine) {
            // const info = store.getters.machine_information(machine)
            apiCall({
                method: "machine_delete",
                machine: machine
            }).then(response => {
                if (response.statusCode == 200) {
                    Vue.set(state, "machines", 0)
                    Vue.set(machine, "machine_selected", "")
                }
            })
        }
    },
    actions: {
    },
    getters: {
        getMachineInformation: (state) => (machine) => {
            if (state.machineInformation[machine]) return state.machineInformation[machine]
            // const formData = new FormData();
            // formData.append('method', "machine_getInformation");
            // formData.append('machine_name', machine);
            // fetch(getters.path_data, {
            //     method: "POST",
            //     body: formData
            // }).then(result => {
            //     return result.json()
            // }).then(result => {
            //     Vue.set(state.machineInformation, machine, result)
            //     Vue.set(state.information_raw, machine, JSON.stringify(result))
            // })
            return 0
        },
        machines: (state) => {
            if (state.machines) return state.machines
            apiCall({
                method: "machines_getAll"
            }).then(response => {
                if (response.statusCode == 200) {
                    Vue.set(state, "machines", Object.keys(response.body))
                    Vue.set(state, "machineInformation", response.body)
                }
            })
            return []
            // const formData = new FormData();
            // formData.append('method', "machine_getNames");
            // fetch(getters.path_data, {
            //     method: "POST",
            //     body: formData
            // }).then(result => {
            //     return result.json()
            // }).then(result => {
            //     Vue.set(state, "machines", result)
            // })
            // return 0
        },
        machines_getCatalogueSelected: (state) => state.catalogue_selected,
        machine_isCustomized: (state) => (machine) => JSON.stringify(state.machineInformation[machine]) != state.information_raw[machine],
    }
}

export default machines