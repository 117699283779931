const price = {
    get(material, information) {
        console.log(material, information);
        const processType = store.state.calculation.processes[0].name
        if (price[processType]) return parseFloat(price[processType](material, information).toFixed(2))
        return ""
    },
    turning(material, information) {
        let price = "";
        const diameter = store.getters.subProcess("dimensions").raw_diameter.value || Math.ceil(store.getters.subProcess("dimensions").diameter.value)
        const length = material.rod_length
        console.log(length, information);
        const type = material.type
        switch (type) {
            case "rod":
                if (information.price[type + "_" + diameter]) {
                    price = information.price[type + "_" + diameter] * length
                }
                else if (information.price["€/cm3"]) {
                    const vol = Math.PI*(diameter**2)/4*length
                    console.log(vol, diameter, length);
                    price = vol * information.price["€/cm3"]
                }
                break;
            case "piece":
                    price = ""
                break;
        }
        console.log(price);
        return price
    }
}




import Vue from "vue";
import apiCall from '@/apiCall'
import store from '@/store/index.js'
const material = {
    state: {
        materials: 0,
        material_selected: "",
        material_raw: 0
    },
    mutations: {
        // materialInformation_set(state, data) {
        //     state.materialInformation[data[0]] = data[1]
        // },
        materialInformation_change(state, data) {
            state.materialInformation[data[0]][data[1]] = data[2]
        },
        material_setInformation(state, [key, value]) {
            Vue.set(store.state.calculation.material, key, value)
        },
        material_changeSelected(state, material) {
            Vue.set(state, "material_selected", material)
            // console.log(state.materials);
            // const existing = state.materials.filter(mat => mat.name == material)
            // if(!existing.length) return
            // Vue.set(store.state.calculation.material, "name", existing[0].name)
            // Vue.set(store.state.calculation.material, "codes", existing[0].codes)
            // Vue.set(store.state.calculation.material, "description", existing[0].description)
            // Vue.set(store.state.calculation.material, "id", existing[0].id)
            // Vue.set(store.state.calculation.material, "isogroup", existing[0].isogroup)
            // Vue.set(store.state.calculation.material, "machinability", existing[0].machinability)
            // // if (!store.state.calculation.material.rod_length) {
            // //     Vue.set(store.state.calculation.material, "rod_length", 6)
            // // }            
            // // //  Get price
            // // console.log(price.get(store, existing[0]));
            // Vue.set(store.state.calculation.material, `price_${store.state.calculation.material.type}`, price.get(existing[0]))
            
            

            // apiCall({
            //     method: "material_get",
            //     material
            // }).then((response) => {
            //     if (response.statusCode == 200) {
            //         for (const key in response.body) {
            //             console.log(key, response.body[key]);
            //             Vue.set(store.state.calculation.material, key, response.body[key])
            //         }
            //         store.dispatch("refresh")
            //     }
            // })
        },
        // getMaterialInformation(state, material) {
        // },
        material_saveNew(state, material) {
            material.id = material.name.toLowerCase()
            apiCall({
                method: "material_saveNew",
                material
            }).then(response => {
                if (response.statusCode == 200) {
                    Vue.set(state, "materials", 0)
                }
            })
        },
        material_delete(state, material) {
            const info = store.getters.material_information(material)
            apiCall({
                method: "material_delete",
                material: info.id.split("~")[1]
            }).then(response => {
                if (response.statusCode == 200) {
                    Vue.set(state, "materials", 0)
                    Vue.set(material, "material_selected", "")
                }
            })
        }
    },
    actions: {
    },
    getters: {
        materials: (state) => {
            if (state.materials) return state.materials
            apiCall({
                method: "material_getAll",
                // material
            }).then(response => {
                if (response.statusCode == 200) {
                    Vue.set(state, "materials", response.body)
                    Vue.set(state, "materials_raw", response.body.map(mat => JSON.stringify(mat)))
                }
            })
            return 0
        },
        material_information: (state) => (name) => {
            if (!name) name = state.material_selected
            const mat = state.materials.filter(mat => mat.name == name)
            return mat[0] || 0
        },
        material_selected: (state) => state.material_selected,
        material_isCustomized: (state, getters) => {
            if (!state.materials_raw || !getters.material) return 0
            const existing = state.materials_raw.filter(mat => mat == JSON.stringify(getters.material))
            return !existing.length
            // JSON.stringify(state.materialInformation[material]) != state.information_raw[material]
        },
        material: () => store.state.calculation.material,
        material_calculatedPrice: () => (material, information) => price.get(material, information),//rootState.calculation.material,
        material_volume: (state, getters)=>{
            const dimensions = getters.subProcess("dimensions")
            let volume = 0
            if (getters.material.shape == "round") {
                const diameter = dimensions.raw_diameter || dimensions.diameter 
                const length = dimensions.raw_length || dimensions.length 
                volume = Math.PI*length*diameter**2/4000
            }
            else if (getters.material.shape == "square" || getters.material.shape == "prismatic") {
                const length = dimensions.raw_length || dimensions.length
                const width = dimensions.raw_width || dimensions.width
                const height = dimensions.raw_height || dimensions.height
                volume = length * width * height / 1000
            }         
            else if (getters.material.shape == "hex") {
                const a = dimensions.raw_diameter/2 || dimensions.diameter/2 
                const length = dimensions.raw_length || dimensions.length 
                return 3/2*a**2*3**0.5*length/1000
            }                            
            else if (getters.material.shape == "hollow") {
                const diameter = dimensions.raw_diameter || dimensions.diameter
                const insideDiameter = dimensions.raw_insideDiameter || dimensions.insideDiameter
                const [di, da] = [diameter, insideDiameter].sort()
                const length = dimensions.raw_length || dimensions.length
                volume = Math.PI * length * (da-di) ** 2 / 4000
            }        
            else volume = "noshape"          
            return volume   //  cm^3
        },

        materials_shapesAvailable: () => {
            let types = []
            switch (store.getters.processType) {
                case "turning":
                    types = [
                        "round",
                        "square",
                        "hex",
                        "hollow"
                    ]
                    break;
                case "milling":
                    types = [
                        "round",
                        "square",
                        "hex",
                        "prism",
                        "hollow"
                    ]
                    break;
            }
            return types
        },
        material_dimensionsNeeded: () => {
            let dims = []
            switch (store.state.calculation.material.shape) {
                case "round":
                    dims = ["diameter", "length"]
                    break;
                case "square":
                case "prism":
                    dims = ["width", "height", "length"]
                    break;
                case "hex":
                    dims = ["diameter", "length"]//, "keywidth", "length"]
                    break;
                case "hollow":
                    dims = ["diameter", "insideDiameter", "length"]
                    break;
                default:
                    break;
            }
            return dims
        },
        // part_dimensionsNeeded: () => {
        //     let dims = []
        //     switch (store.getters.processType) {
        //         case "turning":
        //             dims = ["diameter", "length"]
        //             break;
        //         case "milling":
        //             dims = ["width", "height", "length"]
        //             break;
        //     }
        //     return dims
        // },
    }
}

export default material




