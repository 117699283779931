const awsmobile = {
    Auth: {
        IdentityPoolId: "eu-central-1:64994a61-b110-49c4-ab9e-6b2292bafac8",
        region: "eu-central-1",
        userPoolId: "eu-central-1_VE5kiyBxH",
        userPoolWebClientId: "5q1s6fqc9e3205m63m15ptv9r8",
    }, 
    API: {
        endpoints: [
            {
                name: "primecalc_clientAccess",
                endpoint: "https://ut377oqtyb.execute-api.eu-central-1.amazonaws.com"
            },
            {
                name: "primecalc_getDrawings",
                endpoint: "https://f2ir5jdoeh.execute-api.eu-central-1.amazonaws.com"
            }
        ]
    },
    Storage: {
        AWSS3: {
            bucket: 'arn:aws:s3:::primecalc-files', //REQUIRED -  Amazon S3 bucket name
            region: 'eu-central-1', //OPTIONAL -  Amazon service region
        }
    }
};

export default awsmobile;



    // Auth: {
    //     IdentityPoolId: "eu-central-1:64994a61-b110-49c4-ab9e-6b2292bafac8",
    //     region: "eu-central-1",
    //     userPoolId: "eu-central-1_7vcmLz29L",
    //     userPoolWebClientId: "303jpp2vivtmk36gtk6m5nufrq",
    // }, 