<template>
    <div id="preview-container">
        <div class="box">
            <b>{{dict($store.getters.processType)}}</b>
            <p class="textspacing dark radius padding_small fitContent pointer" @click="$store.commit('deleteProcess', 0)">Anderen Prozess wählen</p>
            <p v-if="step_available != -1" class="dark radius padding_small fitContent pointer" @click="step_available = -1">Step Datei einlesen</p>
        </div>
        <div class="box" v-if="!templateData.selected">
            <div class="box" v-if="step_available == -1" >
                
                <div v-if="!uploading">
                    <label for="read" class="dragArea dark padding_small radius pointer">
                        <div id="dropArea" for="read" class="boxspacing padding_small dark radius" @drop.prevent="selectedFile" @dragover.prevent="drag = 1" @dragleave="drag = 0" :class="{blue:  drag}">
                            STP Datei einlesen
                            <input type="file" name="read" id="read" multiple="0" accept=".stp, .step" @change="selectedFile($event.target)">
                        </div>
                    </label>
                    <p class="boxspacing">oder</p>
                    <p class="padding_small dark radius pointer fitContent" @click="step_available = false">Keine Step vorhanden</p>
                </div>
                <div v-else>
                    Lade hoch...
                </div>

            </div>
            <p>Vorlagen</p>
            <p v-if="!previews.general">Lade vorlagen</p>
            <div class="previewGrid">
                <div v-for="(preview, id) in previews.general" :key="id" class="flex" @click="template_select(id, 'general')">
                    <div>
                        <div class="previewBox pointer" style="position: relative" >
                            <img class="preview" :src="preview[0]" alt="">
                            <div class="shadowGradient"></div>
                            <img class="previewShadow" :src="preview[0]" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <p>Vorige Zeichnungen</p>   
            <p v-if="!previews.specific">Lade vorlagen</p>         
            <div class="previewGrid">
                <div v-for="(preview, id) in previews[$store.getters.company]" :key="id" class="flex" @click="template_select(id, 'specific')">
                    <div>
                        <div class="previewBox pointer" style="position: relative" >
                            <img class="preview" :src="preview[0]" alt="">
                            <div class="shadowGradient"></div>
                            <img class="previewShadow" :src="preview[0]" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="box" v-else>
            <div class="pointer padding_small radius blue flex fitContent" @click="removeTemplate()">
                <img class="pointer" style="width: 2em; height: 2em" src="https://dashboard.pixelprime.de/assets/icons/decline.svg" alt="">
                <p class="marginAuto" style="margin-right: .5em">Andere Vorlage wählen</p>
            </div>
            <div class="previewGrid box">
                <div v-for="(img, p) in selected_perspectives" :key="p">
                    <img class="preview" :src="img" alt="">
                </div>
            </div>

            <div class="box">
                <h3 class="textspacing">Material</h3>
                <div class="indent">
                    <p class="boxspacing">Form des Halbzeuges</p>
                    <div class="indent flex textspacing">
                        <p class="radius pointer padding_small fitContent dark" :class="{blue: material.shape == t}" @click="$store.commit('material_setInformation', ['shape', t])" v-for="t in $store.getters.materials_shapesAvailable" :key="t">{{dict(t)}}</p>
                        <!-- <p class="radius pointer padding_small fitContent dark" :class="{blue: material.type == 'rod'}" @click="changeSelectedProperty('type', 'rod')">{{dict('rod')}}</p> -->
                        <!-- <p class="radius pointer padding_small fitContent dark" :class="{blue: material.type == 'piece'}" @click="changeSelectedProperty('type', 'piece')">{{dict('piece')}}</p> -->
                    </div>          
                    <div>
                        <p class="boxspacing">Maße des Halbzeuges:</p>
                        <div class="indent dimensionContainer flex boxspacing" v-for="(prop, p) in $store.getters.material_dimensionsNeeded" :key="p">
                            <p class="spacingright">{{dict(prop)}}</p>
                            <input type="number" :value="dimensions['raw_'+prop]" @input="$store.commit('changeSubprocessValue', ['dimensions', 'raw_'+prop, $event.target.value])">
                            <p>mm</p>
                        </div>
                    </div>      
                </div>
            </div>
            <div class="box">
                <h3 class="textspacing">Bauteilmaße</h3>
                <div class="indent">
                    <div class="indent dimensionContainer flex boxspacing" v-for="(prop, p) in $store.getters.material_dimensionsNeeded" :key="p">
                        <p class="spacingright">{{dict(prop)}}</p>
                        <input type="number" :value="dimensions[prop]" @input="$store.commit('changeSubprocessValue', ['dimensions', prop, $event.target.value])">
                        <p>mm</p>
                    </div>
                </div>
            </div>
            <div class="box" v-if="templateData.type == 'general'">
                <h3 class="textspacing">Volumenverhältnis Teil/Halbzeug - Korrektur</h3>
                <div class="flex">
                    <p style="width: 3em">{{($store.getters.subProcess('roughing').ratio*100).toFixed(0)}}%</p>
                    <input style="width: 18em" type="range" min="1" max="100" :value="$store.getters.subProcess('roughing').ratio*100" @input="$store.commit('changeSubprocessValue', ['roughing', 'ratio', $event.target.value/100])">
                </div>
            </div>
            <div class="box">
                <p v-for="t in todo" :key="t">{{t}}</p>
                <p @click="next()" :class="{'pointer blue': !todo.length}" class="darkfitContent radius padding_small fitContent">Weiter</p>
            </div>
            <!-- <div v-if="$store.getters.processType == 'turning'">

            </div>
            <div v-else-if="$store.getters.processType == 'milling'">
                Fräsen
            </div> -->

        </div>
    </div>
</template>

<script>
import s3Upload from '@/s3Upload'
export default {
    name: "Preview3D",
    data() {
        return {
            existingDrawings: false, 
            step_available: -1,
            uploading: false,
            drag: 0
        }
    },
    mounted() {
        if(!Object.keys(this.previews.specific).length) this.$store.commit("previews_get", this.$store.getters.processType)
        // document.getElementById("dropArea").addEventListener("drop", (event) => {
        //     event.preventDefault()
        //     console.log(event);
        // })
    },
    methods: {   
        dict(c) {  
            if(!this.$store.state.dict) return c
            return this.$store.state.dict[c] || c
        },
        template_select(id, type) {
            this.$store.commit("changeSubprocessValue", ["template", "selected", true]);
            this.$store.commit("changeSubprocessValue", ["template", "type", type]);
            this.$store.commit("changeSubprocessValue", ["template", "id", id]);
            this.$store.commit("previewPerspectives_get", [this.templateData.id, this.templateData.type])
        },
        removeTemplate() {
            this.$store.commit("changeSubprocessValue", ["template", "selected", false]);
            this.$store.commit("changeSubprocessValue", ["template", "type", 0]);
            this.$store.commit("changeSubprocessValue", ["template", "selected", 0]);
        },
        selectedFile(event) {
            let file
            if(document.getElementById("read").files[0]) file = document.getElementById("read").files[0]
            else file = event.dataTransfer.files

            let thisObj = this;
            var reader = new FileReader();
            reader.readAsText(file, "utf-8");
            reader.onload = function (evt) {
                console.log(evt);
                const id = `${new Date().getTime()}${parseInt(Math.random()*1000)}`
                thisObj.$store.commit("changeSubprocessValue", ["template", "id", id]);
                thisObj.$store.commit("changeSubprocessValue", ["template", "name", file.name]);
                thisObj.$store.commit("changeSubprocessValue", ["step", "name", file.name]);
                thisObj.$store.commit("changeSubprocessValue", ["step", "id", id]);
                thisObj.$store.dispatch("refresh")
                thisObj.uploading = true
                s3Upload(`${thisObj.$store.state.company}/${id}/${id}.stp`, evt.target.result).then(()=>{
                    thisObj.uploading = false
                    fetch(thisObj.$store.getters.path_data, {
                        method: "POST",
                        mode: "no-cors",
                        headers: {'Content-Type':'application/x-www-form-urlencoded'},
                        body: `method=file_step_getVolume&id=${id}&company=${thisObj.$store.getters.company}`
                    }).then(()=>{
                        thisObj.downloadCreatedFromStep()
                        // this.$store.commit("viewer_load", [this.$store.getters.company])
                    })
                })
            }
            reader.onerror = () => {
                thisObj.errorMsg = "Ein Fehler ist aufgetreten."
            }
        },
        downloadCreatedFromStep() {
            this.$store.commit("changeSubprocessValue", ["step", "uploaded", true]);
            this.$store.getters.viewer.loaded = (processed) => {
                this.$store.commit("changeSubprocessValue", ["step", "uploaded", true]);
                this.$store.commit("changeSubprocessValue", ["step", "volume", processed.shell.mesh_volume/1000]);
                this.$store.commit("changeSubprocessValue", ["step", "volume_raw", this.volume_raw(processed.shell)/1000]);
                this.$store.commit("changeSubprocessValue", ["step", "dimensions", processed.shell.dimensions]);
                this.$store.commit("changeSubprocessValue", ["template", "createPreview", true]);
            }
            this.$store.commit("loadFile", [this.templateData.id, "specific"])
            this.template_select(this.templateData.id, "specific")                 

        },
        volume_raw(shell) {
            if (this.$store.getters.processType == "turning") {
                const delXY = Math.abs(shell.bounding_box_x_length - shell.bounding_box_y_length)
                const delYZ = Math.abs(shell.bounding_box_y_length - shell.bounding_box_z_length)
                const delXZ = Math.abs(shell.bounding_box_x_length - shell.bounding_box_z_length)
                let dimensions = {length: "", diameter: ""}
                let volume_raw
                if (Math.min(delXY, delYZ, delXZ) == delXY) {
                    volume_raw = Math.PI*shell.bounding_box_x_length**2/4*shell.bounding_box_z_length
                    dimensions.length = shell.bounding_box_z_length
                    dimensions.diameter = shell.bounding_box_x_length 
                }
                else if(Math.min(delXY, delYZ, delXZ) == delYZ) {
                    volume_raw = Math.PI*shell.bounding_box_y_length**2/4*shell.bounding_box_x_length
                    dimensions.length = shell.bounding_box_x_length
                    dimensions.diameter = shell.bounding_box_y_length 
                }
                else {
                    volume_raw = Math.PI*shell.bounding_box_x_length**2/4*shell.bounding_box_y_length
                    dimensions.length = shell.bounding_box_y_length
                    dimensions.diameter = shell.bounding_box_x_length 
                }
                this.$store.commit("changeSubprocessValue", ["dimensions", "diameter", dimensions.diameter]);
                this.$store.commit("changeSubprocessValue", ["dimensions", "length", dimensions.length]);
                this.$store.commit("changeSubprocessValue", ["contour", "ratio", 1-shell.mesh_volume/volume_raw]);
                //  Change Dimensions Subprocess
                // this.$store.commit("changeSubprocessValue", ["dimensions", "length", Math.round(dimensions.length)]);
                // this.$store.commit("changeSubprocessValue", ["dimensions", "diameter", Math.round(dimensions.diameter)]);
                //  Ratio of material removal (for contour)
                return volume_raw
            }
            else if (this.$store.getters.processType == "milling") {
                const volume_raw = shell.bounding_box_x_length*shell.bounding_box_y_length*shell.bounding_box_z_length
                this.$store.commit("changeSubprocessValue", ["contour", "ratio", 1-shell.mesh_volume/volume_raw]);
                return volume_raw
            }
            else return 0
            // let volumeRatio = 2
            // if(volume/volume_raw > 0.4) volumeRatio = 1
            // else if (volume/volume_raw > .75) volumeRatio = 0
            // // this.$store.commit("changeSubprocessValue", ["contour", "selected", volumeRatio]);

            // // this.$store.commit("changeSubprocessValue", ["step", "volume_raw", volume_raw*1000]);
            // // this.$store.commit("changeSubprocessValue", ["step", "dimensions", dimensions]);
            // console.log(volumeRatio);
            // return volume_raw
        },
        next() {
            if (!this.todo.length) {                
                this.$store.commit("changeSubprocessValue", ["template", "defined", true]);
                this.$store.commit("loadFile", [this.templateData.id, this.templateData.type])
            }
        }
    },
    computed: {   
        previews() {
            return this.$store.getters.previews
        },
        templateData() {
            return this.$store.getters.subProcess("template")
        },
        step() {
            return this.$store.getters.subProcess("step")
        },
        selected_perspectives() {
            // if(this.previews.general[this.template_selected].length <= 1) this.$store.commit("previewPerspectives_get", this.template_selected)
            return this.previews[this.templateData.type][this.templateData.id]
        },
        material() {
            if(!this.$store.getters.material.shape) {
                if(this.$store.getters.processType == "milling") {
                    this.$store.commit("material_setInformation", ["shape", "prism"])
                }
                else {
                    this.$store.commit("material_setInformation", ["shape", "round"])
                }
            }
            return this.$store.getters.material
        },
        dimensions() {
            return this.$store.getters.subProcess("dimensions")            
        },
        // materials_shapesAvailable() {
        //     let types = []
        //     switch (this.$store.getters.processType) {
        //         case "turning":
        //                 types = [
        //                     "round",
        //                     "square",
        //                     "hex",
        //                     "hollow"
        //                 ]
        //             break;
        //         case "milling":
        //                 types = [
        //                     "round",
        //                     "square",
        //                     "hex",
        //                     "prism",
        //                     "hollow"
        //                 ]
        //             break;
        //     }
        //     return types
        // },
        // material_dimensionsNeeded() {
        //     let dims = []
        //     switch (this.material.shape) {
        //         case "round":
        //             dims = ["diameter", "length"]
        //             break;
        //         case "square":
        //             dims = ["width", "height", "length"]
        //             break;
        //         case "hex":
        //             dims = ["diameter", "length"]//, "keywidth", "length"]
        //             break;
        //         case "hollow":
        //             dims = ["diameter", "insideDiameter", "length"]
        //             break;
        //         default:
        //             break;
        //     }
        //     return dims
        // },
        // part_dimensionsNeeded() {
        //     let dims = []
        //     switch (this.$store.getters.processType) {
        //         case "turning":
        //                 dims = ["diameter", "length"]                
        //             break;
        //         case "milling":  
        //                 dims = ["width", "height", "length"]         
        //             break;
        //     }
        //     return dims
        // },

        todo() {
            let msg = []
            this.$store.getters.material_dimensionsNeeded.map(dim => {
                if (!this.dimensions[dim]){
                    msg.push(`${this.dict(dim)} des Bauteils fehlt.`)
                }
            })
            return msg
        }
    },
    watch: {
        // template_selected() {
        //     console.log(this.templateData);
        //     this.$store.commit("previewPerspectives_get", [this.template_selected, this.templateData.type])
        //     this.$emit("selected", this.template_selected)
        // }
    }
}
</script>

<style scoped>
    #preview-container{
        width: 100%;
        height: auto;
    }
    .previewGrid{
        overflow: hidden;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 2em;
    }
    @media screen and (min-width: 1600px) { 
        .previewGrid{
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        }
    }
    @media screen and (min-width: 2560px) { 
        .previewGrid{
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        }
    }
    @media screen and (min-width: 3840px) { 
        .previewGrid{
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        }
    }
    img{
        width: 100%;
        height: 100%;
    }

    #read{
        display: none;
    }
    label{
        opacity: 0;
    }
    #dropArea{
        height: 10em;
        width: 20em;
    }

    .dimensionContainer>*:nth-child(1){width: 8em;}
    .dimensionContainer>*:nth-child(2){width: 4em;}
    .dimensionContainer>*:nth-child(3){width: 2em;}

    .preview{
        position: relative;
        z-index: 5;
        transform: translateY(0);
        transition: .5s;
    }
    .previewShadow{
        position: absolute;
        z-index: 1;
        top: 0em;
        left: 0em;
        transform: translate(2px,5px);
        filter: saturate(0) blur(4px) brightness(0.25);
        opacity: .45;
        transition: .5s;
    }
    .shadowGradient{
        position: absolute;
        z-index: 2;
        width: calc(100% + 2em);
        height: calc(100% + 2em);
        top: -1em;
        left: -1em;
        background: linear-gradient(30deg, rgba(255, 255, 255, 0) 0%,rgb(255, 255, 255) 75%);
        background-blend-mode: multiply;
    }
    .previewBox:hover>.preview{
        transform: translateY(-5px);
    }
    .previewBox:hover>.previewShadow{
        transform: translate(2px,9px);
        filter: saturate(0) blur(6px) brightness(0.25);
        opacity: 0.25;
    }
    
</style>