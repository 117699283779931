<template>
    <div id="viewer-container">
        
    </div>
</template>

<script>
import apiCall from '@/apiCall'
export default {
    name: "ThreeViewer",
    mounted() {
        this.$store.commit("viewer_load", [this.$store.getters.company, this.template.id])
        if(!this.$store.getters.viewer.id) this.$store.commit("loadFile", [this.template.id, this.template.type])
        if(this.$store.getters.subProcess("template").createPreview == true) {
            console.log("createPreview");
            this.$store.commit("changeSubprocessValue", ["template", "createPreview", false]);
            this.$store.getters.viewer.loaded = () => {
                this.$store.getters.viewer.preview_create()
                this.$store.getters.viewer.loaded = 0
                //  Save Step Preview
                apiCall({ 
                    method: "file_step_saveInformation",
                    information: {
                        id: this.template.id,
                        name: this.template.name,
                        processType: this.$store.getters.processType,
                        dimensions: this.$store.getters.subProcess("step").dimensions
                    }
                })
            }
        }
    },
    computed: {
        template() {
            return this.$store.getters.subProcess("template")
        }
    }
}
</script>

<style scoped>
    #viewer-container{
        width: 100%;
        height: auto;
    }
    canvas{
        outline: none;
        vertical-align: middle;
        border-radius: 1em;
    }
    
</style>