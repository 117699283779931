const contour = {
    get() {
        if (!contour.checkData()) return
        
        const vol_raw = store.getters.material_volume
            - grooves.volume() / 1000
            - inside.volume() / 1000
            - radialHole.volume() / 1000
        //contour.getRawVolume(process.subProcesses["dimensions"].values)
        let ratio
        // console.log(store.getters.subProcess('step'));
        // if (!store.getters.subProcess('step').existing) {
        // }
        ratio = store.getters.subProcess('contour').ratio
        // else ratio = 1

        if (!store.getters.subProcess('contour').tools) return
        let tools = store.getters.subProcess('contour').tools
        let ratioSum = 0
        tools.map(tool => ratioSum += tool.ratio)
        const ratios = tools.map(tool => tool.ratio / ratioSum)

        let vol, Q, toolTime//, parameters
        // let time = 0
        tools.forEach((tool, t) => {
            // parameters = contour.getTurningParameters(tool)
            // if (!parameters) {
            //     // this.commit("setVerifyMessage", ["process", "Wählen Sie Schnittparameter.", !parameters])
            //     return
            // }
            vol = vol_raw * ratios[t] * ratio
            Q = tool.ap * tool.f * tool.vc // cm^3/min
            toolTime = vol / Q * 60
            toolTime *= store.getters.getTimeCorrectionFactor(tool.type)

            store.commit("addToManufactureChain", { time: toolTime, tool: tool.type, type: tool.type })
            if (toolTime) processes.state.tools[tool.type] = { time: toolTime }

            // time += toolTime
        });
    },
    checkData() {
        let missing = []
        // if (!store.getters.subProcess('chamfer').areal || !store.getters.subProcess('chamfer').edge) return 0
        if (!store.getters.subProcess('step').existing) {
            if (!store.getters.subProcess('contour').ratio) missing.push(`Schruppanteil`)
        }
        if (store.getters.subProcess('contour').tools) store.getters.subProcess('contour').tools.forEach((tool, t) => {
            if (!tool.ap) missing.push(`ap${t + 1}`)
            if (!tool.vc) missing.push(`vc${t + 1}`)
            if (!tool.f) missing.push(`f${t + 1}`)
            // if (!thread.length) missing.push(`Länge für Gewinde${t + 1}`)
            // if (!thread.diameter) missing.push(`Durchmesser für Gewinde${t + 1}`)
            // if (!thread.p) missing.push(`P für Gewinde${t + 1}`)
            // if (thread.type == "milling") {
            //     if (!thread.fz) missing.push(`fz für Gewinde${t + 1}`)
            //     if (!thread.z) missing.push(`z für Gewinde${t + 1}`)
            //     if (!thread.toolLength) missing.push(`Fräserlänge für Gewinde${t + 1}`)
            // }
            // if (!thread.vc) missing.push(`Prozesstyp für Gewinde${t + 1}`)
        });
        if (missing.length) {
            missing = `Fehlende Informationen für Schruppen: ${missing.join(", ")}`
            store.commit("setVerifyMessage", ["processes", missing, 1])
        }
        return !missing.length ? 1 : 0
    },

    // getTimeWithStep(process, step, context, state, pState) {
    //     let parameters = contour.getContourParameters(process)
    //     const volume = step.values.volume.value.mesh_volume
    //     const volume_raw = store.getters.material_volume//step.values.volume_raw.value
    //     const Q = parameters.ap * parameters.f * parameters.vc
    //     const volRatio = 1 - Math.tanh(process.subProcesses.grooves.values.grooves_contour.value / 5) / 3
    //     let time = volRatio * (volume_raw - volume) / 1000 / Q * 60
    //     //  Grooving? -> Reduce Volume Time

    //     time *= context.getters.getTimeCorrectionFactor("roughing")
    //     pState.tools.roughing = { parameters, time }
    //     let changeTime = state.calculation.processes[0].machine.toolchange_time || 4
    //     context.commit("addToManufactureChain", { time, tool: "roughing", type: "roughing" })
    //     if (process.subProcesses.contour.values.toolcount) {
    //         for (let i = 0; i < process.subProcesses.contour.values.toolcount.value - 1; i++) {
    //             context.commit("addToManufactureChain", { time: changeTime, type: "toolchange" })
    //         }
    //     }
    //     // //  Finishing
    //     // let region = contour.getContourRegion(process)
    //     // if (!process.subProcesses.contour.values.finishing_use.value) return [time]
    //     // parameters = contour.getFinishingPrameters(process)
    //     // let path = contour.getFinishingPath(region, parameters)
    //     // let finishTime = contour.contour_calculateTime(parameters, path) * context.getters.getTimeCorrectionFactor("finishing")
    //     // if (finishTime) {
    //     //     pState.tools.finishing = { parameters, finishTime }
    //     //     context.commit("addToManufactureChain", { time: finishTime, tool: "finishing", type: "finishing" })
    //     // }


    //     return [time]// + finishTime]
    // },
    // //  Without Step
    // getTimeWithoutStep() {
    //     // const process = store.state.calculation.processes[store.state.process_selected]
    //     const vol_raw = store.getters.material_volume//contour.getRawVolume(process.subProcesses["dimensions"].values)
    //     let ratio
    //     if (store.getters.subProcess('step').existing) {
    //         ratio = store.getters.subProcess('contour').ratio
    //     }
    //     else ratio = 1

    //     if (!store.getters.subProcess('contour').tools) return
    //     let tools = store.getters.subProcess('contour').tools
    //     let ratioSum = 0
    //     tools.map(tool => ratioSum += tool.ratio)
    //     const ratios = tools.map(tool => tool.ratio / ratioSum)

    //     let vol, Q, toolTime//, parameters
    //     let time = 0
    //     tools.forEach((tool, t) => {
    //         // parameters = contour.getTurningParameters(tool)
    //         // if (!parameters) {
    //         //     // this.commit("setVerifyMessage", ["process", "Wählen Sie Schnittparameter.", !parameters])
    //         //     return
    //         // }
    //         vol = vol_raw * ratios[t] * ratio
    //         Q = tool.ap * tool.f * tool.vc // cm^3/min
    //         toolTime = vol / Q * 60
    //         toolTime *= store.getters.getTimeCorrectionFactor(tool.type)

    //         store.commit("addToManufactureChain", { time: toolTime, tool: tool.type, type: tool.type })
    //         if (toolTime) processes.state.tools[tool.type] = { parameters: tool, time: toolTime }

    //         time += toolTime
    //     });
    //     return [time]
    // },



    
    // getContourParameters(process) {
    //     let parameters = {
    //         ap: 2,
    //         f: .2,
    //         vc: 150
    //     }
    //     if (process.subProcesses.contour.values.ap) parameters.ap = process.subProcesses.contour.values.ap.value
    //     if (process.subProcesses.contour.values.f) parameters.f = process.subProcesses.contour.values.f.value
    //     if (process.subProcesses.contour.values.vc) parameters.vc = process.subProcesses.contour.values.vc.value
    //     return parameters
    // },
    // getFinishingPrameters(process) {
    //     let parameters = {
    //         count: 1,
    //         f: .1,
    //         vc: 150
    //     }
    //     if (process.subProcesses.contour.values.finishing_count) parameters.count = process.subProcesses.contour.values.finishing_count.value
    //     if (process.subProcesses.contour.values.finishing_f) parameters.f = process.subProcesses.contour.values.finishing_f.value
    //     if (process.subProcesses.contour.values.finishing_vc) parameters.vc = process.subProcesses.contour.values.finishing_vc.value
    //     return parameters
    // },
    // Without Step
    // getTimeWithoutStep(process, context, state, pState) {
    //     let region = contour.getContourRegion(process)
    //     if (!region) return [0, "No Region"]
    //     let parameters = contour.getContourParameters(process)
    //     let path = contour.getContourPath(region, parameters)
    //     let time = contour.contour_calculateTime(parameters, path)
    //     time *= context.getters.getTimeCorrectionFactor("roughing")
    
    //     pState.tools.roughing = {parameters, time}
    //     let changeTime = state.calculation.processes[0].machine.toolchange_time || 4
    //     context.commit("addToManufactureChain", { time, tool: "roughing", type: "roughing" })
    //     if (process.subProcesses.contour.values.toolcount) {
    //         for (let i = 0; i < process.subProcesses.contour.values.toolcount.value-1; i++) {
    //             context.commit("addToManufactureChain", { time: changeTime, type: "toolchange" })
    //         }
    //     }
    //     //  Finishing
    //     if (!process.subProcesses.contour.values.finishing_use.value) return [time]
    //     parameters = contour.getFinishingPrameters(process)
    //     path = contour.getFinishingPath(region, parameters)
    //     let finishTime = contour.contour_calculateTime(parameters, path) * context.getters.getTimeCorrectionFactor("finishing") 
    //     pState.tools.finishing = { parameters, finishTime }
    //     context.commit("addToManufactureChain", { time: finishTime, tool: "finishing", type: "finishing" })
    //     // if (process.subProcesses.contour.values.toolcount) {
    //     //     for (let i = 0; i < process.subProcesses.contour.values.toolcount.value - 1; i++) {
    //     //         context.commit("addToManufactureChain", { time: changeTime, type: "toolchange" })
    //     //     }
    //     // }
    
    //     return [time + finishTime]

    // },
    // getContourRegion(process) {
    //     let values = process.subProcesses.contour.values
    //     let dimensions = process.subProcesses.dimensions.values
    //     let removalRatio = values.selected.value

    //     let dRatio = 1
    //     if (dimensions.raw_diameter && dimensions.raw_diameter.value > dimensions.diameter.value) dRatio = dimensions.raw_diameter.value / dimensions.diameter.value


    //     let region = 0;
    //     switch (String(removalRatio)) {
    //         case "0":
    //             region = [[0, dRatio], [.1, dRatio], [.1, .9], [.8, .9], [.8, .8], [1, .8], [1, 0]]
    //             break;
    //         case "1":
    //             region = [[0, dRatio], [.1, dRatio], [.1, .75], [.8, .75], [.8, .5], [1, .5], [1, 0]]
    //             break;
    //         case "2":
    //             region = [[0, dRatio], [.1, dRatio], [.1, .5], [.5, .5], [.5, .25], [1, .25], [1, 0]]
    //             break;
    //     }
    //     if (!region) return region
    //     let [diameter, length] = [process.subProcesses.dimensions.values.diameter.value, process.subProcesses.dimensions.values.length.value]
    //     if (process.subProcesses.dimensions.values.raw_length.value > length) length = process.subProcesses.dimensions.values.raw_length.value
    //     region.forEach(point => {
    //         point[0] *= length
    //         point[1] *= diameter
    //     });
    //     return region
    // },
    // getContourPath(region, parameters) {
    //     let path = []
    //     region = p.pointsArrayToObj(region)
    //     let domain, int, lastV, connectingPath
    //     let lastPoint, vertex, onContour
    //     function getConnectingPath(point, lastPoint, verticies) {
    //         let startI, endI, connectingVerts, vertex, lastV
    //         startI = -1;
    //         endI = -1;
    //         for (let i = 1; i < verticies.length; i++) {
    //             vertex = verticies[i]
    //             lastV = verticies[i - 1]
    //             if (p.pointOnLine(point, [vertex.z, vertex.x], [lastV.z, lastV.x])) startI = i
    //             if (p.pointOnLine(lastPoint, [vertex.z, vertex.x], [lastV.z, lastV.x])) endI = i
    //         }
    //         if (startI == endI) {
    //             return [lastPoint]
    //         }
    //         if (startI > -1 && endI > -1) {
    //             connectingVerts = []
    //             if (endI - startI < verticies.length / 2) {
    //                 for (let i = startI; i <= endI; i++) {
    //                     connectingVerts.push([verticies[i].z, verticies[i].x])
    //                 }
    //             }
    //             else {
    //                 for (let i = endI; i <= startI; i++) {
    //                     connectingVerts.push([verticies[i].z, verticies[i].x])
    //                 }
    //             }
    //             return connectingVerts
    //         }
    //         else return [lastPoint]
    //     }

    //     lastPoint = 0
    //     //  Get Domain
    //     domain = contour.contour_domain(region)
    //     let layers = Math.floor((domain[1][1] - domain[1][0]) / parameters.ap + 1)
    //     //  For Each Layer
    //     let x
    //     for (let l = 0; l <= layers; l++) {
    //         x = domain[1][1] - l * parameters.ap  //  New X Level
    //         //  Actual Turning
    //         x < domain[1][0] ? x = domain[1][0] : ""
    //         !l ? x = domain[1][1] - .001 : ""

    //         //  Get Intersect Line on Start
    //         for (let i = 0; i < region.length; i++) {
    //             vertex = region[i]
    //             if (!i) continue
    //             lastV = region[i - 1]
    //             int = p.lineIntersect([vertex.z, vertex.x], [lastV.z, lastV.x], [domain[0][1], x], [domain[0][1] + 100, x])

    //             onContour = 0
    //             region.forEach((vertex, i) => {
    //                 if (!i) return
    //                 let lastV = region[i - 1]
    //                 onContour += p.pointOnLine(int, [vertex.z, vertex.x], [lastV.z, lastV.x])
    //             });

    //             if (p.round(int[0], 3) == p.round(domain[0][1], 3)) continue
    //             if (!onContour) continue
    //             if (!lastPoint) {
    //                 lastPoint = int
    //                 break;
    //             }
    //             if (isNaN(int[0]) || isNaN(int[1])) continue

    //             path.push({ x: x, z: domain[0][1] + .5, type: "rapid" })
    //             path.push({ x: x, z: domain[0][1] + .5, type: "cut" })
    //             path.push({ x: x, z: int[0], type: "cut" })
    //             if (lastPoint[0] != int[0]) {
    //                 connectingPath = getConnectingPath(lastPoint, [int[0], x], region).reverse()
    //                 connectingPath.forEach(vertex => {
    //                     path.push({ x: vertex[1], z: vertex[0], type: "cut" })
    //                 });
    //             }
    //             if (!isNaN(path[path.length - 1].x) && !isNaN(path[path.length - 1].z)) {
    //                 path.push({ x: path[path.length - 1].x + .25, z: path[path.length - 1].z, type: "cut" })
    //                 path.push({ x: path[path.length - 1].x, z: domain[0][1] + .5, type: "rapid" })
    //             }
    //             lastPoint = int
    //         }
    //     }
    //     return path
    //     // this.duration = this.time_calculate(this.paths[0])
    // },
    // getFinishingPath(region, parameters) {
    //     region = p.pointsArrayToObj(region)
    //     if(!region) return 0
    //     let path = []
    //     for (let i = 0; i < parameters.count; i++) {           
    //         path.push({ z: region[0].z, x: region[0].x + 5, type: "rapid" })
    //         path.push({ z: region[0].z, x: region[0].x + .5, type: "rapid" })
    //         path.push({ z: region[0].z, x: region[0].x, type: "cut" })
    //         region.forEach(v => {
    //             path.push({ z: v.z, x: v.x, type: "cut" })
    //         });
    //     }
    //     return path
    // },
    // contour_calculateTime(parameters, path) {
    //     let time = { cut: 0, rapid: 0 }
    //     let n, vf
    //     let [f, vc] = [parameters.f, parameters.vc]
    //     let n_max = 4000
    //     let rapid = 400

    //     if (!path || !path.length) return 0
    //     path.forEach((v, vi) => {
    //         if (!vi) return
    //         vi = path[vi - 1]
    //         let distance = p.dist([v.x, v.z], [vi.x, vi.z])
    //         if (distance == undefined || isNaN(distance)) return
    //         if (v.type == "cut") {
    //             n = vc * 1000 / (Math.PI * Math.abs(v.x))
    //             n > n_max ? n = n_max : ""

    //             vf = f * n
    //             time.cut += distance / vf * 60
    //         }
    //         else if (v.type == "rapid") {
    //             time.rapid += distance / rapid
    //         }
    //         else if (v.type == "drilling") {
    //             n = vc * 1000 / (Math.PI * this.parameters.diameter)
    //             n > n_max ? n = n_max : ""

    //             vf = f * n
    //             time.cut += distance / vf * 60
    //         }
    //         else if (v.type == "partRotate") {
    //             time.rapid += (.5 / 360 * v.angle)
    //         }
    //         else if (v.type == "dwell") {
    //             n = vc * 1000 / (Math.PI * this.parameters.diameter)
    //             n > n_max ? n = n_max : ""
    //             time.cut += v.rotations / n * 60
    //         }
    //     });
    //     return time.cut + time.rapid
    // },
    // contour_domain(contour) {
    //     let domain = [[Infinity, -Infinity], [Infinity, -Infinity]]
    //     if (!contour.length) return
    //     contour.forEach(vertex => {
    //         vertex.z < domain[0][0] ? domain[0][0] = vertex.z : ""
    //         vertex.z > domain[0][1] ? domain[0][1] = vertex.z : ""
    //         vertex.x < domain[1][0] ? domain[1][0] = vertex.x : ""
    //         vertex.x > domain[1][1] ? domain[1][1] = vertex.x : ""
    //     });
    //     return domain
    // },
    // getRawVolume(dim) {
    //     // if (1) {    //  Type
    //     //     return dim.length.value * dim.length.diameter**2/4000
    //     // }
    //     return dim.length.value * dim.diameter.value ** 2 / 4000
    // },
    // getTurningParameters(tool) {
    //     if (!tool.ap) return 0
    //     if (!tool.f) return 0
    //     if (!tool.vc) return 0
    //     return tool
    // },
    // With step
}
const sides = {
    get(context, state, pState) {
        if (!state) return
        let values = state.calculation.processes[state.process_selected].subProcesses.sides.values
        let selected = values.selected.value
        let materialType = state.calculation.material.type
        let time = 0
        let processes = []

        if (!selected || selected === 1) return [0]
        if (materialType == "rod" || materialType == "m") {
            pState.tools.partoff = { time:6 }
            processes.push({ time, tool: "partoff", type: "partoff" })
        }
        if (values.sidechange_manual && values.sidechange_manual.value) time = 45
        else time = state.calculation.processes[0].machine.piecechange_time || 6
        time *= context.getters.getTimeCorrectionFactor("sidechange")
        processes.push({ time, type: "sidechange" })
        
        if (processes.length > 1) {
            context.commit("addToManufactureChain", {parallel: 1, processes})
        }
        else if (processes.length == 1) {
            context.commit("addToManufactureChain", processes[0])
        }
        // if (selected == 2) {
        //     if (values.sidechange_manual && values.sidechange_manual.value) {
        //         time = 45
        //     }
        //     else time = state.calculation.machines.changetime || 4
        // }
        // if (state.calculation.material.type != "piece") {
        // }
        // context.commit("addToManufactureChain", { time , type:"sidechange"})
        return [time, " (Seitenwechsel)"] 
    },
}
const grooves = {
    get() {
        let time = 0
        const _grooves = store.getters.subProcess("grooves").grooves
        const dimensions = store.getters.subProcess("dimensions")
        if (!_grooves || !_grooves.length || !grooves.checkData()) return [0]

        _grooves.forEach((groove, g) => {
            // const parameters = grooves.getParameters(groove)
            let path = [
                { type: "rapid", z: 0, x: groove.diameter + .5, },
                { type: "cut", z: 0, x: groove.diameter + .5, },
                { type: "cut", z: 0, x: groove.diameter - 2 * groove.depth, },
                { type: "cut", z: 0, x: groove.diameter + .5, }
            ]
            const cutsForWidth = Math.ceil(groove.width / groove.toolWidth)
            time += groove.count * cutsForWidth * grooves.calculateTime(path, groove)
            let nextRapid = g < _grooves.length - 1 ? 1 : 0  //  Eilgang nächste Nut
            time += (nextRapid + (groove.count - 1)) * grooves.calculateTime([
                { type: "rapid", z: 0, x: groove.diameter + 1.5 },
                { type: "rapid", z: dimensions.length / 2, x: groove.diameter + 1.5 },
            ], groove)
        });

        time *= store.getters.getTimeCorrectionFactor("grooving")
        store.commit("addToManufactureChain", { time, tool: "grooving", type: "grooving" })
        // if (toolTime) processes.state.tools[tool.type] = { parameters: tool, time: toolTime }
        if (time) processes.state.tools["grooving"] = { time }

        // console.log(time);
        // return [time]
    },
    // checkGroovesData(_grooves) {
    //     let check = 1
    //     _grooves.forEach((groove, g) => {
    //         let missing = ""
    //         if (!groove.count) missing += "Anzahl "
    //         if (!groove.width) missing += "Breite "
    //         if (groove.diameter == "") missing += "Durchmesser "
    //         if (!groove.depth) missing += "Tiefe "
    //         if(missing != "") {
    //             missing = `Fehlende Informationen für Nut ${g + 1}: `+missing
    //             store.commit("setVerifyMessage", ["processes", missing, 1])
    //             check = 0
    //         }
    //     });
    //     return check
    // },
    checkData() {
        let missing = []
        if (store.getters.subProcess("grooves").grooves) store.getters.subProcess("grooves").grooves.forEach((groove, t) => {
            if (!groove.count) missing.push(`Anzahl für Stechen${t + 1}`)
            if (!groove.diameter) missing.push(`Durchmesser für Stechen${t + 1}`)
            if (!groove.depth) missing.push(`Nuttiefe für Stechen${t + 1}`)
            if (!groove.width) missing.push(`Nutbreite für Stechen${t + 1}`)
            if (!groove.toolWidth) missing.push(`Werkzeugbreite für Stechen${t + 1}`)
            if (!groove.f) missing.push(`f für Stechen${t + 1}`)
            if (!groove.vc) missing.push(`vc für Stechen${t + 1}`)
        });
        if (missing.length) {
            missing = `Fehlende Informationen für Stechen: ${missing.join(", ")}`
            store.commit("setVerifyMessage", ["processes", missing, 1])
        }
        return !missing.length ? 1 : 0
    },
    volume() {
        if (!grooves.checkData()) return 0
        let vol = 0
        if (store.getters.subProcess('grooves').grooves) store.getters.subProcess('grooves').grooves.forEach(groove => {
            const [da, di] = [parseFloat(groove.diameter) + parseFloat(groove.depth), groove.diameter]
            vol += Math.PI * (da ** 2 - di ** 2) / 4 * groove.width
        });
        if (store.getters.material_volume < vol) return 0
        return vol
    },
    getTimeWithStep(process, step, context, state, pState) {
        let time_radial, time_axial, time_contour
        
        let values = state.calculation.processes[state.process_selected].subProcesses.grooves.values
        let diameter = parseFloat(state.calculation.processes[state.process_selected].subProcesses.dimensions.values.diameter.value)
        let parameters = grooves.getParameters(values)

        time_radial = grooves.groovesRadial(parseInt(values.grooves_radial.value), parameters, diameter)
        time_radial *= context.getters.getTimeCorrectionFactor("grooving_radial")
        // context.commit("addToManufactureChain", { time: time_radial, tool: "grooving", type: "grooving" })
        
        time_axial = grooves.groovesAxial(parseInt(values.grooves_axial.value), parameters, diameter)
        time_axial *= context.getters.getTimeCorrectionFactor("grooving_axial")
        // context.commit("addToManufactureChain", { time: time_axial, tool: "grooving", type: "grooving" })
        //  Contour Grooves:
        // Grooving
        time_contour = 0
        if (process.subProcesses.grooves.values.grooves_contour.value != 0) {
            const volume = step.values.volume.value.mesh_volume
            const volume_raw = store.getters.material_volume//step.values.volume_raw.value
            const Q = parameters.width * parameters.f * parameters.vc
            const volRatio = Math.tanh(process.subProcesses.grooves.values.grooves_contour.value / 5) / 3
            time_contour = volRatio * (volume_raw - volume) / 1000 / Q * 60
            time_contour *= context.getters.getTimeCorrectionFactor("grooving_contour")
            // context.commit("addToManufactureChain", { time: time_contour, tool: "grooving", type: "grooving" })
        }
        
        let time = time_radial + time_axial + time_contour
        time *= context.getters.getTimeCorrectionFactor("grooving")
        context.commit("addToManufactureChain", { time, tool: "grooving", type: "grooving" })
        pState.tools.grooving = { parameters, time }

        return [parseFloat(time)]
    },
    // getParameters(values) {
    //     let parameters = {
    //         width: 2,
    //         f: .1,
    //         vc: 130
    //     }
    //     if (values.toolWidth) parameters.width = values.toolWidth
    //     if (values.f) parameters.f = values.f
    //     if (values.vc) parameters.vc = values.vc
    //     return parameters
    // },

    calculateTime(path, parameters) {
        let time = { cut: 0, rapid: 0 }
        let n, vf
        let [f, vc] = [parameters.f, parameters.vc]
        let n_max = 4000
        let rapid = 400

        if (!path || !path.length) return 0
        path.forEach((v, vi) => {
            if (!vi) return
            vi = path[vi - 1]
            let distance = p.dist([v.x, v.z], [vi.x, vi.z])
            if (distance == undefined || isNaN(distance)) return
            if (v.type == "cut") {
                n = vc * 1000 / (Math.PI * Math.abs(v.x))
                n > n_max ? n = n_max : ""

                vf = f * n
                time.cut += distance / vf * 60
            }
            else if (v.type == "rapid") {
                time.rapid += distance / rapid
            }
            else if (v.type == "drilling") {
                n = vc * 1000 / (Math.PI * this.parameters.diameter)
                n > n_max ? n = n_max : ""

                vf = f * n
                time.cut += distance / vf * 60
            }
            else if (v.type == "partRotate") {
                time.rapid += (.5 / 360 * v.angle)
            }
            else if (v.type == "dwell") {
                n = vc * 1000 / (Math.PI * this.parameters.diameter)
                n > n_max ? n = n_max : ""
                time.cut += v.rotations / n * 60
            }
        });
        return time.cut + time.rapid
    }
}
const finishing = {
    get() {
        let time = 0
        const areas = store.getters.subProcess("finishing").areas
        // const dimensions = store.getters.subProcess("dimensions")
        if (!areas || !areas.length || !finishing.checkData(areas)) return [0]

        areas.forEach((area, g) => {
            const parameters = finishing.getParameters(area)
            let path = [
                { z: .5, x: area.diameter, type: "rapid" },
                { z: .5, x: area.diameter, type: "cut" },
                { z: area.length, x: area.diameter, type: "cut" },
                { z: area.length, x: area.diameter + .5, type: "cut" },
                { z: area.length, x: area.diameter + .5, type: "rapid" },
                { z: .5, x: area.diameter + .5, type: "rapid" },
            ]
            time += area.count * finishing.calculateTime(path, parameters)
            let nextRapid = g < areas.length - 1 ? 1 : 0  //  Eilgang nächste Nut
            time += (nextRapid + (area.count - 1)) * 0.5
        });

        time *= store.getters.getTimeCorrectionFactor("finishing")
        store.commit("addToManufactureChain", { time, tool: "finishing", type: "finishing" })
        if (time) processes.state.tools["finishing"] = { time }
        // if (time) processes.state.tools[tool.type] = { parameters: tool, time }

        // console.log(time);
        // return [time]
    },
    checkData(data) {
        let check = 1
        data.forEach((area, h) => {
            let missing = ""
            if (!area.count) missing += "Anzahl "
            if (area.diameter == "") missing += "Durchmesser "
            if (!area.length) missing += "Länge "
            if (!area.r) missing += "Schneidradius "
            if (!area.rz || !area.f) missing += "Vorschub oder Rz "
            if (missing != "") {
                missing = `Fehlende Informationen für Schlichten ${h + 1}: ` + missing
                store.commit("setVerifyMessage", ["processes", missing, 1])
                check = 0
            }
        });
        return check
    },
    getParameters(values) {
        let parameters
        parameters = {
            diameter: 10,
            f: .1,
            vc: 130
        }
        // if (values.rz && values.r && !values.f) values.f = (8*values.r*values.rz/1000)**0.5
        if (values.diameter) parameters.diameter = values.diameter
        if (values.f) parameters.f = values.f
        if (values.vc) parameters.vc = values.vc
        
        return parameters
    },
    calculateTime(path, parameters) {
        let time = { cut: 0, rapid: 0 }
        let n, vf
        let [f, vc] = [parameters.f, parameters.vc]
        let n_max = 4000
        let rapid = 400

        if (!path || !path.length) return 0
        path.forEach((v, vi) => {
            if (!vi) return
            vi = path[vi - 1]
            let distance = p.dist([v.x, v.z], [vi.x, vi.z])
            if (distance == undefined || isNaN(distance)) return
            if (v.type == "cut") {
                n = vc * 1000 / (Math.PI * Math.abs(v.x))
                n > n_max ? n = n_max : ""

                vf = f * n
                time.cut += distance / vf * 60
            }
            else if (v.type == "rapid") {
                time.rapid += distance / rapid
            }
            else if (v.type == "drilling") {
                n = vc * 1000 / (Math.PI * this.parameters.diameter)
                n > n_max ? n = n_max : ""

                vf = f * n
                time.cut += distance / vf * 60
            }
            else if (v.type == "partRotate") {
                time.rapid += (.5 / 360 * v.angle)
            }
            else if (v.type == "dwell") {
                n = vc * 1000 / (Math.PI * this.parameters.diameter)
                n > n_max ? n = n_max : ""
                time.cut += v.rotations / n * 60
            }
        });
        return time.cut + time.rapid
    }
}
const inside = {
    get() {
        let time = 0
        const holes = store.getters.subProcess("inside").holes
        const turning = store.getters.subProcess("inside").turning
        let drillTypeTimes = {}
        // const dimensions = store.getters.subProcess("dimensions")
        if (holes && inside.checkData("holes", holes)) {
            holes.forEach((hole, h) => {
                const parameters = inside.getParameters('hole',hole)
                let path = [
                    { z: .5, x: 0, type: "rapid" },
                    { z: .5, x: 0, type: "drilling" },
                    { z: -hole.depth, x: 0, type: "drilling" },
                    { z: -hole.depth, x: 0, type: "dwell", rotations: 3 },
                    { z: -hole.depth, x: 0, type: "rapid" },
                    { z: .5, x: 0, type: "rapid" }
                ]
                const holeTime = hole.count * inside.calculateTime(path, parameters)
                time += holeTime
                let nextRapid = h < hole.length - 1 ? 1 : 0  //  Eilgang nächste Nut
                time += (nextRapid + (hole.count - 1)) * 0.5
                
                if (!drillTypeTimes[`drilling_${hole.diameter}`]) drillTypeTimes[`drilling_${hole.diameter}`] = 0
                drillTypeTimes[`drilling_${hole.diameter}`] += holeTime
                // if (time) processes.state.tools[`drilling_${hole.diameter}`] = { time: holeTime * store.getters.getTimeCorrectionFactor("insideDrilling") }
            });
            for (const drillType in drillTypeTimes) {
                processes.state.tools[drillType] = { time: drillTypeTimes[drillType] * store.getters.getTimeCorrectionFactor("insideDrilling") }
            }
            time *= store.getters.getTimeCorrectionFactor("insideDrilling")
            store.commit("addToManufactureChain", {time, tool: "drill", type: "insideDrilling" })
            
        }
        time = 0
        if (turning && inside.checkData("turning", turning)) {
            turning.forEach((turn, t) => {
                const parameters = inside.getParameters('turning',turn)
                const [di, da, ap] = [turn.diameter_inside, turn.diameter, parameters.ap]
                const cuts = Math.ceil((da-di)/2/ap)
                let path = []
                for (let c = 1; c <= cuts; c++) {
                    path.push({ z: .5, x: di + 2*c * ap, type: "rapid" },)
                    path.push({ z: .5, x: di + 2*c * ap, type: "cut" },)
                    path.push({ z: turn.length, x: di+2*c*ap, type: "cut" },)
                    path.push({ z: turn.length, x: di+2*c*ap - .5, type: "cut" },)
                    path.push({ z: turn.length, x: di+2*c*ap - .5, type: "rapid" },)
                    path.push({ z: .5, x: di+2*c*ap - .5, type: "rapid" },)
                }
                time += turn.count * inside.calculateTime(path, parameters)
                let nextRapid = t < turn.length - 1 ? 1 : 0  //  Eilgang nächste Nut
                time += (nextRapid + (turn.count - 1)) * 0.5
            });
            time *= store.getters.getTimeCorrectionFactor("insideTurning")
            if (time) processes.state.tools[`insideTurning`] = { time }
            // if (time) processes.state.tools[tool.type] = { parameters: tool, time }
            store.commit("addToManufactureChain", { time, tool: "V", type: "insideTurning" })
        }
    },
    checkData(type, data) {
        let check = 1
        if (type == "hole") {
            data.forEach((hole, h) => {
                let missing = ""
                if (!hole.count) missing += "Anzahl "
                if (hole.diameter == "") missing += "Durchmesser "
                if (!hole.depth) missing += "Tiefe "
                if (missing != "") {
                    missing = `Fehlende Informationen für Innebohrung ${h + 1}: ` + missing
                    store.commit("setVerifyMessage", ["processes", missing, 1])
                    check = 0
                }
            });
        }
        else if (type == "turning") {
            data.forEach((turning, t) => {
                let missing = ""
                if (!turning.count) missing += "Anzahl "
                if (!turning.diameter_inside) missing += "Innendurchmesser "
                if (turning.diameter == "") missing += "Durchmesser "
                if (!turning.length) missing += "Länge "
                if (missing != "") {
                    missing = `Fehlende Informationen für Ausdrehen ${t + 1}: ` + missing
                    store.commit("setVerifyMessage", ["processes", missing, 1])
                    check = 0
                }
            });
        }
        return check
    },
    volume() {
        if (!inside.checkData()) return 0
        let vol = 0
        if (store.getters.subProcess('inside').holes) store.getters.subProcess('inside').holes.forEach(hole => {
            vol += Math.PI * hole.diameter ** 2 / 4 * hole.depth
        });
        if (store.getters.subProcess('inside').turning) store.getters.subProcess('inside').turning.forEach(turn => {
            const [da, di] = [parseFloat(turn.diameter), parseFloat(turn.diameter_inside)]
            vol += Math.PI * (da ** 2 - di ** 2) / 4 * turn.length
        });
        if (store.getters.material_volume < vol) return 0
        return vol
    },
    getParameters(type, values) {
        let parameters
        if (type == "hole") {
            parameters = {
                diameter: 10,
                f: .1,
                vc: 130
            }
            if (values.diameter) parameters.diameter = values.diameter
            if (values.f) parameters.f = values.f
            if (values.vc) parameters.vc = values.vc
        }
        else if (type == "turning") {
            parameters = {
                ap: .5,
                f: .1,
                vc: 130
            }
            if (values.ap) parameters.ap = values.ap
            if (values.f) parameters.f = values.f
            if (values.vc) parameters.vc = values.vc
        }
        return parameters
    },

    // drilling(vComplexity, vDimensions, parameters) {
    //     let depth = parseFloat(vComplexity.inside_depth.value/100*vDimensions.length.value)
    //     //  Path
    //     let path = [
    //         { z: .5, x: 0, type: "rapid" },
    //         { z: .5, x: 0, type: "drilling" },
    //         { z: -depth, x: 0, type: "drilling" },
    //         { z: -depth, x: 0, type: "dwell", rotations: 3},
    //         { z: -depth, x: 0, type: "rapid" },
    //         { z: .5, x: 0, type: "rapid" }
    //     ]
    //     return inside.calculateTime(path, parameters)
    // },
    // insideTurning() {//parameters, vDimensions, valuesComplexity) {
    //     // let inside_diameter = valuesComplexity.inside_diameter.value
    //     // //  Region
    //     // let region
    //     // if (valuesComplexity.selected.value == "turning") region = [[0, -0.1], [0, 0.5], [-.8, 0.5], [-.8, 1]]
    //     // else if (valuesComplexity.selected.value == "complex") region = [[0, -0.1], [0, 0.25], [-.6, 0.25], [-.6, 0.5], [-.8, 0.5], [-.8, 1]]
    //     // region.forEach(point => {
    //     //     point[0] *= vDimensions.length.value * valuesComplexity.inside_depth.value/100
    //     //     point[1] *= vDimensions.diameter.value - inside_diameter
    //     //     point[1] += parseFloat(inside_diameter)
    //     // });

    //     // let path = contour.getContourPath(region, parameters)
    //     // return inside.calculateTime(path, parameters)
    //     return 10
    // },

    calculateTime(path, parameters) {
        let time = { cut: 0, rapid: 0 }
        let n, vf
        let [f, vc] = [parameters.f, parameters.vc]
        let n_max = 4000
        let rapid = 400

        if (!path || !path.length) return 0
        path.forEach((v, vi) => {
            if (!vi) return
            vi = path[vi - 1]
            let distance = p.dist([v.x, v.z], [vi.x, vi.z])
            if (distance == undefined || isNaN(distance)) return
            if (v.type == "cut") {
                n = vc * 1000 / (Math.PI * Math.abs(v.x))
                n > n_max ? n = n_max : ""

                vf = f * n
                time.cut += distance / vf * 60
            }
            else if (v.type == "rapid") {
                time.rapid += distance / rapid
            }
            else if (v.type == "drilling") {
                n = vc * 1000 / (Math.PI * parameters.diameter)
                n > n_max ? n = n_max : ""

                vf = f * n
                time.cut += distance / vf * 60
            }
            else if (v.type == "partRotate") {
                time.rapid += (.5 / 360 * v.angle)
            }
            else if (v.type == "dwell") {
                n = vc * 1000 / (Math.PI * parameters.diameter)
                n > n_max ? n = n_max : ""
                time.cut += v.rotations / n * 60
            }
        });
        return time.cut + time.rapid
    }
}
const thread = {
    get() {
        let time = 0
        const threads = store.getters.subProcess("thread").threads
        if (!threads || !threads.length || !thread.checkData(threads)) return [0]

        threads.forEach((thre, g) => {
            const parameters = thread.getParameters(thre)
            let path = []
            for (let c = 0; c < thre.cuts; c++) {
                path.push({ z: .5, x: thre.diameter, type: "rapid" })
                path.push({ z: .5, x: thre.diameter, type: "cut" })
                path.push({ z: thre.length, x: thre.diameter, type: "cut" })
                path.push({ z: thre.length, x: thre.diameter + .5, type: "cut" })
                path.push({ z: thre.length, x: thre.diameter + .5, type: "rapid" })
                path.push({ z: .5, x: thre.diameter + .5, type: "rapid" }                )
            }
            time += thre.count * (thread.calculateTime(path, parameters) + .25 * thre.cuts)
            let nextRapid = g < threads.length - 1 ? 1 : 0  //  Eilgang nächste Nut
            time += (nextRapid + (thre.count - 1)) * 0.5
        });

        time *= store.getters.getTimeCorrectionFactor("thread")
        store.commit("addToManufactureChain", { time, tool: "thread", type: "thread" })
        if (time) processes.state.tools[`threading`] = { time }
        // if (time) processes.state.tools[tool.type] = { parameters: tool, time }

        // console.log(time);
        // return [time]
    },
    checkData(data) {
        let check = 1
        data.forEach((area, h) => {
            let missing = ""
            if (!area.count) missing += "Anzahl "
            if (area.diameter == "") missing += "Durchmesser "
            if (!area.length) missing += "Länge "
            if (!area.p) missing += "Steigung "
            if (missing != "") {
                missing = `Fehlende Informationen für Gewinde ${h + 1}: ` + missing
                store.commit("setVerifyMessage", ["processes", missing, 1])
                check = 0
            }
        });
        return check
    },
    getParameters(values) {
        let parameters
        parameters = {
            cuts: 8,
            diameter: 10,
            f: 1,
            vc: 130
        }
        // if (values.rz && values.r && !values.f) values.f = (8*values.r*values.rz/1000)**0.5
        if (values.cuts) parameters.cuts = values.cuts
        if (values.diameter) parameters.diameter = values.diameter
        if (values.p) parameters.f = values.p
        if (values.vc) parameters.vc = values.vc

        return parameters
    },
    calculateTime(path, parameters) {
        let time = { cut: 0, rapid: 0 }
        let n, vf
        let [f, vc] = [parameters.f, parameters.vc]
        let n_max = 4000
        let rapid = 400

        if (!path || !path.length) return 0
        path.forEach((v, vi) => {
            if (!vi) return
            vi = path[vi - 1]
            let distance = p.dist([v.x, v.z], [vi.x, vi.z])
            if (distance == undefined || isNaN(distance)) return
            if (v.type == "cut") {
                n = vc * 1000 / (Math.PI * Math.abs(v.x))
                n > n_max ? n = n_max : ""

                vf = f * n
                time.cut += distance / vf * 60
            }
            else if (v.type == "rapid") {
                time.rapid += distance / rapid
            }
            else if (v.type == "drilling") {
                n = vc * 1000 / (Math.PI * this.parameters.diameter)
                n > n_max ? n = n_max : ""

                vf = f * n
                time.cut += distance / vf * 60
            }
            else if (v.type == "partRotate") {
                time.rapid += (.5 / 360 * v.angle)
            }
            else if (v.type == "dwell") {
                n = vc * 1000 / (Math.PI * this.parameters.diameter)
                n > n_max ? n = n_max : ""
                time.cut += v.rotations / n * 60
            }
        });
        return time.cut + time.rapid
    }
    // get(context, state, pState) {
    //     let time = 0
    //     // let valuesInside = state.calculation.processes[state.process_selected].subProcesses.inside.values
    //     let vThread = state.calculation.processes[state.process_selected].subProcesses.thread.values.thread.value
    //     let parameters = thread.getParameters(state.calculation.processes[state.process_selected].subProcesses.thread.values)
    //     let path
    //     vThread.forEach((t, i) => {
    //         t.diameter = parseFloat(t.diameter)
    //         t.length = parseFloat(t.length)
    //         t.p = parseFloat(t.p)
    //         parameters.f = t.p || parameters.f
    //         if(i < vThread.length-1) time += 2
    //         //  Path
    //         path = []
    //         for (let c = 0; c < parameters.cuts; c++) {
    //             path.push({ z: .5, x: t.diameter, type: "rapid" })
    //             path.push({ z: .5, x: t.diameter, type: "cut" })
    //             path.push({ z: t.length, x: t.diameter, type: "cut" })
    //             path.push({ z: t.length, x: t.diameter + 2, type: "cut" })
    //             path.push({ z: .5, x: t.diameter + 2, type: "rapid" })
    //         }
    //         time += thread.calculateTime(path, parameters)
    //     });
    //     time *= context.getters.getTimeCorrectionFactor("threading")
    //     context.commit("addToManufactureChain", { time, tool: "threading", type: "threading" })
    //     if (vThread.length) pState.tools.threading = { parameters, time }
    //     // if (vThread.selected.value !== "") {
    //     //     let parameters = inside.getParameters(vComplexity)
    //     //     time += inside.drilling(vComplexity, vDimensions, parameters)
    //     //     if (vComplexity.selected.value === "turning" || vComplexity.selected.value === "complex") {
    //     //         console.log("medium");
    //     //         time += inside.insideTurning(parameters, vDimensions, vComplexity)
    //     //     }
    //     //     if (vComplexity.selected.value === "complex") {
    //     //         console.log("complex");
    //     //     }
    //     //     console.log(parameters);
    //     // }
    //     // else if (valuesInside.selected.value == "centering") {
    //     // let parameters = inside.getParameters(valuesComplexity)
    //     // time += sides.centering(parseInt(values.grooves_radial.value), parameters, diameter)

    //     // }
    //     return [parseFloat(time)]
    // },
    // getParameters(vThread) {
    //     let parameters = {
    //         cuts: 12,
    //         f: 1,
    //         vc: 120,
    //     }
    //     if (vThread.cuts && vThread.cuts.value) parameters.cuts = parseInt(vThread.cuts.value)
    //     if (vThread.f && vThread.f.value) parameters.f = parseInt(vThread.f.value)
    //     if (vThread.vc && vThread.vc.value) parameters.vc = parseInt(vThread.vc.value)
    //     return parameters
    // },
    // calculateTime(path, parameters) {
    //     let time = { cut: 0, rapid: 0 }
    //     let n, vf
    //     let [f, vc] = [parameters.f, parameters.vc]
    //     let n_max = 4000
    //     let rapid = 400

    //     if (!path || !path.length) return 0
    //     path.forEach((v, vi) => {
    //         if (!vi) return
    //         vi = path[vi - 1]
    //         let distance = p.dist([v.x, v.z], [vi.x, vi.z])
    //         if (distance == undefined || isNaN(distance)) return
    //         if (v.type == "cut") {
    //             n = vc * 1000 / (Math.PI * Math.abs(v.x))
    //             n > n_max ? n = n_max : ""

    //             vf = f * n
    //             time.cut += distance / vf * 60
    //         }
    //         else if (v.type == "rapid") {
    //             time.rapid += distance / rapid
    //         }
    //         else if (v.type == "drilling") {
    //             n = vc * 1000 / (Math.PI * this.parameters.diameter)
    //             n > n_max ? n = n_max : ""

    //             vf = f * n
    //             time.cut += distance / vf * 60
    //         }
    //         else if (v.type == "partRotate") {
    //             time.rapid += (.5 / 360 * v.angle)
    //         }
    //         else if (v.type == "dwell") {
    //             n = vc * 1000 / (Math.PI * this.parameters.diameter)
    //             n > n_max ? n = n_max : ""
    //             time.cut += v.rotations / n * 60
    //         }
    //     });
    //     return time.cut + time.rapid
    // }
}
const radialHole = {
    get() {
        let time = 0
        const holes = store.getters.subProcess("radialHole").holes
        let drillTypeTimes = {}
        // const dimensions = store.getters.subProcess("dimensions")
        if (holes && inside.checkData("holes", holes)) {
            holes.forEach((hole, h) => {
                const parameters = inside.getParameters('hole', hole)
                let path = [
                    { z: .5, x: 0, type: "rapid" },
                    { z: .5, x: 0, type: "drilling" },
                    { z: -hole.depth, x: 0, type: "drilling" },
                    { z: -hole.depth, x: 0, type: "dwell", rotations: 3 },
                    { z: -hole.depth, x: 0, type: "rapid" },
                    { z: .5, x: 0, type: "rapid" }
                ]
                const holeTime = hole.count * inside.calculateTime(path, parameters) 
                time += holeTime
                let nextRapid = h < hole.length - 1 ? 1 : 0  //  Eilgang nächste Nut
                time += (nextRapid + (hole.count - 1)) * 0.5

                if (!drillTypeTimes[`drilling_${hole.diameter}`]) drillTypeTimes[`drilling_${hole.diameter}`] = 0
                drillTypeTimes[`drilling_${hole.diameter}`] += holeTime
            });
            for (const drillType in drillTypeTimes) {
                processes.state.tools[drillType] = { time: drillTypeTimes[drillType] * store.getters.getTimeCorrectionFactor("radialDrilling") }
            }
            time *= store.getters.getTimeCorrectionFactor("radialDrilling")

            // if (time) processes.state.tools[tool.type] = { parameters: tool, time }
            store.commit("addToManufactureChain", { time, tool: "drill", type: "radialDrilling" })
        }
    },
    checkData() {
        let missing = []
        if (store.getters.subProcess('radialHole').holes) store.getters.subProcess('radialHole').holes.forEach((hole, h) => {
            if (!hole.count) missing.push(`Anzahl für Bohrung${h + 1}`)
            if (!hole.diameter) missing.push(`Durchmesser für Bohrung${h + 1}`)
            if (!hole.depth) missing.push(`Tiefe für Bohrung${h + 1}`)
            if (!hole.f) missing.push(`f für Bohrung${h + 1}`)
            if (!hole.vc) missing.push(`vc für Bohrung${h + 1}`)
        });
        if (missing.length) {
            missing = `Fehlende Informationen für Löcher: ${missing.join(", ")}`
            store.commit("setVerifyMessage", ["processes", missing, 1])
        }
        return !missing.length ? 1 : 0
    },
    // drilling_getTime() {
    //     const process = store.state.calculation.processes[store.state.process_selected]
    //     if (!process.subProcesses.holes || !process.subProcesses.holes.values.holes.value.length) return 0
    //     const holes = process.subProcesses.holes.values.holes.value
    //     milling_holes.sortByTools(holes)
    // },
    volume() {
        if (!radialHole.checkData()) return 0
        let vol = 0
        if (store.getters.subProcess('radialHole').holes) store.getters.subProcess('radialHole').holes.forEach(hole => {
            if (!hole.count || !hole.diameter || !hole.depth) return
            vol += hole.count * Math.PI * hole.diameter ** 2 / 4 * hole.depth
        });
        if (store.getters.material_volume < vol) return 0
        return vol      //  mm^3
    },
}
const eject = {
    get(context, state) {
        let time = 5
        if (state.calculation.processes[0] && state.calculation.processes[0].machine && state.calculation.processes[0].machine.eject_time) {
            time = state.calculation.processes[0].machine.eject_time
        }
        time *= context.getters.getTimeCorrectionFactor("eject")
        context.commit("addToManufactureChain", { time, type: "eject" })
        return [time]
    }
}

const milling_roughing = {
    get() {
        if(!milling_roughing.checkData()) return 
        // const step = store.getters.subProcess("step") || 0
        // if (step.existing == true) milling_roughing.fromStepVolume()
        // else 
        milling_roughing.fromRoughInformation()
        // const process = store.state.calculation.processes[store.state.process_selected]
        // const parameters = milling_roughing.getMillingParameters(process)
        // const step = process.subProcesses["step"].values
        // const holeVolume = milling_holes.volume()
        // const vol = (store.getters.material_volume - step.volume.value.mesh_volume - holeVolume) / 1000  //cm^3

        // const n = parameters.vc * 1000 / parameters.diameter / Math.PI
        // const vf = n * parameters.fz * parameters.z
        // const Q = parameters.ap * parameters.ae * vf / 1000 // cm^3/min

        // let time = vol / Q * 60
        // time *= store.getters.getTimeCorrectionFactor("milling")
        // store.commit("addToManufactureChain", { time, tool: "milling", type: "milling" })
        // if (time) processes.state.tools.milling = { parameters, time }

        // return [time]
    },
    checkData() {
        let missing = []
        const general = store.getters.subProcess('template').type == 'general'
        
        if (general && store.getters.subProcess("roughing").ratio === "") missing.push("Volumenanteil")
        store.getters.subProcess("roughing").tools.forEach((tool, t) => {
            if (!tool.ae) missing.push(`ae für WZ${t+1}`)
            if (!tool.ap) missing.push(`ap für WZ${t+1}`)
            if (!tool.diameter) missing.push(`Durchmesser für WZ${t+1}`)
            if (!tool.fz) missing.push(`fz für WZ${t+1}`)
            if (!tool.vc) missing.push(`vc für WZ${t+1}`)
            if (!tool.z) missing.push(`Zähnezahl für WZ${t+1}`)
        });

        if (missing.length) {
            missing = `Fehlende Informationen für Schruppen: ${missing.join(", ")}`
            store.commit("setVerifyMessage", ["processes", missing, 1])
        }
        return !missing.length ? 1 : 0
    },
    fromStepVolume() {
        const process = store.state.calculation.processes[store.state.process_selected]
        const parameters = milling_roughing.getMillingParameters(process)
        const step = process.subProcesses["step"].values
        const holeVolume = milling_holes.volume()
        const vol = (store.getters.material_volume - step.volume.value.mesh_volume - holeVolume) / 1000  //cm^3
    
        const n = parameters.vc * 1000 / parameters.diameter / Math.PI
        const vf = n * parameters.fz * parameters.z
        const Q = parameters.ap * parameters.ae * vf / 1000 // cm^3/min
    
        let time = vol / Q * 60
        time *= store.getters.getTimeCorrectionFactor("milling")
        store.commit("addToManufactureChain", { time, tool: "milling", type: "milling" })
        if (time) processes.state.tools.milling = { parameters, time }
    
        return [time]
    },
    fromRoughInformation() {
        const process = store.state.calculation.processes[store.state.process_selected]
        // const machine = store.state.calculation.processes[store.state.process_selected].machine
        // const parameters = milling_roughing.getMillingParameters(process)
        const dim = process.subProcesses["dimensions"].values
        const ratio = process.subProcesses["roughing"].values.ratio.value
        const vol_mesh = (dim.height.value * dim.length.value * dim.width.value) / 1000  //cm^3
        const holeVolume = milling_holes.volume()
        const vol_raw = store.getters.material_volume//milling_roughing.getRawVolume(dim)
        const vol_chip = vol_raw - holeVolume - vol_mesh*(1-ratio)
        //  Initialize Variables
        let vol, n, vf, Q, toolTime, parameters
        let time = 0
        //  Loop over al Tools and VOlumeRatios
        if (!process.subProcesses["roughing"].values.tools) return
        let tools = process.subProcesses["roughing"].values.tools.value
        let ratioSum = 0
        tools.map(tool => ratioSum += tool.ratio)
        const ratios = tools.map(tool => tool.ratio / ratioSum)
        tools.forEach((tool, t) => {
            parameters = milling_roughing.getMillingParameters(tool)
            if (!parameters) {
                // this.commit("setVerifyMessage", ["process", "Wählen Sie Schnittparameter.", !parameters])
                return
            }
            vol = vol_chip * ratios[t]
            n = parameters.vc * 1000 / parameters.diameter / Math.PI
            // if (machine.n_max && n > machine.n_max) n = machine.n_max
            vf = n * parameters.fz * parameters.z
            Q = parameters.ap * parameters.ae * vf / 1000 // cm^3/min
            toolTime = vol / Q * 60
            toolTime *= store.getters.getTimeCorrectionFactor(tool.type)

            store.commit("addToManufactureChain", { time: toolTime, tool: tool.type, type: tool.type })
            if (toolTime) processes.state.tools[tool.type] = { time: toolTime }

            time += toolTime
        });

        return [time]
    },

    // getRawVolume(dim) {
    //     try {
    //         if (dim.raw_type.value == "prismatic") {
    //             if (dim.raw_height.value * dim.raw_length.value * dim.raw_width.value == 0) {
    //                 return (dim.height.value * dim.length.value * dim.width.value) / 1000  //cm^3
    //             }
    //             else {
    //                 return (dim.raw_height.value * dim.raw_length.value * dim.raw_width.value) / 1000  //cm^3
    //             }
    //         }
    //         else if (dim.raw_type.value == "round") {
    //             return Math.PI * dim.raw_diameter.value**2/4*dim.raw_height.value / 1000  //cm^3
    //         }
    //     } catch (error) {
    //         return (dim.height.value * dim.length.value * dim.width.value) / 1000  //cm^3
    //     }
    // },

    getMillingParameters(tool) {
        // let parameters = {
        //     ap: 25,
        //     ae: 1.5,
        //     fz: .6,
        //     diameter: 35,
        //     z: 3,
        //     vc: 250
        // }
        // if (!process) return          
        if (!tool.ap) return 0
        if (!tool.ae) return 0
        if (!tool.fz) return 0
        if (!tool.diameter) return 0
        if (!tool.z) return 0
        if (!tool.vc) return 0
        return tool
    },
}
const milling_holes = {
    get() {
        if (!milling_holes.checkData()) return 
        let time = 0
        let drillTypeTimes = {}
        store.getters.subProcess('holes').holes.forEach(hole => {
            let n = hole.vc * 1000 / hole.diameter / Math.PI
            let vf = n * hole.f
            time += (2 * hole.depth / vf + 2/60) * hole.count + 2/60
            
            if (!drillTypeTimes[`drilling_${hole.diameter}`]) drillTypeTimes[`drilling_${hole.diameter}`] = 0
            drillTypeTimes[`drilling_${hole.diameter}`] += (2 * hole.depth / vf + 2 / 60) * hole.count + 2 / 60
        });
        for (const drillType in drillTypeTimes) {
            processes.state.tools[drillType] = { time: 60* drillTypeTimes[drillType] * store.getters.getTimeCorrectionFactor("holes") }
        }
        time *= 60*store.getters.getTimeCorrectionFactor("holes")
        // if (time) processes.state.tools[tool.type] = { parameters: tool, time }
        store.commit("addToManufactureChain", { time, tool: "drill", type: "holes" })
        // // const process = store.state.calculation.processes[store.state.process_selected]
        // milling_holes.drilling_getTime()
        // // const step = process.subProcesses["step"] || 0
        // // if (step.values.existing) milling_roughing.fromStepVolume()
        // // else milling_roughing.fromRoughInformation()
        return [time]
    },
    checkData() {let missing = []
        if (store.getters.subProcess('holes').holes) store.getters.subProcess('holes').holes.forEach((hole, h) => {
            if (!hole.count) missing.push(`Anzahl für Bohrung${h + 1}`)
            if (!hole.diameter) missing.push(`Durchmesser für Bohrung${h + 1}`)
            if (!hole.depth) missing.push(`Tiefe für Bohrung${h + 1}`)
            if (!hole.f) missing.push(`f für Bohrung${h + 1}`)
            if (!hole.vc) missing.push(`vc für Bohrung${h + 1}`)
        });
        if (missing.length) {
            missing = `Fehlende Informationen für Löcher: ${missing.join(", ")}`
            store.commit("setVerifyMessage", ["processes", missing, 1])
        }
        return !missing.length ? 1 : 0
    },
    // drilling_getTime() {
    //     const process = store.state.calculation.processes[store.state.process_selected]
    //     if (!process.subProcesses.holes || !process.subProcesses.holes.values.holes.value.length) return 0
    //     const holes = process.subProcesses.holes.values.holes.value
    //     milling_holes.sortByTools(holes)
    // },
    volume() {
        if (!milling_holes.checkData()) return 0
        let vol = 0
        if (store.getters.subProcess('holes').holes) store.getters.subProcess('holes').holes.forEach(hole => {
            if (!hole.count || !hole.diameter || !hole.depth) return
            vol += hole.count * Math.PI * hole.diameter ** 2 / 4000 * hole.depth
        });
        if (store.getters.material_volume < vol) return 0
        return vol      //  cm^3
    },
    sortByTools() {
        // let tools = {}
        // const holeIDs = holes.map(hole => `${hole.tool.length_max}~${hole.diameter}`)
        // holeIDs.forEach((id, i) => {
        //     !tools[id] ? tools[id] = [] : ""
        //     tools[id].push(i)
        // });
        // //  Create new Tools and determine time by tool
        // let hole, tool, time, n, vf;
        // for (const tID in tools) {
        //     time = 0
        //     tools[tID].forEach(h => {
        //         [hole, tool] = [holes[h], holes[h].tool]
        //         tool.type = "Bohren " + hole.diameter
        //         n = tool.vc * 1000 / hole.diameter / Math.PI
        //         vf = n * tool.f 
        //         time += (2 * hole.length / vf + 2/60) * hole.count - 2/60
        //     });
        //     time *= 60
        //     time *= store.getters.getTimeCorrectionFactor(tool.type)
        //     store.commit("addToManufactureChain", { time, tool: tool, type: tool.type })
        //     if (time) processes.state.tools[tool.type] = { parameters: tool, time }
        // }
        // return holeIDs
    }
}
const milling_finishing = {
    get() {
        if (!milling_finishing.checkData()) return
        let time = 0
        let millTypeTimes = {}        
        store.getters.subProcess('finishing').areas.forEach(area => {
            const dist = area.area / area.ap
            const n = area.vc * 1000 / area.diameter / Math.PI
            const vf = n * area.fz * area.z
            time += area.count * dist / vf

            if (!millTypeTimes[`finishing_${area.diameter}`]) millTypeTimes[`finishing_${area.diameter}`] = 0
            millTypeTimes[`finishing_${area.diameter}`] += area.count * dist / vf
        });
        for (const millType in millTypeTimes) {
            processes.state.tools[millType] = { time: 60*millTypeTimes[millType] * store.getters.getTimeCorrectionFactor("finishing") }
        }
        time *= 60 * store.getters.getTimeCorrectionFactor("finishing")
        // if (time) processes.state.tools[tool.type] = { parameters: tool, time }
        store.commit("addToManufactureChain", { time, tool: "finishing", type: "finishing" })
        // // const process = store.state.calculation.processes[store.state.process_selected]
        // milling_holes.drilling_getTime()
        // // const step = process.subProcesses["step"] || 0
        // // if (step.values.existing) milling_roughing.fromStepVolume()
        // // else milling_roughing.fromRoughInformation()
        return [time]
    },
    checkData() {
        let missing = []
        if (store.getters.subProcess('finishing').areas) store.getters.subProcess('finishing').areas.forEach((area, a) => {
            if (!area.count) missing.push(`Anzahl für Fläche${a + 1}`)
            if (!area.area) missing.push(`Fläche für Fläche${a + 1}`)
            if (!area.diameter) missing.push(`Durchmesser für Fläche${a + 1}`)
            if (!area.ap) missing.push(`ap für Fläche${a + 1}`)
            if (!area.fz) missing.push(`fz für Fläche${a + 1}`)
            if (!area.z) missing.push(`z für Fläche${a + 1}`)
            if (!area.vc) missing.push(`vc für Fläche${a + 1}`)
        });
        if (missing.length) {
            missing = `Fehlende Informationen für Schlichten: ${missing.join(", ")}`
            store.commit("setVerifyMessage", ["processes", missing, 1])
        }
        return !missing.length ? 1 : 0
    },
}
const milling_chamfer = {
    get() {
        if (!milling_chamfer.checkData()) return
        let time = 0
        let millTypeTimes = {}       
        if (store.getters.subProcess('chamfer').areal) store.getters.subProcess('chamfer').areal.forEach(area => {
            const n = area.vc * 1000 / area.diameter / Math.PI
            const vf = n * area.fz * area.z
            time += area.count * area.length / vf

            if (!millTypeTimes[`chamfer_${area.diameter}`]) millTypeTimes[`chamfer_${area.diameter}`] = 0
            millTypeTimes[`chamfer_${area.diameter}`] += area.count * area.length / vf
        });
        if (store.getters.subProcess('chamfer').edge) store.getters.subProcess('chamfer').edge.forEach(edge => {
            const n = edge.vc * 1000 / edge.diameter / Math.PI
            const vf = n * edge.fz * edge.z
            time += edge.count * edge.length / vf

            if (!millTypeTimes[`chamfer_${edge.diameter}`]) millTypeTimes[`chamfer_${edge.diameter}`] = 0
            millTypeTimes[`chamfer_${edge.diameter}`] += edge.count * edge.length / vf
        });
        for (const millType in millTypeTimes) {
            processes.state.tools[millType] = { time: 60*millTypeTimes[millType] * store.getters.getTimeCorrectionFactor("chamfer") }
        }
        time *= 60 * store.getters.getTimeCorrectionFactor("chamfer")
        // if (time) processes.state.tools[tool.type] = { parameters: tool, time }
        store.commit("addToManufactureChain", { time, tool: "chamfer", type: "chamfer" })
        // // const process = store.state.calculation.processes[store.state.process_selected]
        // milling_holes.drilling_getTime()
        // // const step = process.subProcesses["step"] || 0
        // // if (step.values.existing) milling_roughing.fromStepVolume()
        // // else milling_roughing.fromRoughInformation()
        return [time]
    },
    checkData() {
        let missing = []
        // if (!store.getters.subProcess('chamfer').areal || !store.getters.subProcess('chamfer').edge) return 0
        if (store.getters.subProcess('chamfer').areal) store.getters.subProcess('chamfer').areal.forEach((area, a) => {
            if (!area.count) missing.push(`Anzahl für Fläche${a + 1}`)
            if (!area.length) missing.push(`Fasenlänge für Fläche${a + 1}`)
            if (!area.diameter) missing.push(`Durchmesser für Fläche${a + 1}`)
            if (!area.fz) missing.push(`fz für Fläche${a + 1}`)
            if (!area.z) missing.push(`z für Fläche${a + 1}`)
            if (!area.vc) missing.push(`vc für Fläche${a + 1}`)
        });
        if (store.getters.subProcess('chamfer').edge) store.getters.subProcess('chamfer').edge.forEach((edge, a) => {
            if (!edge.count) missing.push(`Anzahl für Kante${a + 1}`)
            if (!edge.length) missing.push(`Fasenlänge für Kante${a + 1}`)
            if (!edge.diameter) missing.push(`Durchmesser für Kante${a + 1}`)
            if (!edge.fz) missing.push(`fz für Kante${a + 1}`)
            if (!edge.z) missing.push(`z für Kante${a + 1}`)
            if (!edge.vc) missing.push(`vc für Kante${a + 1}`)
        });
        if (missing.length) {
            missing = `Fehlende Informationen für Fasen: ${missing.join(", ")}`
            store.commit("setVerifyMessage", ["processes", missing, 1])
        }
        return !missing.length ? 1 : 0
    },
}
const milling_thread = {
    get() {
        if (!milling_thread.checkData()) return
        let time = 0
        let [drillTime, millTime] = [0,0]
        if (store.getters.subProcess('thread').threads) store.getters.subProcess('thread').threads.forEach(thread => {
            console.log(thread.type);
            if (thread.type == 'milling') {
                const n = thread.vc * 1000 / thread.diameter / Math.PI
                const vf = n * thread.fz*thread.z
                const translation = thread.toolLength < thread.length ? thread.length - thread.toolLength : thread.p 
                const dist = ((translation / thread.p * Math.PI * thread.diameter) ** 2 + translation / thread.p**2)**0.5
                time += thread.count * dist / vf + thread.count
                drillTime += thread.count * dist / vf + thread.count
            }
            else if (thread.type == "drilling") {
                const n = thread.vc * 1000 / thread.diameter / Math.PI
                const vf = n * thread.p
                time += thread.count * thread.length / vf + thread.count
                millTime += thread.count * thread.length / vf + thread.count
            }
        });
        processes.state.tools.threadDrilling = { time: 60*drillTime * store.getters.getTimeCorrectionFactor("thread") }
        processes.state.tools.threadMilling = { time: 60*millTime * store.getters.getTimeCorrectionFactor("thread") }
        time *= 60 * store.getters.getTimeCorrectionFactor("thread")
        // if (time) processes.state.tools[tool.type] = { parameters: tool, time }
        store.commit("addToManufactureChain", { time, tool: "thread", type: "thread" })
        // // const process = store.state.calculation.processes[store.state.process_selected]
        // milling_holes.drilling_getTime()
        // // const step = process.subProcesses["step"] || 0
        // // if (step.values.existing) milling_roughing.fromStepVolume()
        // // else milling_roughing.fromRoughInformation()
        return [time]
    },
    checkData() {
        let missing = []
        // if (!store.getters.subProcess('chamfer').areal || !store.getters.subProcess('chamfer').edge) return 0
        if (store.getters.subProcess('thread').threads) store.getters.subProcess('thread').threads.forEach((thread, t) => {
            if (!thread.count) missing.push(`Anzahl für Gewinde${t + 1}`)
            if (!thread.length) missing.push(`Länge für Gewinde${t + 1}`)
            if (!thread.diameter) missing.push(`Durchmesser für Gewinde${t + 1}`)
            if (!thread.p) missing.push(`P für Gewinde${t + 1}`)
            if (!thread.vc) missing.push(`vc für Gewinde${t + 1}`)
            if (thread.type == "milling") {
                if (!thread.fz) missing.push(`fz für Gewinde${t + 1}`)
                if (!thread.z) missing.push(`z für Gewinde${t + 1}`)
                if (!thread.toolLength) missing.push(`Fräserlänge für Gewinde${t + 1}`)
            }
            if (!thread.vc) missing.push(`Prozesstyp für Gewinde${t + 1}`)
        });
        if (missing.length) {
            missing = `Fehlende Informationen für Gewinde: ${missing.join(", ")}`
            store.commit("setVerifyMessage", ["processes", missing, 1])
        }
        return !missing.length ? 1 : 0
    },
}

const p = {
    pointsArrayToObj(array) {
        let obj = []
        if (typeof (array) != "object") return array
        array.forEach(point => {
            obj.push({ z: point[0], x: point[1] })
        });
        return obj
    },
    pointsObjToArray(obj) {
        let array = []
        if (typeof (obj) != "object") return obj
        obj.forEach(point => {
            array.push([point.z, point.x])
        });
        return array
    },
    pointOnLine(p, v1, v2) {
        if (this.round(this.dist(p, v1) + this.dist(p, v2), 3) > this.round(this.dist(v1, v2), 3)) return 0
        return 1
    },
    round(val, amount) {
        return Math.round(val * 10 ** amount) / 10 ** amount
    },
    dist(p1, p2) {
        return ((p2[1] - p1[1]) ** 2 + (p2[0] - p1[0]) ** 2) ** 0.5
    },
    getPoint(v) {
        if (v == undefined) return -1
        if (v.x) {
            v = [v.x, v.y]
        }
        return v
    },
    vectorRise(p1, p2) {
        p1 = this.getPoint(p1)
        p2 = this.getPoint(p2)

        let rise = -(p2[1] - p1[1]) / (p2[0] - p1[0])
        rise == -Infinity ? rise = -9e10 : "";
        rise == Infinity ? rise = 9e10 : "";
        return rise
    },
    lineIntersect(p1, p2, v1, v2) {
        p1 = this.getPoint(p1)
        p2 = this.getPoint(p2)
        v1 = this.getPoint(v1)
        v2 = this.getPoint(v2)

        let m = []
        let r1 = -this.vectorRise(p2, p1)
        let r2 = -this.vectorRise(v2, v1)
        m[0] = (v1[1] - p1[1] + r1 * p1[0] - r2 * v1[0]) / (r1 - r2)
        m[1] = p1[1] + r1 * (m[0] - p1[0])
        return m
    },
    linesCrossing(v1, v2, p1, p2, threshold = 0.01) {
        /**
        * c:        center of line
        * v:        vector
        * p1, p2:   line
        */
        let int = p.lineIntersect(v1, v2, p1, p2)
        if (p.dist(int, v1) + p.dist(int, v2) > p.dist(v1, v2) + threshold) return 0
        if (p.dist(int, p1) + p.dist(int, p2) > p.dist(p1, p2) + threshold) return 0
        return 1
    },
}

import Vue from "vue";
import store from '@/store/index.js'
const processes = {
    state: {
        executionStack: {
            turning: {
                contour: contour.get,
                sides: sides.get,
                grooves: grooves.get,
                finishing: finishing.get,
                inside: inside.get,
                thread: thread.get,
                radialHole: radialHole.get,
                eject: eject.get,
            },
            milling: {
                milling_roughing: milling_roughing.get,
                milling_holes: milling_holes.get,
                milling_finishing: milling_finishing.get,
                milling_chamfer: milling_chamfer.get,
                milling_thread: milling_thread.get,
            }
        },
        manufactureChain: [],
        tools: {},
        times_corrected: {}
    },
    mutations: {
        setTime(state, [key, time]) {
            Vue.set(state.times, key, time)
        },
        time_correct(state, [type, value]) {
            Vue.set(state.times_corrected, type, value)
        },
        addToManufactureChain(state, object) {
            state.manufactureChain.push(object)
        }
    },
    actions: {
        processes_evaluate(context) {
            Vue.set(context.state, "manufactureChain", [])
            Vue.set(context.state, "tools", {})
            if (!context.rootState.calculation.processes[context.rootState.process_selected]) return
            let processType = context.rootState.calculation.processes[context.rootState.process_selected].name
            let stack = context.state.executionStack[processType]
            for (const key in stack) {
                stack[key](context, context.rootState, context.state) 
            }
        },
    },
    getters: {
        times: (state, getters, rootState) => {
            let timeData = []
            let lastTool = ""
            let changeTime = 4;
            if (rootState.calculation.processes[0] && rootState.calculation.processes[0].machine) changeTime = rootState.calculation.processes[0].machine.toolchange_time
            if (changeTime == "" || changeTime == undefined) changeTime = 0
            const evaluateSubProcess = (subProcess, lastTool, changeTime) => {
                if (subProcess.tool && lastTool && subProcess.tool != lastTool) {
                    timeData.push({ type: "toolchange", time: parseFloat(changeTime)})
                }
                lastTool = subProcess.tool
                timeData.push({ type: subProcess.type, time: subProcess.time })
            }

            state.manufactureChain.forEach(subProcess => {
                if (subProcess.parallel) {
                    subProcess.processes.forEach(parallelSP => {
                        if(!parallelSP.time) return
                        evaluateSubProcess(parallelSP, lastTool, changeTime)
                        lastTool = parallelSP.tool
                    });  
                    return                   
                }
                if (!subProcess.time) return
                evaluateSubProcess(subProcess, lastTool, changeTime)
                lastTool = subProcess.tool
            });

            return timeData
        },
        time: (state, getters) => {
            let time = 0
            getters.times.forEach(sP => {
                time += sP.time
            });
            return time
        },
        times_corrected: (state) => state.times_corrected,
        getTimeCorrectionFactor: (state) => (type) => {
            if (state.times_corrected[type]) return 1 + state.times_corrected[type]/100
            else return 1
        },
        tools_raw: (state) => state.tools
    }
}

export default processes