<template>
    <div id="summary">
        <h2>Aufgaben</h2>
        <div class="padding">
            <div id="processes">
                <div>
                    <div class="flex">
                        <indicator :condition="!verifiedSteps.processes" :ttle="verifiedSteps.processes" :required="1" :forcegray="!(opened.processes || verifiedSteps.processes)"></indicator>
                        <p><b>Fertigungsprozess:</b></p>
                    </div>
                    <div class="indent">
                        <p>{{verifiedSteps.processes}}</p>
                        <!-- <p v-if="!$store.state.calculation.processes.length">Es wurde noch kein Prozess hinzugefügt.</p>
                        <p v-else-if="!verify.all">Es wurden noch nicht alle Prozesse vollständig definiert</p>
                        <p v-else>Erledigt</p> -->
                    </div>
                </div>
                <div>
                    <div class="flex">
                        <indicator :condition="!verifiedSteps.material" :ttle="verifiedSteps.material" :required="1" :forcegray="!(opened.material || verifiedSteps.material)"></indicator>
                        <p><b>Material:</b></p>
                    </div>
                    <div class="indent">
                        <p>{{verifiedSteps.material}}</p>
                    </div>
                </div>
                <div>
                    <div class="flex">
                        <indicator :condition="!verifiedSteps.machines" :required="1" :forcegray="!(opened.machine || verifiedSteps.machines)" :ttle="verifiedSteps.machines"></indicator>
                        <p><b>Maschinen:</b></p>
                    </div>
                    <div class="indent">
                        <p v-if="!$store.state.calculation.processes.length">Es wurde noch kein Prozess hinzugefügt.</p>
                        <p v-else>{{verifiedSteps.machines}}</p>
                    </div>
                </div>
                <div>
                    <div class="flex">
                        <indicator :condition="!verifiedSteps.tools" :required="1" :forcegray="!(opened.tools || verifiedSteps.tools)" :ttle="verifiedSteps.tools"></indicator>
                        <p><b>Werkzeuge:</b></p>
                    </div>
                    <div class="indent">
                        <p v-if="!$store.state.calculation.processes.length">Es wurde noch kein Prozess hinzugefügt.</p>
                        <p v-else>{{verifiedSteps.tools}}</p>
                    </div>
                </div>
                <div>
                    <div class="flex">
                        <indicator :condition="!verifiedSteps.setup" :ttle="verifiedSteps.setup" :required="1" :forcegray="!(opened.setup || verifiedSteps.setup)"></indicator>
                        <p><b>Rüsten:</b></p>
                    </div>
                    <div class="indent">
                        <p>{{verifiedSteps.setup}}</p>
                    </div>
                </div>
                <div>
                    <div class="flex">
                        <indicator :condition="!verifiedSteps.other" :ttle="verifiedSteps.other" :required="1" :forcegray="!(opened.other || verifiedSteps.other)"></indicator>
                        <p><b>Sonstiges:</b></p>
                    </div>
                    <div class="indent">
                        <p>{{verifiedSteps.other}}</p>
                    </div>      
                </div>
                <div>
                    <div class="flex">
                        <indicator :condition="false"></indicator>
                        <p><b>Nachkalkulation:</b></p>
                    </div>
                    <div class="indent">
                        <p>Es wurde noch kein Nachkalkulation erstellt. (Bald möglich)</p>
                    </div>
                </div>
            </div>
        </div>
        <p></p>
        <div id="summary_time" class="dark">
            <p><b>{{time_summed}}</b></p>
            <p><b>{{cost}}€</b></p>
        </div>
    </div>
        <!-- <div id="summary_content">
    </div> -->
</template>

<script>
// import p from "@/assets/js/processes.js";
export default {
    name: "pcSummary",
    methods: {
        dict(c) {
            return this.$store.state.dict[c] || c
        },
        changeValue(key, value) {
            if(key == "lotsize") this.$store.commit("information_set", [key, value]);
            this.$store.dispatch("refresh")
        },
    },
    computed: {
        information() {
            return this.$store.state.calculation.information
        },
        material() {
            return this.$store.state.calculation.material
        },
        time() {
            let values = []
            let val
            for (const key in this.$store.getters.time) {
                if (this.$store.getters.time[key][0] === 0) continue
                values.push(key)
                val = ""
                if (typeof this.$store.getters.time[key][0] == "number") val = this.$store.getters.time[key][0].toFixed(0)+"s"
                else val = this.$store.getters.time[key][0]
                if(this.$store.getters.time[key][1]) val += this.$store.getters.time[key][1]
                values.push(val)
            }
            return values
        },
        time_summed() {
            return this.$store.getters.convertTime(this.$store.getters.time.toFixed(0))

            // let times = this.$store.getters.time
            // let time = 0;
            // for (const key in times) {
            //     time += times[key][0]
            // }
            // return time.toFixed(0)
        },
        cost() {
            if (this.calcInfo.cost.piece != "NaN") {
                return this.calcInfo.cost.piece
            }
            else return "-"
        },
        calcInfo() {
            return this.$store.getters.calcInfo
        },
        verifiedSteps() {
            return this.$store.getters.verifiedSteps
        },
        opened() {
            return this.$store.getters.verifyOpened
        }
    }
}
</script>

<style scoped>
    #summary{
        grid-column-start: 3;
    }
    #contentGrid{
        display: grid;
        grid-template-columns: auto 1fr;
        grid-column-gap: 1em;
        grid-row-gap: 4px;
    }
    #processes>div{
        /* background-color: rgb(199, 96, 96); */
        border-bottom: 1px solid #dedede;
        padding: 16px 8px;
    }
    #processes>div:last-child{border: none}
    /* #processes>div:nth-child(odd){
        background-color: rgb(241, 241, 241);
    }    
    #processes>div:nth-child(even){
        background-color: rgb(247, 247, 247);
    } */
    #summary_time{
        padding: 1.5em 0;
    }
    #summary_time>p{
        text-align: center;
        font-size: 16pt;
        margin: auto;
    }
</style>