<template>
    <div id="container">
        <div class="padding glass">
            <b>Fasen</b>
            <div class="flex gridGap">
                <!-- <div class="flex padding_small radius" v-for="(t, i) in values.holes" :key="i">
                    <img @click="hole_selected = i" class="radius_small pointer" :style="{backgroundColor: t.color}" src="https://dashboard.pixelprime.de/assets/icons/categories/interaction_clickmap.svg" title="Fläche auswählen">
                </div> -->
                <div class="flex gridGap_small">
                    <div class="flex padding_small radius" v-for="(t, i) in values.areal" :key="i">
                        <img @click="areal_selected = i" class="radius_small pointer" :style="{backgroundColor: t.color}" src="https://dashboard.pixelprime.de/assets/icons/categories/interaction_clickmap.svg" title="Bohrung">
                    </div>
                    <div class="dark padding_small pointer radius flex fitContent" @click="addArealChamfer()">
                        <img style="height: 1.5em; width: 1.5em; margin:auto" src="https://dashboard.pixelprime.de/assets/icons/add.svg" alt="">
                        <p class="marginAuto nowrap">Fläche als Fase</p>
                    </div>
                </div>
                <div class="flex gridGap_small">
                    <div class="flex padding_small radius" v-for="(t, i) in values.edge" :key="i">
                        <img @click="edge_selected = i" class="radius_small pointer" :style="{backgroundColor: t.color}" src="https://dashboard.pixelprime.de/assets/icons/categories/interaction_clickmap.svg" title="Bohrung">
                    </div>
                    <div class="dark padding_small pointer radius flex fitContent" @click="addEdgeChamfer()">
                        <img style="height: 1.5em; width: 1.5em; margin:auto" src="https://dashboard.pixelprime.de/assets/icons/add.svg" alt="">
                        <p class="marginAuto nowrap">Flächenkanten als Fläche</p>
                    </div>
                </div>
            </div>

        </div>

        <div id="settings" :style="$store.getters.settingsPosition" class="padding glass">
            <div v-if="a">
                <b>Aus Fläche {{1+areal_selected}}:</b>
                <div class="infoGrid indent" :style="{borderColor: a.color}">
                    <p>Anzahl:</p>
                    <input type="number" min="1" :value="a.count" @input="changeProperty('areal', areal_selected, 'count', $event.target.value)">
                    <p></p>
                    <p>Länge:</p>
                    <input type="number" min="0.1" :value="a.length" @input="changeProperty('areal', areal_selected, 'length', $event.target.value)">
                    <p>mm</p>
                    
                    <p title="Fräserdurchmesser">Durchmesser:</p> 
                    <input type="number" step="0.1" :value="a.diameter" @input="changeProperty('areal', areal_selected, 'diameter', $event.target.value)">
                    <p> mm</p>
                    <p title="Schnittgeschwindigkeit">vc:</p>
                    <input type="number" steps="10" :value="a.vc" @input="changeProperty('areal', areal_selected, 'vc', $event.target.value)">
                    <p>m/min</p>
                    <p title="Vorschub pro Schneide">fz:</p>
                    <input type="number" min="0.05" :value="a.fz" @input="changeProperty('areal', areal_selected, 'fz', $event.target.value)">
                    <p>mm/U</p>                    
                    <p title="Zähnezahl">z:</p>
                    <input type="number" min="1" :value="a.z" @input="changeProperty('areal', areal_selected, 'z', $event.target.value)">
                    <p></p>
                </div>
                <img class="pointer" @click="remove('areal', areal_selected)" src="https://dashboard.pixelprime.de/assets/icons/delete.svg" alt="">
            </div>
            <div v-if="e">
                <b>Aus Kante {{1+edge_selected}}:</b>
                <div class="infoGrid indent" :style="{borderColor: e.color}">
                    <p>Anzahl:</p>
                    <input type="number" min="1" :value="e.count" @input="changeProperty('edge', edge_selected, 'count', $event.target.value)">
                    <p></p>
                    <p>Länge:</p>
                    <input type="number" min="0.1" :value="e.length" @input="changeProperty('edge', edge_selected, 'length', $event.target.value)">
                    <p>mm</p>
                    
                    <p title="Fräserdurchmesser">Durchmesser:</p> 
                    <input type="number" step="0.1" :value="e.diameter" @input="changeProperty('edge', edge_selected, 'diameter', $event.target.value)">
                    <p> mm</p>
                    <p title="Schnittgeschwindigkeit">vc:</p>
                    <input type="number" steps="10" :value="e.vc" @input="changeProperty('edge', edge_selected, 'vc', $event.target.value)">
                    <p>m/min</p>
                    <p title="Vorschub pro Schneide">fz:</p>
                    <input type="number" min="0.05" :value="e.fz" @input="changeProperty('edge', edge_selected, 'fz', $event.target.value)">
                    <p>mm/U</p>                    
                    <p title="Zähnezahl">z:</p>
                    <input type="number" min="1" :value="e.z" @input="changeProperty('edge', edge_selected, 'z', $event.target.value)">
                    <p></p>
                </div>
                <img class="pointer" @click="remove('edge', areal_selected)" src="https://dashboard.pixelprime.de/assets/icons/delete.svg" alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            areal_selected: -1,
            edge_selected: -1
        }
    },
    methods: {
        changeProperty(type, tIndex, key, value) {
            this.$store.commit("changeSubprocessListValue", ["chamfer", type, tIndex, key, value])
            this.$store.dispatch("refresh")
        },
        addArealChamfer() {
            this.$store.commit("pushSubprocessValue", ["chamfer", "areal", {
                faces: [], 
                length: false, 
                count: 1,
                fz: 0.12,
                diameter: 10,
                vc: 150,
                z: 3,
                color: this.$store.getters.color()
            }])
            this.areal_selected = this.values.areal.length-1
            this.edge_selected = -1
            this.$store.dispatch("refresh")
        },
        addEdgeChamfer() {
            this.$store.commit("pushSubprocessValue", ["chamfer", "edge", {
                edges: [], 
                length: false, 
                count: 1,
                fz: 0.12,
                diameter: 10,
                vc: 150,
                z: 3,
                color: this.$store.getters.color()
            }])
            this.areal_selected = -1
            this.edge_selected = this.values.edge.length-1
            this.$store.dispatch("refresh")
        },
        remove(type, i) {
            this.turning_selected = -1
            this.hole_selected = -1
            this.interactivity(0)
            this.$store.commit("deleteSubprocessList", ["chamfer", type, i])
            this.$store.dispatch("refresh")
        },
        interactivity(state) {
            let viewer = this.$store.getters.viewer
            if (!state) {
                delete viewer.onHover
                delete viewer.onClick
                return
            }

            viewer.onHover = (mesh) => {
                viewer.resetMaterials()
                if(viewer.mouseDown) return
                mesh.object.material = viewer.material_get("hover")
            }
            viewer.onClick = (mesh, button) => {
                if (button == "left") {
                    const [type, selected] = this.edge_selected > -1 ? ["edge", this.edge_selected] : ["areal", this.areal_selected]
                    viewer.faceMaterials[mesh.object.name] = viewer.material_get("selected", this.values[type][selected].color)
                    this.dimensionsFromShape(mesh.object.information)
                }
                else {
                    delete viewer.faceMaterials[mesh.object.name]
                }
                viewer.resetMaterials()     
            }
        },
        dimensionsFromShape(info) {
            const [type, selected] = this.edge_selected > -1 ? ["edge", this.edge_selected] : ["areal", this.areal_selected]
            if (type == "areal" && info[0].type == "Cylinder") {
                console.log(info);                
            }
            else if (type == "areal" && info[0].type == "Plane") {
                let lengths = []
                info[1].map(edge => {lengths.push(Math.abs(edge.V-edge.U))})
                console.log(lengths);
                this.changeProperty(type, selected, "length", Math.round(10*Math.max(...lengths))/10)
            }
            else if (type == "edge" && info[0].type == "Cylinder") {
                console.log(info);                
            }
            else if (type == "edge" && info[0].type == "Plane") {
                let lengths = 0
                info[1].map(edge => {lengths += Math.abs(edge.V-edge.U)})
                this.changeProperty(type, selected, "length", Math.round(10*lengths)/10)
            }
        }
    },
    computed: {
        values() {
            return this.$store.getters.subProcess("chamfer")
        },
        a() {
            if(this.areal_selected == -1) return 0
            return this.values.areal[this.areal_selected]
        },
        e() {
            if(this.edge_selected == -1) return 0
            return this.values.edge[this.edge_selected]
        },
        subProcess_selected() {
            return this.$store.state.subProcess_selected
        },
        verify() {
            return this.$store.getters.verify.processes[this.$store.state.process_selected].subProcesses[this.$store.state.subProcess_selected]
        },
    },
    watch: {
        areal_selected() {
            // var viewer = this.$store.getters.viewer
            if (this.areal_selected != -1) {
                this.edge_selected = -1
                this.interactivity(1)
            } 
        },
        edge_selected() {
            // var viewer = this.$store.getters.viewer
            if (this.edge_selected != -1) {
                this.areal_selected = -1
                this.interactivity(1)
            }
        }
    }
}
</script>

<style scoped>

    .infoGrid{
        display: grid;
        grid-template-columns: auto auto auto;
        grid-column-gap: .5em;
        grid-row-gap: .25em;
        border-left: 4px solid;
        padding-left: 8px;
        border-radius: 4px 0 0 4px;
    }
</style>
