<template>

    <div id="container" >
        <div class="padding glass flex gridGap_large fitContent">

            <b>Radialbohrungen</b>
            <div class="flex gridGap">
                <div class="flex padding_small radius" v-for="(t, i) in values.holes" :key="i">
                    <img @click="radialHole_selected = i" class="radius_small pointer" :style="{backgroundColor: t.color}" src="https://dashboard.pixelprime.de/assets/icons/categories/interaction_clickmap.svg" title="Fläche auswählen">
                </div>
                <div id="radialHoleAdd" class="dark padding_small pointer radius flex fitContent" @click="addRadialHole()">
                    <img style="height: 1.5em; width: 1.5em; margin:auto" src="https://dashboard.pixelprime.de/assets/icons/add.svg" alt="">
                    <p class="marginAuto">Bohrung hinzufügen</p>
                </div>
            </div>

        </div>

        <div id="settings" :style="$store.getters.settingsPosition" class="padding glass">
            <div v-if="t">
                <b>Bohrung {{1+radialHole_selected}}:</b>
                <div class="infoGrid indent" :style="{borderColor: t.color}">
                    <p>Anzahl:</p>
                    <input type="number" min="1" :value="t.count" @input="changeRadialHoleProperty(radialHole_selected, 'count', $event.target.value)">
                    <p></p>
                    <p>Durchmesser:</p>
                    <input type="number" min="0.1" :value="t.diameter" @input="changeRadialHoleProperty(radialHole_selected, 'diameter', $event.target.value)">
                    <p>mm</p>
                    <p>Tiefe:</p>
                    <input type="number" min="0.1" :value="t.depth" @input="changeRadialHoleProperty(radialHole_selected, 'depth', $event.target.value)">
                    <p>mm</p>
                </div>
                <img class="pointer" @click="removeRadialHole(radialHole_selected)" src="https://dashboard.pixelprime.de/assets/icons/delete.svg" alt="">
            </div>
        </div>
    </div>

</template>

<script>
export default {
    data() {
        return {
            radialHoles: [],
            radialHole_selected: -1
        }
    },
    // mounted() {
    //     const card = document.getElementById("viewerBox").getBoundingClientRect()
    //     document.getElementById("settings").style.left = card.width-200+"px"
    //     document.getElementById("settings").style.top = card.height+"px"//this.$store.getters.viewer.renderer.domElement.style.height
    // },
    methods: {
        addRadialHole() {
            this.$store.commit("pushSubprocessValue", ["radialHole", "holes", {
                depth: false, 
                diameter: false, 
                count: 1,
                color: this.$store.getters.color()
            }])
            this.radialHole_selected = this.values.holes.length-1
            this.$store.dispatch("refresh")
        },
        removeRadialHole(i) {
            this.$store.commit("deleteSubprocessList", ["radialHole", "holes", i])
            this.$store.dispatch("refresh")
        },
        changeRadialHoleProperty(tIndex, key, value) {
            this.$store.commit("changeSubprocessListValue", ["radialHole", "holes", tIndex, key, value])
            this.$store.dispatch("refresh")
        },
        dimensionsFromShape(info) {
            if (info[0].type == "Cylinder") {
                let diameter = Math.round(2*info[0].radius*1000)/1000
                let length = Math.abs(Math.round((info[0]["V"][1]-info[0]["V"][0])*1000)/1000)
                this.$store.commit("changeSubprocessListValue", ["radialHole", "holes", this.radialHole_selected, 'diameter', diameter])
                this.$store.commit("changeSubprocessListValue", ["radialHole", "holes", this.radialHole_selected, 'inside', info[0].orientation])
                this.$store.commit("changeSubprocessListValue", ["radialHole", "holes", this.radialHole_selected, 'depth', length])
                this.$store.dispatch("refresh")
            }
            else {
                console.log(info[0]);
            }
        }
    },
    computed: {
        values() {
            return this.$store.getters.subProcess("radialHole")
        },
        t() {
            if(this.radialHole_selected < 0) return 0
            return this.values.holes[this.radialHole_selected] || 0
        },
        verify() {
            return this.$store.getters.verify.processes[0].subProcesses["radialHole"]
        },
    },
    watch: {
        radialHole_selected() {
            var viewer = this.$store.getters.viewer
            if (this.radialHole_selected == -1) delete viewer.onHover
            else {
                viewer.onHover = (mesh) => {
                    viewer.resetMaterials()
                    if(viewer.mouseDown) return
                    mesh.object.material = viewer.material_get("hover")
                    // console.log(mesh);
                    // setTimeout(() => {
                    //     mesh.object.material.color.set(0x00426D);
                    // }, 500)
                }
                viewer.onClick = (mesh, button) => {
                    if (button == "left") {
                        delete viewer.faceMaterials[this.t.face]
                        viewer.faceMaterials[mesh.object.name] = viewer.material_get("selected", this.values.holes[this.radialHole_selected].color)
                        this.dimensionsFromShape(mesh.object.information)

                        this.$store.commit("changeSubprocessListValue", ["radialHole", "holes", this.radialHole_selected, 'face', mesh.object.name])
                    }
                    else {
                        delete viewer.faceMaterials[mesh.object.name]
                    }
                    viewer.resetMaterials()                        
                    // mesh.object.material.color.set(0x004200);
                    // console.log(mesh);
                    // setTimeout(() => {
                    //     mesh.object.material.color.set(0x00426D);
                    // }, 500)
                }
                
            }   
        }
    }
}
</script>

<style scoped>

    /* p{white-space: nowrap;} */
    .infoGrid{
        display: grid;
        grid-template-columns: auto auto auto;
        grid-column-gap: .5em;
        grid-row-gap: .25em;
        border-left: 4px solid;
        padding-left: 8px;
        border-radius: 4px 0 0 4px;
    }

    #box>.card:first-child{margin-bottom: 2em;}
    #box>.card{width: fit-content;}
    .flex>input{width: 4em;}
    .flex>p:nth-child(3n){margin-right: 1em;}
    #gridContainer{
        display: grid;
        grid-template-columns: auto auto;
        grid-column-gap: 2em;
    }
    #radialHoleImage{
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        height: 12em;
        width: 17em;
        margin: 1em auto;
    }
    #next{
        position: absolute;
        right: .5em;
        bottom: .5em;
    }
    .diameter{
        transform-origin: center;
        transform: scale(1.5);
        margin-right: 5px;
    }
    select{background-color: rgba(0, 0, 0, 0);}
</style>