import apiCall from '@/apiCall'

var pdf = {
    getPdfTemplate: async function (getters, type) {
        const formData = new FormData();
        formData.append('method', "export_getTemplate");
        formData.append('type', type);
        const pdf = await fetch(getters.path_data, {
            method: "POST",
            body: formData
        }).then(result => result.arrayBuffer())
        const pdfDoc = await PDFDocument.load(pdf)
        return pdfDoc
    },
    getManufacturerInformation: async () => {
        return apiCall({
            method: "manufacturer_getInformation",
        }).then(response => {
            if (response.statusCode == 200) {
                return response.body
            }
        })
        // const formData = new FormData();
        // formData.append('method', "export_getManufacturerInformation");
        // return fetch(getters.path_data, {
        //     method: "POST",
        //     body: formData
        // }).then(result => result.json())
    },
    // getTemplateInformation: async function(getters, type) {
    //     const formData = new FormData();
    //     formData.append('method', "export_getTemplateInformation");
    //     formData.append('method', type);
    //     const pdf = await fetch(getters.path_data, {
    //         method: "POST",
    //         body: formData
    //     }).then(result => result.arrayBuffer())
    //     const pdfDoc = await PDFDocument.load(pdf)
    //     return pdfDoc
    // },
    savePdf: async function (pdfDoc) {
        const pdfBytes = await pdfDoc.save()
        saveByteArray("Sample Report", base64ToArrayBuffer(pdfBytes));
    },

    createPdf: async function(getters, type) {
        //  Check Data
        let information = getters.customerInformation
        let missing = ""
        if (information.name == undefined) missing += "Kundenname "
        if (information.street == undefined) missing += "Straße "
        if (information.housenumber == undefined) missing += "Hausnummer "
        if (information.zipcodecity == undefined) missing += "Plz Ort "
        if(information.lettertext == undefined) missing += "Anschreiben "
        if(getters.lotsize_prices == undefined) missing += "Losgröße "
        if(getters.information.part == undefined) missing += "Bauteilname "
        if (missing != "") return missing

        let pdfDoc = await pdf.getPdfTemplate(getters, type)//getPDF(await pdf.getTemplateURL())
        const pages = pdfDoc.getPages()
        const page = pages[0]



        switch (type) {
            case "offer":
                await pdf.createOfferPdf(getters, page, pdfDoc)
                break;
            case "time":
                await pdf.createCalculationPdf(page, pdfDoc)
                break;
            default:
                break;
        }

        pdf.savePdf(pdfDoc)

        // const form = pdfDoc.getForm()
        // const textField = form.createTextField('name')
        // textField.setText('Julian Meinhold')
        // textField.addToPage(page, {
        //     x: 326,
        //     y: 817.9-234,
        //     width: 100,
        //     height: 24,
        //     textColor: rgb(0, 0, 0),
        //     // backgroundColor: rgb(0, 1, 0),
        //     // borderColor: rgb(0, 0, 1),
        //     // borderWidth: 2,
        //     // rotate: degrees(90),
        //     // font: "Arial",
        // })



        // const pdfDoc = await PDFDocument.create()
        // const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman)

        // const page = pdfDoc.addPage()
        // const { width, height } = page.getSize()
        // console.log(width);
        // const fontSize = 30
        // page.drawText('Creating PDFs in JavaScript is awesome!', {
        //     x: 50,
        //     y: height - 4 * fontSize,
        //     size: fontSize,
        //     font: timesRomanFont,
        //     color: rgb(0, 0.53, 0.71),
        // })

        // const pdfBytes = await pdfDoc.save()
        // console.log(pdfBytes);
    },
    addGridBlock: async (data, dimensions, page) => {
        const [left, top1, lineSpacing, font] = dimensions
        data.forEach((line, l) => {
            line.forEach((entry, e) => {
                page.drawText(entry, {
                    x: left[e],
                    y: top1 - lineSpacing * l,
                    size: 12,
                    font: font,
                    color: rgb(0, 0, 0),
                })
            });
        });
        return page
    },
    createCalculationPdf: async (page, pdfDoc) => {
        const mm = (m) => m*884/297
        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
        const helveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold)
        // const calcInfo = store.getters.calcInfo
        const lineSpacing = 16
        const indent = mm(3)
        const left = mm(25)

        const calcInfo = store.getters.calcInfo
        const dict = (c) => {
            return store.state.dict[c] || c
        }
        // const right = 325
        // const calcInfo = store.getters.calcInfo
        console.log(store.state);
        //  Title
        page.drawText("Vorkalkulation", {
            x: left,
            y: 884-mm(37),
            size: 16,
            font: helveticaBold,
            color: rgb(0, 0, 0),
        })
        //  Head
        const d = new Date()
        pdf.addGridBlock(
            [
                ["Teil:", store.state.calculation.name],
                ["Kunde:", store.state.calculation.information.customer.name],
                ["Datum:", `${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()}`]
            ],
            [
                [left, left + mm(60)],
                884 - mm(50),
                lineSpacing,
                helveticaFont
            ],
            page
        )
        //  Lotsize
        page.drawText("Losgröße:", {
            x: left,
            y: 884 - mm(50) - lineSpacing * 4,
            size: 12,
            font: helveticaFont,
            color: rgb(0, 0, 0),
        })
        page.drawText(String(store.state.calculation.information.lotsize), {
            x: left + mm(60),
            y: 884 - mm(50) - lineSpacing * 4,
            size: 12,
            font: helveticaFont,
            color: rgb(0, 0, 0),
        })
        //  Material
        page.drawText("Material:", {
            x: left,
            y: 884 - mm(50) - lineSpacing * 6,
            size: 12,
            font: helveticaBold,
            color: rgb(0, 0, 0),
        })
        pdf.addGridBlock(
            [
                ["Materialname:", String(store.state.calculation.material.name)],
                ["Materialtyp:", String(dict(store.state.calculation.material.type))],
                [dict("price_" + store.state.calculation.material.type)+":", String(store.state.calculation.material["price_"+store.state.calculation.material.type]+"€")],
                ["Bedarf:", String(calcInfo.material.demand)],
                ["Materialkosten:", String(calcInfo.material.cost+"€")],
            ],
            [
                [left + indent, left + mm(60)],
                884 - mm(50) - lineSpacing * 7,
                lineSpacing,
                helveticaFont
            ],
            page
        )
        //  Fertigung
        page.drawText("Fertigungskosten:", {
            x: left,
            y: 884 - mm(50) - lineSpacing * 13,
            size: 12,
            font: helveticaBold,
            color: rgb(0, 0, 0),
        })
        pdf.addGridBlock(
            [
                ["Maschine:", calcInfo.machines.name],
                ["Stundensatz:", calcInfo.machines.machinecost_hourly+"€/h"],
                ["Fertigungsdauer:", calcInfo.manufacturing.time+"h"],
                ["Fertigungskosten:", calcInfo.manufacturing.cost+"€"],
                ["Rüstzeit:", calcInfo.setup.time+"min"],
                ["Rüstkosten:", calcInfo.setup.cost+"€"],
                ["Werkzeugkosten:", calcInfo.toolcost+"€"]
            ],
            [
                [left+indent, left + mm(60)],
                884 - mm(50) - lineSpacing * 14,
                lineSpacing,
                helveticaFont
            ],
            page
        )
        // Sonderkosten
        page.drawText("Sondereinzelkosten:", {
            x: left,
            y: 884 - mm(50) - lineSpacing * 22,
            size: 12,
            font: helveticaBold,
            color: rgb(0, 0, 0),
        })
        page.drawText(calcInfo.other.individual + "€", {
            x: left + mm(60),
            y: 884 - mm(50) - lineSpacing * 22,
            size: 12,
            font: helveticaFont,
            color: rgb(0, 0, 0),
        })
        page.drawText("Sondergemeinkosten:", {
            x: left,
            y: 884 - mm(50) - lineSpacing * 23,
            size: 12,
            font: helveticaBold,
            color: rgb(0, 0, 0),
        })
        page.drawText(calcInfo.other.overhead + "€", {
            x: left + mm(60),
            y: 884 - mm(50) - lineSpacing * 23,
            size: 12,
            font: helveticaFont,
            color: rgb(0, 0, 0),
        })
        // Herstellkosten
        page.drawText("Herstellkosten:", {
            x: left,
            y: 884 - mm(50) - lineSpacing * 25,
            size: 12,
            font: helveticaBold,
            color: rgb(0, 0, 0),
        })
        page.drawText(calcInfo.cost.manufacturing + "€", {
            x: left + mm(60),
            y: 884 - mm(50) - lineSpacing * 25,
            size: 12,
            font: helveticaFont,
            color: rgb(0, 0, 0),
        })
        // Gewinn
        page.drawText("Gewinn:", {
            x: left,
            y: 884 - mm(50) - lineSpacing * 27,
            size: 12,
            font: helveticaBold,
            color: rgb(0, 0, 0),
        })
        page.drawText(calcInfo.other.margin+"%", {
            x: left + mm(60),
            y: 884 - mm(50) - lineSpacing * 27,
            size: 12,
            font: helveticaFont,
            color: rgb(0, 0, 0),
        })
        // Rabatt 
        page.drawText("Rabatt:", {
            x: left,
            y: 884 - mm(50) - lineSpacing * 28,
            size: 12,
            font: helveticaBold,
            color: rgb(0, 0, 0),
        })
        page.drawText(calcInfo.other.discount+"%", {
            x: left + mm(60),
            y: 884 - mm(50) - lineSpacing * 28,
            size: 12,
            font: helveticaFont,
            color: rgb(0, 0, 0),
        })
        // Preis
        page.drawText("Gesamtpreis:", {
            x: left,
            y: 884 - mm(50) - lineSpacing * 30,
            size: 12,
            font: helveticaBold,
            color: rgb(0, 0, 0),
        })
        page.drawText(calcInfo.cost.total+"€", {
            x: left + mm(60),
            y: 884 - mm(50) - lineSpacing * 30,
            size: 12,
            font: helveticaFont,
            color: rgb(0, 0, 0),
        })
        page.drawText("Stückpreis:", {
            x: left,
            y: 884 - mm(50) - lineSpacing * 31,
            size: 12,
            font: helveticaBold,
            color: rgb(0, 0, 0),
        })
        page.drawText(calcInfo.cost.piece+"€/Stück", {
            x: left + mm(60),
            y: 884 - mm(50) - lineSpacing * 31,
            size: 12,
            font: helveticaFont,
            color: rgb(0, 0, 0),
        })

        // const material = store.getters.calcInfo
    },
    createOfferPdf: async (getters, page, pdfDoc) => {
        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
        const helveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold)
        let information = getters.customerInformation
        let manufacturer = await pdf.getManufacturerInformation(getters) 
        let calcInfo = getters.calcInfo
        let lineSpacing = 16
        let left = 70
        let right = 325
        console.log(manufacturer);
        console.log(information, calcInfo);
        
        //  Manufacturer
        page.drawText(manufacturer.name, {
            x: right,
            y: 833 - 140,
            size: 12,
            font: helveticaFont,
            color: rgb(0, 0, 0),
        })
        //  Customer street + housenumber
        page.drawText(manufacturer.street + " " + manufacturer.housenumber, {
            x: right,
            y: 833 - 140 - 1 * lineSpacing,
            size: 12,
            font: helveticaFont,
            color: rgb(0, 0, 0),
        })
        //  Customer zipcodecity
        page.drawText(manufacturer.zipcodecity, {
            x: right,
            y: 833 - 140 - 2 * lineSpacing,
            size: 12,
            font: helveticaFont,
            color: rgb(0, 0, 0),
        })
        //  Tel
        page.drawText(manufacturer.tel, {
            x: right,
            y: 833 - 140 - 4 * lineSpacing,
            size: 12,
            font: helveticaFont,
            color: rgb(0, 0, 0),
        })
        //  Mail
        page.drawText(manufacturer.email, {
            x: right,
            y: 833 - 140 - 5 * lineSpacing,
            size: 12,
            font: helveticaFont,
            color: rgb(0, 0, 0),
        })
        //  Date
        const d = new Date();
        page.drawText(`${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()}`, {
            x: right,
            y: 833 - 140 - 6 * lineSpacing,
            size: 12,
            font: helveticaFont,
            color: rgb(0, 0, 0),
        })
        //  Customer
        //  Customer Name
        page.drawText(information.name, {
            x: left,
            y: 833 - 140,
            size: 12,
            font: helveticaFont,
            color: rgb(0, 0, 0),
        })
        //  Customer street + housenumber
        page.drawText(information.street + " " + information.housenumber, {
            x: left,
            y: 833 - 140 - 1 * lineSpacing,
            size: 12,
            font: helveticaFont,
            color: rgb(0, 0, 0),
        })
        //  Customer street + housenumber
        page.drawText(information.zipcodecity, {
            x: left,
            y: 833 - 140 - 2 * lineSpacing,
            size: 12,
            font: helveticaFont,
            color: rgb(0, 0, 0),
        })
        //  Customer zipcodecity
        page.drawText(information.lettertext.replace('\n\n', '\n'), {
            x: left,
            y: 833 - 330,
            size: 12,
            lineHeight: 30,
            font: helveticaFont,
            color: rgb(0, 0, 0),
        })
        //  Anschreiben
        //  Bezeichnung
        page.drawText("Teil", {
            x: left,
            y: 833 - 330-4*lineSpacing,
            size: 12,
            lineHeight: 30,
            font: helveticaBold,
            color: rgb(0, 0, 0),
        })
        //  Menge
        page.drawText("Menge", {
            x: left+200,
            y: 833 - 330 - 4 * lineSpacing,
            size: 12,
            lineHeight: 30,
            font: helveticaBold,
            color: rgb(0, 0, 0),
        })
        //  Stückpreis
        page.drawText("Stückpreis", {
            x: left+300,
            y: 833 - 330 - 4 * lineSpacing,
            size: 12,
            lineHeight: 30,
            font: helveticaBold,
            color: rgb(0, 0, 0),
        })
        //  Gesamt
        page.drawText("Gesamt", {
            x: left+400,
            y: 833 - 330 - 4 * lineSpacing,
            size: 12,
            lineHeight: 30,
            font: helveticaBold,
            color: rgb(0, 0, 0),
        })
        //  All Parts
        var i = 0
        for (const ls in getters.lotsize_prices) {
            i++
            //  Bezeichnung
            page.drawText(getters.information.part, {
                x: left,
                y: 833 - 330 - (4+i) * (lineSpacing+4),
                size: 12,
                lineHeight: 30,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            })
            //  Menge
            page.drawText(ls, {
                x: left + 200,
                y: 833 - 330 - (4+i) * (lineSpacing+4),
                size: 12,
                lineHeight: 30,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            })
            //  Stückpreis
            page.drawText(getters.lotsize_prices[ls]+"€", {
                x: left + 300,
                y: 833 - 330 - (4+i) * (lineSpacing+4),
                size: 12,
                lineHeight: 30,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            })
            //  Gesamt
            page.drawText((getters.lotsize_prices[ls] * ls).toFixed(2) + "€", {
                x: left + 400,
                y: 833 - 330 - (4+i) * (lineSpacing+4),
                size: 12,
                lineHeight: 30,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            })
        }

        return page
    }
}
function base64ToArrayBuffer(binaryString) {
    var bytes = new Uint8Array(binaryString);
    for (var i = 0; i < binaryString; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}
function saveByteArray(reportName, byte) {
    var blob = new Blob([byte], { type: "application/pdf" });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();
};

import { PDFDocument, StandardFonts, rgb } from 'pdf-lib'




import Vue from "vue";
import store from '@/store/index.js'
const exp = {
    state: {
        export_message: ""
    },
    mutations: {
        setCustomerInformation(state, [rootState, key, value]) {
            Vue.set(rootState.calculation.information.customer, key, value)
        },
        exportPDF(state, type) {
            pdf.createPdf(this.getters, type).then(response => {
                console.log(response);
                if (response) {
                    Vue.set(state, "export_message", response)
                }
                else Vue.set(state, "export_message", "")
            })
        }
    },
    actions: {

    },
    getters: {
        customerInformation: (state, getters, rootState) => {
            if (typeof rootState.calculation.information.customer != "object") rootState.calculation.information.customer = {}
            if (!rootState.calculation.information.customer) Vue.set(rootState.calculation.information, "customer", {})
            return rootState.calculation.information.customer
        },
        export_message: (state) => state.export_message
    }
}
export default exp