// import * as THREE from '/build/three.module.js';
// import { OrbitControls } from '/jsm/controls/OrbitControls.js';
// import { STLLoader } from './jsm/loaders/STLLoader.js';
import Vue from "vue";
import store from "@/store";
import apiCall from '@/apiCall'
import s3Upload from '@/s3Upload'

var THREE = require('three')
const OrbitControls = require('three-orbitcontrols')
var STLLoader = require('three-stl-loader')(THREE)
// import CSG from '@/store/three/CSGMesh.js'
// import Geometry from '@/store/three/Geometry.js'
// console.log(new Geometry());


class Viewer {
    constructor() {
        this.ratio = 16/9
        this.renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true, preserveDrawingBuffer: true });
        this.renderer.setSize(window.innerWidth, window.innerWidth/this.ratio);
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setClearColor(0x000000, 0);
        // this.renderer.setClearColor(0xffffff, 1);
        this.scene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera(45, this.ratio, 1, 90);
        this.raycaster = new THREE.Raycaster();
        this.mouse = new THREE.Vector2();
        this.faceMaterials = {}

        new OrbitControls(this.camera, this.renderer.domElement);
        this.listeners()

        this.position_reset()
        this.light_load()

        this.materials = {
            transparent: new THREE.MeshPhongMaterial({
                color: 0x00426D,
                opacity: 0.75,
                transparent: true, 
                side: THREE.DoubleSide,
                depthWrite: false
            })
        }
    }
    load(container) {
        this.container = container

        this.canvas_scale()
        this.container.appendChild(this.renderer.domElement);

        this.renderer.setAnimationLoop(() => {
            this.renderer.render(this.scene, this.camera);
        });
    }
    listeners() {
        this.mouseDownPosition = [0, 0]
        window.addEventListener('resize', () => { this.canvas_scale() }, false);
        window.addEventListener('mousedown', (event) => {
            this.mouseDown = 1
            this.mouseDownPosition = [event.clientX, event.clientY]
        }, false);
        window.addEventListener("mouseup", (event) => {
            if (!this.onClick) return
            this.mouseDown = 0
            if (this.mouseDownPosition.join("~") != [event.clientX, event.clientY].join("~")) return

            const card = this.renderer.domElement.getBoundingClientRect()
            this.mouse.x = ((event.clientX - card.x) / card.width) * 2 - 1;
            this.mouse.y = - ((event.clientY - card.y) / card.height) * 2 + 1;
            //  Mouse Picking
            this.raycaster.setFromCamera(this.mouse, this.camera);
            const intersects = this.raycaster.intersectObjects(this.scene.children);
            var minDist = { distance: Infinity }
            intersects.map(mesh => mesh.distance < minDist.distance && mesh.object.name.match(/face/) ? minDist = mesh : "")

            if (minDist.distance == Infinity) return

            const button = [0, "left", "middle", "right"][event.which]
            this.onClick(minDist, button, intersects)
        })
        window.addEventListener("mousemove", (event) => {
            if (!this.onHover) return
            const card = this.renderer.domElement.getBoundingClientRect()
            this.mouse.x = ((event.clientX - card.x) / card.width) * 2 - 1;
            this.mouse.y = - ((event.clientY - card.y) / card.height) * 2 + 1;
            //  Mouse Picking
            this.raycaster.setFromCamera(this.mouse, this.camera);
            const intersects = this.raycaster.intersectObjects(this.scene.children);
            var minDist = { distance: Infinity }
            intersects.map(mesh => mesh.distance < minDist.distance && mesh.object.name.match(/face/) ? minDist = mesh : "")
            if (minDist.distance == Infinity) return

            const button = [0, "left", "middle", "right"][event.which]
            this.onHover(minDist, button, intersects)
        })
    }
    canvas_scale() {
        if (!this.container) return
        const card = this.container.getBoundingClientRect()
        this.renderer.domElement.style.width = "100%"
        this.renderer.domElement.style.height = card.width / this.ratio + "px"
        this.renderer.domElement.style.outline = "none"
        this.camera.aspect = this.ratio
        this.camera.updateProjectionMatrix();
        // if (document.getElementById("settings")) {
        //     document.getElementById("settings").style.left = card.width - 200 + "px"
        //     document.getElementById("settings").style.top = card.height + "px"
        // }
        if (document.getElementById("container") && document.getElementById("container").childNodes[0]) {
            document.getElementById("container").childNodes[0].style.maxWidth = card.width - 201-2.5*16 + "px"
        }
    }
    async loadFile(id, type) {
        if (this.id) {
            let i = 0
            while (this.scene.children[i]) {
                if (this.scene.children[i].name) this.scene.remove(this.scene.children[i]);
                else i++;
            }
        }
        // console.log(id, type);
        // testCSG()
        // return 
        this.id = id
        if (type == "specific") type = threeViewer.state.company
        const file = await getFile(`${id}/${id}_processed.json`, type)
        file.stl = decompressStl(file.stl)
        this.faces = file.faces
        this.stls_load(file, id)
        if (this.loaded) this.loaded(file)
    }
    stls_load(data, id) {
        // if (threeViewer.state.loadedId == id) return
        var boxes = [[], [], []]
        var enc = new TextEncoder();
        data.stl.forEach((stl, i) => {
            var arrayBuffer = enc.encode(stl).buffer;
            var geometry = new STLLoader().parse(arrayBuffer);
            geometry.computeVertexNormals()
            var material = this.material_get("std")
            var mesh = new THREE.Mesh(geometry, material);
            mesh.name = "face_" + i
            mesh.information = data.faces[i]
            this.scene.add(mesh);
            // subtractCut(mesh)

            var box = new THREE.Box3().setFromObject(mesh);
            // var size = new THREE.Vector3()
            boxes[0].push(box.min.x, box.max.x)
            boxes[1].push(box.min.y, box.max.y)
            boxes[2].push(box.min.z, box.max.z)
        });
        this.domain = [
            Math.max(...boxes[0]) - Math.min(...boxes[0]),
            Math.max(...boxes[1]) - Math.min(...boxes[1]),
            Math.max(...boxes[2]) - Math.min(...boxes[2])
        ]
        const maxScale = Math.max(...this.domain)
        this.scene.children.forEach(element => {
            if (!element.name.match(/face/)) return
            element.scale.x = 10 / maxScale
            element.scale.y = 10 / maxScale
            element.scale.z = 10 / maxScale
            // element.position.z -= 5
            element.position.x -= data.shell.centreOfMass[0] * 10 / maxScale
            element.position.y -= data.shell.centreOfMass[1] * 10 / maxScale
            element.position.z -= data.shell.centreOfMass[2] * 10 / maxScale
        });
        Vue.set(threeViewer.state, "loadedId", id)
    }
    material_get(type, color) {
        let mat
        switch (type) {
            case "std":
                mat = new THREE.MeshPhongMaterial({
                    color: 0x00426D,
                    specular: 0x333333,
                    shininess: 1,
                    side: THREE.DoubleSide,
                    // flatShading : false,
                    emissive: 1,
                    opacity: 0.95,
                    transparent: true,
                    // depthWrite: false
                })
                break;
            case "hover":
                mat = new THREE.MeshPhongMaterial({
                    color: "#00096d",
                    specular: 0x333333,
                    shininess: 1,
                    side: THREE.DoubleSide,
                    // flatShading : false,
                    emissive: 1,
                    opacity: 1,
                    transparent: true,
                    // depthWrite: false
                })
                break;
            case "selected":
                mat = new THREE.MeshPhongMaterial({
                    color: (color || 0x004200),
                    specular: 0x333333,
                    shininess: 0,
                    side: THREE.DoubleSide,
                    // flatShading : false,
                    emissive: 1,
                    opacity: .85,
                    transparent: true,
                    // depthWrite: false
                })
                break;
        }
        return mat
    }
    resetMaterials() {
        this.scene.children.forEach(element => {
            if (!element.name) return
            if(this.faceMaterials[element.name]) {
                element.material = this.faceMaterials[element.name]
            }
            else element.material = this.material_get("std")
        });
    }
    light_load() {
        var directionalLight = new THREE.DirectionalLight(0xfafafa);
        directionalLight.position.x = 1;
        directionalLight.position.y = -1;
        directionalLight.position.z = 20;
        directionalLight.position.normalize();

        var light1 = new THREE.PointLight(0xffffff);
        // const pointLightHelper = new THREE.PointLightHelper(light1);
        light1.position.x = 15
        light1.position.y = 15
        light1.position.z = -1
        // light1.add(pointLightHelper);

        var light2 = new THREE.PointLight(0xbbbbbb);
        light2.position.x = -5
        light2.position.y = -5
        light2.position.z = 1

        const rectLight = new THREE.RectAreaLight(0xffffff, 1, 10, 5);
        rectLight.position.set(5, 5, 0);
        rectLight.lookAt(0, 0, 5);

        this.scene.add(new THREE.AmbientLight(0xAAAAAA));
        this.scene.add(directionalLight);
        this.scene.add(light1);
        this.scene.add(light2);
        this.scene.add(rectLight)
    }
    position_reset() {
        this.camera.position.y = 5;
        this.camera.position.x = 5;
        this.camera.position.z = 5;
        this.camera.lookAt(0, 0, 0);
    }
    preview_create() {
        var images = []
        this.renderer.setSize(360, 360);
        let oldScale = [this.renderer.domElement.style.width, this.renderer.domElement.style.height]
        this.renderer.domElement.style.display = "none"
        this.renderer.domElement.style.width = "360px"
        this.renderer.domElement.style.height = "360px"
        this.camera.aspect = 1
        this.camera.fov = 3
        this.camera.far = 1000
        this.camera.position.y = 141;
        this.camera.position.x = 141;
        this.camera.position.z = 141;
        this.camera.lookAt(0, 0, 0);
        this.camera.updateProjectionMatrix();
        setTimeout(() => {
            images.push(this.renderer.domElement.toDataURL('image/png'))
            this.camera.position.y = 0;
            this.camera.position.x = 0;
            this.camera.position.z = 200;
            this.camera.lookAt(0, 0, 0);
            setTimeout(() => {
                images.push(this.renderer.domElement.toDataURL('image/png'))
                this.camera.position.y = 200;
                this.camera.position.x = 0;
                this.camera.position.z = 0;
                this.camera.lookAt(0, 0, 0);
                setTimeout(() => {
                    images.push(this.renderer.domElement.toDataURL('image/png'))
                    this.camera.position.y = 0;
                    this.camera.position.x = 200;
                    this.camera.position.z = 0;
                    this.camera.lookAt(0, 0, 0);
                    setTimeout(() => {
                        images.push(this.renderer.domElement.toDataURL('image/png'))
                        reset()
                        this.preview_save(images)
                    }, 16)
                }, 16)
            }, 16)
        }, 16)

        const reset = () => {
            this.position_reset()
            this.camera.fov = 45
            this.camera.far = 90
            this.camera.aspect = window.innerWidth / window.innerHeight
            this.camera.updateProjectionMatrix();
            this.renderer.domElement.style.display = ""
            this.renderer.setSize(window.innerWidth, window.innerHeight);
            [this.renderer.domElement.style.width, this.renderer.domElement.style.height] = oldScale
            this.canvas_scale()
        }
    }
    preview_save(images) {
        images.forEach((image, i) => {            
            s3Upload(`${threeViewer.state.company}/${this.id}/${this.id}_preview_${i}.png`, `["${image}"]`)//image)
        });
    }
}
var viewer = new Viewer()
viewer.loaded = (data) => {
    viewer.preview_create()
    // if (!thisObj.values.existing) {
    //     thisObj.saveFileInformation()
    // }
    console.log(data);
    store.commit("changeSubprocessValue", ["template", "existing", true]);
    store.commit("changeSubprocessValue", ["template", "volume", data.shell]);

    for (const subProcess in data.calculation) {
        for (const key in data.calculation[subProcess]) {
            store.commit("changeSubprocessValue", [subProcess, key, data.calculation[subProcess][key]]);
        }
    }
}

const threeViewer = {
    state: {
        loaded: false,
        previews: {
            specific: {},
            general: {}
        },
        viewer: viewer
    },
    mutations: {
        // viewer_test(state) {

            
        //     // viewer.loadFile("1613057900938_PrimeCalc_stp_processed")
        //     // Vue.set(state, "loaded", true)
        // },
        setCompany(state, [company]) {
            state.company = company
        },
        viewer_load(state, [company]) {
            viewer.load(document.getElementById('viewer-container'))
            viewer.canvas_scale()
            state.company = company
            // if (state.loaded) return
        },
        loadFile(state, [id, type]) {
            if (state.loaded && this.id == id) return
            state.id = id
            state.type = type
            viewer.loadFile(id, type)
            Vue.set(state, "loaded", true)
        },
        previews_get(state, processType) {
            Vue.set(state, "previews", {
                specific: {},
                general: {}
            })
            apiCall({ method: "file_step_getExisting" }).then((existingDrawings=>{
                let previewInfo = []
                existingDrawings.forEach(info => {if (info.processType == processType) previewInfo.push(info)})
                Vue.set(threeViewer.state, "previewInformation", previewInfo)
                // console.log(threeViewer.state.company);
                previews_download(previewInfo.map(d => d.id), [0], threeViewer.state.company)
            }))
            let templates = []
            switch (processType) {
                case "milling":
                    templates = [
                        "1613313874342499",
                        "1613319555833885"
                    ]
                    break;
                case "turning":
                    templates = [
                        "1613318490824820",
                    ]
                    break;
            }
            if (templates.length) previews_download(templates, [0])
        },
        previewPerspectives_get(state, [id, type]) {
            previews_download([id], [1, 2, 3], type)
        }
    },
    actions: {

    },
    getters: {
        previews: (state) => state.previews,
        previewInformation: (state) => state.previewInformation,
        viewer: (state) => state.viewer,
        settingsPosition: (state) => {
            if (document.getElementById("settings")) {
                document.getElementById("settings").style.left = Math.round(state.viewer.container.getBoundingClientRect().width - 200) + "px"
                document.getElementById("settings").style.top = Math.round(state.viewer.container.getBoundingClientRect().height) + "px"
            }
            return "left: "+Math.round(state.viewer.container.getBoundingClientRect().width - 200) + "px;top: "+Math.round(state.viewer.container.getBoundingClientRect().height) + "px"
        }
    }
}

export default threeViewer


async function previews_download(ids, perspectives, user="general") {
    // ids.forEach(id => Vue.set(threeViewer.state.previews, id, []));
    if(user == "specific") user = threeViewer.state.company
    ids.forEach(async (id) => {
        if (!threeViewer.state.previews[user]) Vue.set(threeViewer.state.previews,user, {})
        if (!threeViewer.state.previews[user][id]) Vue.set(threeViewer.state.previews[user], id, [])
        perspectives.forEach(async (i) => {
            if (threeViewer.state.previews[user][id][i] || !id) return
            Vue.set(threeViewer.state.previews[user][id], i, await getFile(`${id}/${id}_preview_${i}.png`, user))
        });
    });
}



// viewer.onClick = (mesh) => {
//     mesh.object.material.color.set(0xff0000);
//     setTimeout(() => {
//         mesh.object.material.color.set(0x00426D);
//     }, 500)
// }

// function subtractCut(mesh) {
//     const cut = new THREE.Mesh(new THREE.BoxGeometry(5, 5, 5));
//     cut.position.add(new THREE.Vector3(2.5, 2.5, 2.5));
//     mesh.updateMatrix();
//     cut.updateMatrix();

//     const bspA = CSG.fromMesh(mesh);
//     const bspB = CSG.fromMesh(cut);

//     // Subtract one bsp from the other via .subtract... other supported modes are .union and .intersect
//     const bspResult = bspA.subtract(bspB);

//     let meshResult = CSG.toMesh(bspResult, mesh.matrix);
//     // meshResult = new THREE.Mesh(meshResult.geometry, meshA.material)
//     // Set the results material to the material of the first cube.
//     meshResult.material = new THREE.MeshPhongMaterial({
//         color: "#00096d",
//         specular: 0x333333,
//         shininess: 1,
//         side: THREE.DoubleSide,
//         // flatShading : false,
//         emissive: 1,
//         opacity: 1,
//         transparent: true,
//     });
//     // console.log(new THREE.BufferGeometry());
//     // meshResult = new THREE.BufferGeometry().fromGeometry(meshResult.geometry);
//     console.log(meshResult);
//     viewer.scene.add(meshResult);
// }

// function testCSG() {
//     // Make 2 box meshes..
//     const meshA = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), new THREE.MeshNormalMaterial());
//     const meshB = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1));

//     // Offset one of the boxes by half its width..
//     meshB.position.add(new THREE.Vector3(0.5, 0.5, 0.5));

//     // Make sure the .matrix of each mesh is current
//     meshA.updateMatrix();
//     meshB.updateMatrix();

//     // Create a bsp tree from each of the meshes
//     const bspA = CSG.fromMesh(meshA);
//     const bspB = CSG.fromMesh(meshB);

//     // Subtract one bsp from the other via .subtract... other supported modes are .union and .intersect
//     const bspResult = bspA.subtract(bspB);

//     // Get the resulting mesh from the result bsp
//     let meshResult = CSG.toMesh(bspResult, meshA.matrix);
//     // meshResult = new THREE.Mesh(meshResult.geometry, meshA.material)
//     // Set the results material to the material of the first cube.
//     meshResult.material = new THREE.MeshPhongMaterial({
//         color: "#00096d",
//         specular: 0x333333,
//         shininess: 1,
//         side: THREE.DoubleSide,
//         // flatShading : false,
//         emissive: 1,
//         opacity: 1,
//         transparent: true,
//     });
//     // console.log(new THREE.BufferGeometry());
//     // meshResult = new THREE.BufferGeometry().fromGeometry(meshResult.geometry);
//     console.log(meshResult);
//     viewer.scene.add(meshResult);
//     // viewer.scene.add(meshB);
// }






const getFile = async (path, user="general") => {
    const session = await Vue.prototype.$Amplify.Auth.currentSession();
    const myInit = {
        mode: "no-cors",
        headers: {
            authorization: session.getIdToken().getJwtToken(),
        }
    }
    return await Vue.prototype.$Amplify.API.get("primecalc_getDrawings", `/prod/${user}/${path}`, myInit)
}
function decompressStl(stl) {
    let decompressed = []
    stl.forEach(segment => {
        let decSeg = "solid \n"
        segment.forEach(face => {
            decSeg += ` facet normal  ${face[0][0]}  ${face[0][0]}  ${face[0][0]}\n  outer loop\n`
            decSeg += `   vertex ${face[1][0]}  ${face[1][1]}  ${face[1][2]}\n`
            decSeg += `   vertex ${face[2][0]}  ${face[2][1]}  ${face[2][2]}\n`
            decSeg += `   vertex ${face[3][0]}  ${face[3][1]}  ${face[3][2]}\n  endloop\n endfacet`
        });
        decSeg += "endsolid"
        decompressed.push(decSeg)
    });
    return decompressed
}