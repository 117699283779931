var AWS = require('aws-sdk');
AWS.config.update({
    accessKeyId: "AKIAYREDS43NIOYPXQFP",
    secretAccessKey: "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",
    region: "eu-central-1"
});

const s3Upload = async (filename, content) => {
    var s3 = new AWS.S3();
    var params = {
        Bucket: 'primecalc-files',
        Key: filename,
        Body: content
    };
    return s3.putObject(params).promise()
    //     .then(data => {
    //         console.log(data);
    //         return data
    //     }).catch(err => {
    //         console.log(err);
    //         return 0
    //     })
    // console.log(response);
    // return response
}



export default s3Upload

