<template>
    <div id="box">
        <div id="subProcessOptions" class="card" v-if="subProcess_selected">
            <h3 class="card_title">Honen</h3>
            <div class="padding" id="dimensioning">
                
                
                <p v-if="verify" @click="$store.dispatch('subProcesses_next')" class="blue padding_small radius fitContent pointer" style="margin: .5em 0 0 auto;">weiter</p>
            </div>
        </div>

        <!-- <div class="card" v-if="verify">
            <h3 class="card_title">Prozess genauer definieren</h3>
            <div class="padding">
                
            </div>
        </div> -->
    </div>
</template>

<script>
export default {
    methods: {
        setValue(key, value) {
            this.$store.commit("changeSubprocessValue", ["holes", key, value]);
            this.$store.dispatch("refresh")
        }
    },
    computed: {
        values() {
            return this.$store.getters.values("value")
        },
        subProcess_selected() {
            return this.$store.state.subProcess_selected
        },
        verify() {
            return this.$store.getters.verify.processes[this.$store.state.process_selected].subProcesses[this.$store.state.subProcess_selected]
        },
    }
}
</script>

<style scoped>
    #box>.card:first-child{margin-bottom: 2em;}
    #box>.card{width: fit-content;}
    img{
        margin: auto;
        width: auto;
        height: 100%;
        min-height: 10em;
    }
    .dimGrid{
        display: grid;
        grid-gap: 4px;
        grid-template-columns: 2fr 4em 1fr;
    }
</style>
