// var path = ""
// if (document.location.href.match('https://primecalc.de')) {
//     path = ()=>'https://primecalc.de/bin/' 
// }
// else {
//     path = () =>'https://development.primecalc.de/bin/' 
// }

import Vue from "vue";
const path = {
    state: {
        colorIndex: -1,
        colors_raw: [
            "#C75957",
            "#575AC7",
            "#C75789",
            "#C79857",
            "#82C757",
            "#5784C7",
            "#57C773",
            "#8557C7",
            "#57A2C7",
            "#AB57C7",
            "#57C7AB",
            "#C757BF"
        ],
        debug_3d: {}
    },
    mutations: {
        debug_3d(state, [mesh]) {
            let info = JSON.parse(JSON.stringify(mesh.object.information))

            info.forEach(element => {
                for (const key in element) {
                    if (typeof(element[key]) == "number") {
                        if (element[key] > 3.141592 && element[key] < 3.141593) element[key] = "pi"
                        else if (element[key] > 2*3.141592 && element[key] < 2*3.141593) element[key] = "2pi"
                        else element[key] = Math.round(element[key] * 100) / 100
                    }
                    else if (element[key].forEach) {
                        element[key].forEach((number, i) => {
                            if (number > 3.141592 && number < 3.141593) element[key][i] = "pi"
                            else if (number > 2*3.141592 && number < 2*3.141593) element[key][i] = "2pi"
                            else element[key][i] = Math.round(number * 100) / 100
                        });
                    }
                    else {
                        for (const key2 in element[key]) {
                            if (typeof (element[key][key2]) == "number") {
                                if (element[key][key2] > 3.141592 && element[key][key2] < 3.141593) element[key][key2] = "pi"
                                else if (element[key][key2] > 2*3.141592 && element[key][key2] < 2*3.141593) element[key][key2] = "2pi"
                                else element[key][key2] = Math.round(element[key][key2] * 100) / 100
                            }
                            else if (element[key][key2].forEach) {
                                element[key][key2].forEach((number, i) => {
                                    if (number > 3.141592 && number < 3.141593) element[key][key2][i] = "pi"
                                    else if (number > 2*3.141592 && number < 2*3.141593) element[key][key2][i] = "2pi"
                                    else element[key][key2][i] = Math.round(number * 100) / 100
                                });
                            }
                        }
                    }
                }
            });

            Vue.set(state, "debug_3d", {})
            Vue.set(state.debug_3d, "faceInfo", info.shift())
            Vue.set(state.debug_3d, "edges", info)
        }
    },
    getters: {
        path() {
            return document.location.href.match('https://primecalc.de') ? 'https://primecalc.de/bin/' : 'https://development.primecalc.de/bin/';
        },
        path_data() {
            return document.location.href.match('https://primecalc.de') ? 'https://primecalc.de/bin/data.php' : 'https://development.primecalc.de/bin/data.php';
        },
        path_account() {
            return document.location.href.match('https://primecalc.de') ? 'https://primecalc.de/bin/account.php' : 'https://development.primecalc.de/bin/account.php';
        },
        color: (state) => ()=>{
            Vue.set(state, "colorIndex", state.colorIndex + 1)
            return state.colors_raw[state.colorIndex % state.colors_raw.length]
        },

        debug_3d: (state) => {
            return state.debug_3d
        }
    }
}

export default path