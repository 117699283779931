<template>
    <div id="container">
        <div class="padding glass">
            <b>Innenbearbeitung:</b>

            <div class="padding" id="gridContainer">

            </div>

            <!-- <div id="manufacturingtime" class="card">
                <h3 class="card_title padding_small dark radius">Prozess genauer definieren</h3>
                <div class="padding">
                </div>
            </div> -->
        </div>
        
    </div>
</template>
<!--
<template>
    <div id="container">
        <div class="padding glass">
            <div class="padding" id="dimensioning">
                <div id="gridContainer">
                    <div class="opacity card pointer padding" :class="{blue: values.selected == 'simple'}" @click="setInside('simple')">
                        <img src="https://development.primecalc.de/assets/images/inside_0.png" alt="">
                        <p>Wenig Bearbeitung</p>
                    </div>
                    <div class="opacity card pointer padding" :class="{blue: values.selected == 'turning'}" @click="setInside('turning')">
                        <img src="https://development.primecalc.de/assets/images/inside_1.png" alt="">
                        <p>Mittlere Bearbeitung</p>
                    </div>
                    <div class="opacity card pointer padding" :class="{blue: values.selected == 'complex'}" @click="setInside('complex')">
                        <img src="https://development.primecalc.de/assets/images/inside_2.png" alt="">
                        <p>Viel Bearbeitung</p>
                    </div>
                    <div>
                        <p><b>Tiefe:</b></p>
                        <div class="flex indent textspacing">
                            <input type="range" min="0" max="10" step="1" :value="values.inside_depth/10" @input="changeValue('inside_depth', $event.target.value*10)">
                            <p>{{values.inside_depth}}%</p>
                        </div>                    
                    </div>
                    <div>
                        <p><b>Gemittelter Durchmesser:</b></p>
                        <div class="flex indent textspacing">
                            <input type="number" min="0" :max="dimensions_diameter" :value="values.inside_diameter" @input="changeValue('inside_diameter', $event.target.value)">
                            <p>mm ({{diameter_ratio}}%)</p>
                        </div>
                    </div>
                    <p v-if="verify" @click="$store.dispatch('subProcesses_next')" class="blue padding_small radius fitContent pointer">weiter</p>
                </div>
            </div>

             <div class="padding">
                 <p class="textspacing"><b>Schnittparameter:</b></p>
                 <div id="cuttingParameters" class="indent textspacing">
                     <div>
                         <p>Schnitttiefe ap:</p>
                         <input type="number" :value="values.ap" min="0" step=".1" @input="changeValue('ap', $event.target.value)">
                         <p>mm</p>
                     </div>
                     <div>
                         <p>Vorschub f:</p>
                         <input type="number" :value="values.f" min="0" step="0.05" @input="changeValue('f', $event.target.value)">
                         <p>mm/U</p>
                     </div>
                     <div>
                         <p>Schnittgeschwindigkeit vc:</p>
                         <input type="number" :value="values.vc" min="0" step="1" @input="changeValue('vc', $event.target.value)">
                         <p>m/min</p>
                     </div>
                 </div>


                 <p class="textspacing"><b>Schlichten:</b></p>
                 <div class="flex indent textspacing">
                     <div class="flex" id="finishFlex">
                         <p :class="{blue: values.roughness_enabled}" @click="changeValue('roughness_enabled', 1)" class="padding_small pointer radius dark">Ja</p>
                         <p :class="{blue: values.roughness_enabled == false}" @click="changeValue('roughness_enabled', 0)" class="padding_small pointer radius dark">Nein</p>
                     </div>
                     <input min="0.1" step=".1" :class="{'muted': values.roughness_enabled == false}" type="number" :value="values.roughness" @input="changeValue('roughness', $event.target.value)">
                     <p>μm</p>
                 </div>
                 <p class="textspacing"><b>Benötigte Werkzeuge:</b></p>
                 <div class="flex indent textspacing">
                     <p>Anzahl:</p>
                     <input min="1" type="number" :value="values.toolcount" @input="changeValue('toolcount', $event.target.value)">
                 </div>
             </div>
         </div>
        
     </div>
</template>
 -->
<script>
export default {
    data() {
        return {
        }
    },
    methods: {
        changeValue(key, value) {
            switch (key) {
                case "ap":
                        value <= 0.02 ? value = .1 : ""
                    break;
                case "f":
                        value <= 0.001 ? value = .005 : ""
                    break;
                case "vc":
                        value <= 0 ? value = 1 : ""
                    break;
                case "roughness":
                        value < 0.01 ? value = .01 : ""
                    break;
                default:
                    break;
            }
            this.$store.commit("changeSubprocessValue", ["inside-complexity", key, value]);
            this.$store.dispatch("refresh")
        },
        setInside(value) {
            if (value === this.values.selected) this.$store.dispatch("subProcesses_next")
            this.$store.commit("changeSubprocessValue", ["inside-complexity", "selected", value]);
            this.$store.dispatch("refresh")
        },
    },
    computed: {
        values() {
            let values = this.$store.getters.subProcess()
            if (!values.ap) this.changeValue("ap", 2)
            if (!values.f) this.changeValue("f", .2)
            if (!values.vc) this.changeValue("vc", 150)
            if (!values.roughness) this.changeValue("roughness", .8)
            if (!values.toolcount) this.changeValue("toolcount", 1)
            if (values.roughness_enabled === "") this.changeValue("roughness_enabled", 0)
            if(!values.inside_depth) this.changeValue("inside_depth", 30) 
            if(!values.inside_diameter) this.changeValue("inside_diameter", ~~(this.dimensions_diameter/3)) 
            return this.$store.getters.subProcess()
        },
        dimensions_diameter() {
            return parseFloat(this.$store.getters.specificValue(this.$store.state.process_selected,"dimensions", "diameter").value)
        },
        diameter_ratio() {
            return (100*this.values.inside_diameter/this.dimensions_diameter).toFixed(0)
        },
        subProcess_selected() {
            return this.$store.state.subProcess_selected
        },
        verify() {
            return this.$store.getters.verify.processes[this.$store.state.process_selected].subProcesses[this.subProcess_selected]
        },
    }
}
</script>

<style scoped>
    #box>.card:first-child{margin-bottom: 2em;}
    #box>.card{width: fit-content;}
    .opacity{
        transition: .25s;
        opacity: .85;
    }
    .opacity:hover{opacity: 1;}
    #gridContainer{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 2em;
        grid-row-gap: 1em;
    }
    #gridContainer>p{height: fit-content; margin: auto 0 0 auto;}
    img{
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        height: 12em;
        width: 12em;
        margin: 1em auto;
    }

    #cuttingParameters>div{
        display: grid;
        grid-template-columns: auto 4em 4em;
        grid-column-gap: 1em;
        margin-bottom: 4px;
    }

    #finishFlex>:nth-child(1) {border-top-right-radius: 0; border-bottom-right-radius: 0;}
    #finishFlex>:nth-child(2) {border-top-left-radius: 0; border-bottom-left-radius: 0;}
    #nescessaryGrid{
        margin-top: 8px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 1em;
    }
</style>