<template>

    <div class="card">
        <h3 class="card_title">PDF einlesen</h3>
        <div class="padding flex">
            <p class="dark padding_small radius" style="margin-right: 1em">PDF einlesen</p>
            <p class="dark padding_small radius">keine PDF vorhanden</p>
            <!-- <img class="dark padding_small radius" src="https://primecalc.de/assets/icons/pdf.svg" alt="PDF">
            <img class="dark padding_small radius spacingleft" src="" alt="no PDF"> -->
        </div>
    </div>
    
</template>

<script>
export default {

}
</script>

<style scoped>

</style>