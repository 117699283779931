<template>

    <div id="container">
        <div class="padding glass flex gridGap_large"> 

            <div v-if="1 || $store.getters.subProcess('template').type == 'general'">
                <b class="nowrap">Volumenverhältnis Teil/Halbzeug</b>
                <div class="flex">
                    <p style="width: 3em">{{(values.ratio*100).toFixed(0)}}%</p>
                    <input style="width: 14em" type="range" min="1" max="19" :value="values.ratio*20" @input="setValue('ratio', $event.target.value/20)">
                </div>
            </div>
            <div id="scrollContainer">
                <div v-if="!selector_active">
                    <b class="nowrap">Verwendete Werkzeuge - Schnittwerte:</b>
                    <div class="flex gridGap">
                        <div id="toolContainer" class="flex" v-for="(tool, t) in values.tools" :key="t">
                            <div class="flexRow gridGap">
                                <img class="toolImage" :src="images[tool.type]" alt="">
                                <img v-if="values.tools.length > 1" id="toolDelete" @click="deleteTool(t)" class="pointer" src="https://dashboard.pixelprime.de/assets/icons/delete.svg" alt="">
                            </div>
                            
                            <div class="infoGrid">
                                <p title="Schnitttiefe">ap:</p>
                                <input type="number" step="0.1" :value="tool.ap" @input="setToolValue('ap', t, $event.target.value)">
                                <p> mm</p>
                                <p title="Vorschub">f:</p>
                                <input type="number" step="0.05" :value="tool.f" @input="setToolValue('f', t, $event.target.value)">
                                <p> mm/U</p>
                                <p title="Schnittgeschwindigkeit">vc:</p>
                                <input type="number" step="10" :value="tool.vc" @input="setToolValue('vc', t, $event.target.value)">
                                <p> m/min</p>
                                <!-- <div title="Schneidradius"><p>r:</p> <input type="number" step=".1" :value="tool.r" @input="setToolValue('r', t, $event.target.value)"><p> mm</p></div>
                                <div title="Rautiefe"><p>rz:</p> <input type="number" step="1" :value="tool.rz" @input="setToolValue('rz', t, $event.target.value)"><p>μm</p></div> -->
                            </div>

                            <div id="ratioGrid" v-if="values.tools.length > 1">
                                <div>
                                    <p title="Volumenanteil des Werkzeuges am Schruppen">Anteil:</p>
                                    <div class="flex">
                                        <p class="blue padding_small radius pointer" @click="setToolValue('ratio', t, parseInt(tool.ratio)+5)">+</p>
                                        <p class="blue padding_small radius pointer" @click="setToolValue('ratio', t, tool.ratio-5)">-</p>
                                    </div>
                                    <p>{{(ratios[t]*100).toFixed()}}%</p>
                                </div>
                            </div>
                        </div>
                        <img class="blue padding_small radius pointer" @click="selector_active = true" src="https://dashboard.pixelprime.de/assets/icons/add.svg" alt="">
                    </div>
                </div>

                <div v-else id="newTool">
                    <b class="nowrap">Neues Werkzeug hinzufügen:</b>
                    <div class="flex">
                        <div class="flex gridGap_large">
                            <div v-for="(tool, t) in tools" :key="t" class="flex pointer radius fitContent" :class="{dark: t%2==0}" @click="addTool(t)">
                                <img :src="images[tool.type]" alt="">
                                <p style="margin: auto 0">{{$store.getters.dict(t)}}</p>
                            </div>
                        </div>
                        <p style="margin-left: 2em; height: fit-content;" class="blue padding_small radius pointer fitContent" @click="selector_active = false">Zurück</p>
                    </div>
                </div>
            </div>

        </div>
        
    </div>

</template>

<script>
export default {
    data() {
        return {
            roughness_types: ["Ra", "Rz"],
            selector_active: false,
            images: {
                C: "https://primecalc.de/assets/icons/tools/C.png",
                V: "https://primecalc.de/assets/icons/tools/V.png",
                Wiper: "https://primecalc.de/assets/icons/tools/W.png"
            },
            tools: {
                C: {
                    type: "C",
                    ap: 2,
                    f: 0.2,
                    vc: 150,
                    r: 0.8,
                    ratio: 20
                },
                V: {
                    type: "V",
                    ap: 1,
                    f: 0.1,
                    vc: 150,
                    r: .4,
                    ratio: 5
                },
                Wiper: {
                    type: "Wiper",
                    ap: 2,
                    f: .3,
                    vc: 150,
                    r: .8,
                    ratio: 10
                }
            },
        }
    },
    mounted() {

    },
    methods: {    
        setValue(key, value) {
            switch (key) {
                case "ap":
                        value <= 0.02 ? value = .1 : ""
                    break;
                case "f":
                        value <= 0.001 ? value = .005 : ""
                    break;
                case "vc":
                        value <= 0 ? value = 1 : ""
                    break;
                case "roughness":
                        value < 0.01 ? value = .01 : ""
                    break;
                case "finishing_f":
                        var radius = 0.4
                        var rz = Math.round(value**2/(8*radius)*1000)
                        this.$store.commit("changeSubprocessValue", ["contour", "finishing_rz", rz]);
                    break;
                case "finishing_rz":
                        radius = 0.4
                        var f = Math.round((value/1000*8*radius)**0.5*100)/100
                        this.$store.commit("changeSubprocessValue", ["contour", "finishing_f", f]);
                    break;
                default:
                    break;
            }
            this.$store.commit("changeSubprocessValue", ["contour", key, value]);
            this.$store.dispatch("refresh")
        },          
        addTool(t) {
            const tool = this.tools[t]
            this.$store.commit("pushSubprocessValue", ["contour", "tools", tool])
            this.$store.dispatch("refresh")
            this.selector_active = false            
        },
        deleteTool(t) {            
            this.$store.commit("deleteSubprocessList", ["contour", "tools", t])
            this.$store.dispatch("refresh")
        },
        setToolValue(key, t, value) {
            if(key == "ratio" && value < 1) value = 1
            this.$store.commit("changeSubprocessListValue", ["contour", "tools", t, key, value])
            this.$store.dispatch("refresh")
        },
    },
    computed: {
        values() {
            let values = this.$store.getters.subProcess()
            if (!values.tools || !values.tools.length) {
                this.addTool("C")
            }
            return values
        },
        selected() {
            return this.$store.state.calculation.processes[this.$store.state.process_selected].subProcesses["contour"].values.selected.value
        },
        subProcess_selected() {
            return this.$store.state.subProcess_selected
        },
        verify() {
            return this.$store.getters.verify.processes[this.$store.state.process_selected].subProcesses[this.$store.state.subProcess_selected]
        },
        ratios() {
            let ratioSum = 0
            this.values.tools.map(tool => ratioSum += tool.ratio)
            return this.values.tools.map(tool => tool.ratio/ratioSum)
        },
        times () {
            let time = 0
            this.$store.getters.times.forEach(t => {
                if(t.type == "roughing") time = t.time
            });
            return time
        },
        // propertiesWidth() {
        //     document.getElementById("propertyContainer").style.maxWidth = document.getElementById("processList").clientWidth-200+"px"
        //     return document.getElementById("processList").clientWidth-200
        // }
    }
}
</script>

<style scoped>
    .infoGrid{
        display: grid;
        grid-template-columns: auto auto auto;
        grid-column-gap: .5em;
        grid-row-gap: .25em;
    }
    /* p{text-align: center;} */

    #cuttingParameters>div{
        display: grid;
        grid-template-columns: auto 4em 4em;
        grid-column-gap: 1em;
        margin-bottom: 4px;
    }
    #finishFlex>:nth-child(1) {border-top-right-radius: 0; border-bottom-right-radius: 0;}
    #finishFlex>:nth-child(2) {border-top-left-radius: 0; border-bottom-left-radius: 0;}
    #finishing>input{
        width: 4em;
        height: 1.5em;
        margin: .25em .5em;
    }
    #finishing>p{
        margin: auto 0 auto 0;
    }

    #tools>input{
        width: 4em;
        margin-left: .5em;
    }

    .toolImage{
        position: relative;
        width: 3em;
        height: 3em;
        top: 50%;
        transform: translateY(-50%);
    }
    .toolGrid>div{
        display: grid;
        grid-template-columns: 2em 1fr 4em;
        margin-bottom: 4px;
    }

    #scrollContainer{
        overflow: scroll;
    }
    #scrollContainer::-webkit-scrollbar {
        height: 8px;              /* height of horizontal scrollbar ← You're missing this */
    }

    #ratioGrid>div{
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }
    #ratioGrid>div>div>p{
        width: 1em;
        margin-left: 4px;
        text-align: center;
    }
    #ratioGrid>div>p{
        user-select: none;
        text-align: center;
    }
        /* #ratioGrid>div:nth-child(3){
            grid-area: 2/2/4/3;
            position: relative;
        } */
        /* #ratioGrid>div>p{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        } */

    #newTool{

    }
</style>