<template>
    <div id="container">
        <div class="padding glass">
            <b>Innenbearbeitung:</b>
            <div class="flex gridGap">
                <div class="flex gridGap_small">
                    <div class="flex padding_small radius" v-for="(t, i) in values.holes" :key="i">
                        <img @click="hole_selected = i" class="radius_small pointer" :style="{backgroundColor: t.color}" src="https://dashboard.pixelprime.de/assets/icons/categories/interaction_clickmap.svg" title="Bohrung">
                    </div>
                    <div id="holeAdd" class="dark padding_small pointer radius flex fitContent" @click="addHole()">
                        <img style="height: 1.5em; width: 1.5em; margin:auto" src="https://dashboard.pixelprime.de/assets/icons/add.svg" alt="">
                        <p class="marginAuto">Bohrung hinzufügen</p>
                    </div>
                </div>
                <div class="flex gridGap_small">
                    <div class="flex padding_small radius" v-for="(t, i) in values.turning" :key="i">
                        <img @click="turning_selected = i" class="radius_small pointer" :style="{backgroundColor: t.color}" src="https://dashboard.pixelprime.de/assets/icons/categories/interaction_clickmap.svg" title="Fläche auswählen">
                    </div>
                    <div id="turningAdd" class="dark padding_small pointer radius flex fitContent" @click="addTurning()">
                        <img style="height: 1.5em; width: 1.5em; margin:auto" src="https://dashboard.pixelprime.de/assets/icons/add.svg" alt="">
                        <p class="marginAuto">Ausdrehen hinzufügen</p>
                    </div>
                </div>
            </div>
            <!-- <p class="pointer padding_small radius dark" :class="{'blue': visibility}" @click="insideVisibility()">Innenflächen Zeigen</p> -->

            <!-- <div id="manufacturingtime" class="card">
                <h3 class="card_title padding_small dark radius">Prozess genauer definieren</h3>
                <div class="padding">
                </div>
            </div> -->
        </div>
        
        <div id="settings" :style="$store.getters.settingsPosition" class="padding glass">
                <div v-if="h">
                    <b>Bohrung {{1+hole_selected}}:</b>
                    <div class="infoGrid indent" :style="{borderColor: h.color}">
                        <p>Anzahl:</p>
                        <input type="number" min="1" :value="h.count" @input="changeProperty('holes', hole_selected, 'count', $event.target.value)">
                        <p></p>
                        <p>Durchmesser:</p>
                        <input type="number" min="0.1" :value="h.diameter" @input="changeProperty('holes', hole_selected, 'diameter', $event.target.value)">
                        <p>mm</p>
                        <p>Tiefe:</p>
                        <input type="number" min="0.1" :value="h.depth" @input="changeProperty('holes', hole_selected, 'depth', $event.target.value)">
                        <p>mm</p>
                    </div>
                    <img class="pointer" @click="remove('hole', hole_selected)" src="https://dashboard.pixelprime.de/assets/icons/delete.svg" alt="">
                </div>
                <div v-if="t">
                    <b>Ausdrehen {{1+turning_selected}}:</b>
                    <div class="infoGrid indent" :style="{borderColor: t.color}">
                        <p>Anzahl:</p>
                        <input type="number" min="1" :value="t.count" @input="changeProperty('turning', turning_selected, 'cuts', $event.target.value)">
                        <p></p>
                        <p title="Außendurchmesser">Durchmesser:</p>
                        <input type="number" min="0.1" :value="t.diameter" @input="changeProperty('turning', turning_selected, 'diameter', $event.target.value)">
                        <p>mm</p>
                        <p title="Innendurchmesser">Bohrungsdurchmesser:</p>
                        <input type="number" min="0.1" :value="t.diameter_inside" @input="changeProperty('turning', turning_selected, 'diameter_inside', $event.target.value)">
                        <p>mm</p>
                        <p>Länge:</p>
                        <input type="number" min="0.1" :value="t.length" @input="changeProperty('turning', turning_selected, 'length', $event.target.value)">
                        <p>mm</p>
                    </div>
                    <img class="pointer" @click="remove('turning', turning_selected)" src="https://dashboard.pixelprime.de/assets/icons/delete.svg" alt="">
                </div>
            </div>    
        </div>

</template>

<script>
var THREE = require('three')
export default {
    data() {
        return {
            hole_selected: -1,
            turning_selected: -1
        }
    },
    computed: {
        values() {
            return this.$store.getters.subProcess("inside")
        },
        t() {
            if(this.turning_selected < 0) return 0
            return this.values.turning[this.turning_selected] || 0
        },
        h() {
            if(this.hole_selected < 0) return 0
            return this.values.holes[this.hole_selected] || 0
        },
        verify() {
            return this.$store.getters.verify.processes[0].subProcesses["inside"]
        }
    },
    methods: {  
        addHole() {
            this.$store.commit("pushSubprocessValue", ["inside", "holes", {
                depth: false, 
                diameter: false, 
                count: 1,
                color: this.$store.getters.color()
            }])
            this.hole_selected = this.values.holes.length-1
            this.turning_selected = -1
            this.$store.dispatch("refresh")
        },
        addTurning() {
            this.$store.commit("pushSubprocessValue", ["inside", "turning", {
                length: false, 
                diameter: false, 
                //  Dicke?
                count: 1,
                color: this.$store.getters.color()
            }])
            this.hole_selected = -1
            this.turning_selected = this.values.turning.length-1
            this.$store.dispatch("refresh")
        },
        remove(type, i) {
            this.turning_selected = -1
            this.hole_selected = -1
            this.insideVisibility(0)
            this.$store.commit("deleteSubprocessList", ["inside", type, i])
            this.$store.dispatch("refresh")
        },
        changeProperty(type, tIndex, key, value) {
            this.$store.commit("changeSubprocessListValue", ["inside", type, tIndex, key, value])
            this.$store.dispatch("refresh")
        },
        dimensionsFromShape(info) {
            const type = this.hole_selected > -1 ? "holes" : "turning"
            if (info[0].type == "Cylinder") {
                console.log(type);
                if (type == "turning") {
                    let diameter = Math.round(2*info[0].radius*1000)/1000
                    let length = Math.abs(Math.round((info[0]["V"][1]-info[0]["V"][0])*1000)/1000)
                    let diameter_inside
                    if (this.values.holes && this.values.holes[this.turning_selected] && this.values.holes[this.turning_selected].length == length) {
                        diameter_inside = this.values.holes[this.turning_selected].diameter
                    }
                    else diameter_inside = diameter-2
                    this.$store.commit("changeSubprocessListValue", ["inside", "turning", this.turning_selected, 'diameter', diameter])
                    this.$store.commit("changeSubprocessListValue", ["inside", "turning", this.turning_selected, 'diameter_inside', diameter_inside])
                    this.$store.commit("changeSubprocessListValue", ["inside", "turning", this.turning_selected, 'inside', info[0].orientation])
                    this.$store.commit("changeSubprocessListValue", ["inside", "turning", this.turning_selected, 'length', length])
                    this.$store.dispatch("refresh")
                }
                else if (type == "holes") {
                    let diameter = Math.round(2*info[0].radius*1000)/1000
                    let length = Math.abs(Math.round(info[0]["V"][1]-info[0]["V"][0]*1000)/1000)
                    this.$store.commit("changeSubprocessListValue", ["inside", "holes", this.hole_selected, 'diameter', diameter])
                    this.$store.commit("changeSubprocessListValue", ["inside", "holes", this.hole_selected, 'inside', info[0].orientation])
                    this.$store.commit("changeSubprocessListValue", ["inside", "holes", this.hole_selected, 'depth', length])
                    this.$store.dispatch("refresh")
                }
            }
            else {
                console.log(info[0]);
            }
        },

        setTransparency() {
            let viewer = this.$store.getters.viewer
            viewer.resetMaterials()
            viewer.scene.children.forEach(child => {
                if (!child.information) return
                if (child.information[0].orientation == 0) {
                    child.material = new THREE.MeshPhongMaterial({
                        color: 0x00426D,
                        opacity: 0.15,
                        transparent: true, 
                        side: THREE.DoubleSide,
                        depthWrite: false
                    })
                } 
            });
        },
        insideVisibility(state=0) {
            let viewer = this.$store.getters.viewer
            if (state) {
                this.setTransparency()
            }
            else {
                viewer.resetMaterials()
                delete viewer.onHover
                delete viewer.onClick
                return
            }

            viewer.onHover = (mesh, button, intersects) => {
                this.setTransparency()
                let found = 0
                intersects.forEach(element => {
                    if (found || element.object.information[0].orientation == 0) return
                    found = 1
                    element.object.material = new THREE.MeshPhongMaterial({
                        color: 0xaa426D,
                        opacity: 0.75,
                        transparent: true, 
                        side: THREE.DoubleSide,
                    })
                });
            }
            viewer.onClick = (mesh, button, intersects) => {
                let found = 0
                intersects.forEach(element => {
                    if (found || element.object.information[0].orientation == 0) return
                    found = element
                    element.object.material = new THREE.MeshPhongMaterial({
                        color: 0xFF426D,
                        opacity: 0.75,
                        transparent: true, 
                        side: THREE.DoubleSide,
                    })
                });
                if(!found) return
            
                if (button == "left") {
                    const [type, selected] = this.hole_selected > -1 ? ["holes", this.hole_selected] : ["turning", this.turning_selected]
                    if (type == "holes") delete viewer.faceMaterials[this.h.face]
                    else if (type == "turning") delete viewer.faceMaterials[this.t.face]

                    this.$store.commit("changeSubprocessListValue", ["inside", type, selected, 'face', mesh.object.name])
                    viewer.faceMaterials[found.object.name] = viewer.material_get("selected", this.values[type][selected].color)
                    this.dimensionsFromShape(mesh.object.information)
                    viewer.resetMaterials()
                }
                // if (button == "left") {
                    //     const [type, selected] = this.hole_selected > -1 ? ["holes", this.hole_selected] : ["turning", this.turning_selected]
                //     viewer.faceMaterials[found.object.name] = viewer.material_get("selected", this.values[type][selected].color)
                //     this.dimensionsFromShape(mesh.object.information)
                // }
                else {
                    delete viewer.faceMaterials[found.object.name]
                }
                console.log(viewer.faceMaterials);
                this.setTransparency()                        
                // mesh.object.material.color.set(0x004200);
                // console.log(mesh);
                // setTimeout(() => {
                //     mesh.object.material.color.set(0x00426D);
                // }, 500)
            }


            if (state) {
                this.setTransparency()
            }
            else {
                viewer.resetMaterials()
            }
        }
    },
    watch: {
        hole_selected() {
            // var viewer = this.$store.getters.viewer
            if (this.hole_selected != -1) {
                this.turning_selected = -1
                this.insideVisibility(1)
            } 
        },
        turning_selected() {
            // var viewer = this.$store.getters.viewer
            if (this.turning_selected != -1) {
                this.hole_selected = -1
                this.insideVisibility(1)
            }
        }
    }
}
</script>

<style scoped>
    /* #box>.card:first-child{margin-bottom: 2em;}
    #box>.card{width: fit-content;}
    #gridContainer{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 2em;
    }
    #gridContainer>div:nth-child(2)>img{
        width: 18em;
    } */
    /* img{
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        height: 12em;
        width: 12em;
        margin: 1em auto;
    } */
    .infoGrid{
        display: grid;
        grid-template-columns: auto auto auto;
        grid-column-gap: .5em;
        grid-row-gap: .25em;
        border-left: 4px solid;
        padding-left: 8px;
        border-radius: 4px 0 0 4px;
    }
</style>