<template>

    <div id="container">
        <div class="padding glass">            
            <b>Schlichtflächen</b>
            <div class="flex gridGap">
                <div class="flex padding_small radius" v-for="(t, i) in values.areas" :key="i">
                    <img @click="area_selected = i" class="radius_small pointer" :style="{backgroundColor: t.color}" src="https://dashboard.pixelprime.de/assets/icons/categories/interaction_clickmap.svg" title="Fläche auswählen">
                </div>
                <div id="areaAdd" class="dark padding_small pointer radius flex fitContent" @click="addFinisharea()">
                    <img style="height: 1.5em; width: 1.5em; margin:auto" src="https://dashboard.pixelprime.de/assets/icons/add.svg" alt="">
                    <p class="marginAuto">Fläche hinzufügen</p>
                </div>
            </div>

        </div>
        <div id="settings" :style="$store.getters.settingsPosition" class="padding glass">
            <div v-if="g">
                <b class="textspacing">Fläche {{1+area_selected}}:</b>
                <div class="infoGrid indent" :style="{borderColor: g.color}">
                    <p>Anzahl:</p>
                    <input type="number" min="1" :value="g.count" @input="changeFinishareaProperty(area_selected, 'count', $event.target.value)">
                    <p></p>
                    <p>Durchmesser:</p>
                    <input type="number" min="0.1" :value="g.diameter" @input="changeFinishareaProperty(area_selected, 'diameter', $event.target.value)">
                    <p>mm</p>
                    <p>Länge:</p>
                    <input type="number" min="0.1" :value="g.length" @input="changeFinishareaProperty(area_selected, 'length', $event.target.value)">
                    <p>mm</p>
                    <p title="Vorschub">f:</p>
                    <input type="number" min="0.01" step="0.01" :value="g.f" @input="changeFinishareaProperty(area_selected, 'f', $event.target.value)">
                    <p>mm/U</p>
                    <p>Rauheit Rz:</p>
                    <input type="number" min="1" :value="g.rz" @input="changeFinishareaProperty(area_selected, 'rz', $event.target.value)">
                    <p>µm</p>
                    <p>Schneidradius:</p>
                    <input type="number" min="0.1" step="0.1" :value="g.r" @input="changeFinishareaProperty(area_selected, 'r', $event.target.value)">
                    <p>mm</p>
                </div>
                <img class="pointer" @click="removeFinisharea(area_selected)" src="https://dashboard.pixelprime.de/assets/icons/delete.svg" alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            area_selected: -1
        }
    },
    computed: {
        values() {
            // let values = this.$store.getters.subProcess()
            // if(!values.areas) this.$store.commit("pushSubprocessValue", ["areas", "areas", []])
            return this.$store.getters.subProcess()
        },  
        g() {
            if(this.area_selected < 0) return 0
            return this.values.areas[this.area_selected]
        },
        verify() {
            return this.$store.getters.verify.processes[0].subProcesses["finishing"]
        },  
    },
    methods: {  
        addFinisharea() {
            this.$store.commit("pushSubprocessValue", ["finishing", "areas", {
                length: false, 
                diameter: false, 
                depth: false,
                f: 0.32,
                r: 0.8,
                rz: 16,
                count: 1,
                color: this.$store.getters.color()
            }])
            this.area_selected = this.values.areas.length-1
            this.$store.dispatch("refresh")
        },
        removeFinisharea(i) {
            this.$store.commit("deleteSubprocessList", ["finishing", "areas", i])
            this.$store.dispatch("refresh")
        },
        changeFinishareaProperty(tIndex, key, value) {
            this.$store.commit("changeSubprocessListValue", ["finishing", "areas", tIndex, key, value])
            if (key == "f") {
                const rz = 1000*value**2/8/this.g.r
                this.$store.commit("changeSubprocessListValue", ["finishing", "areas", this.area_selected, 'rz', Math.ceil(rz)])
            }
            else if (key == "rz" || key == "r") {
                const f = (8*this.g.r*this.g.rz/1000)**0.5
                this.$store.commit("changeSubprocessListValue", ["finishing", "areas", this.area_selected, 'f', Math.round(100*f)/100])
            }

            this.$store.dispatch("refresh")
        },
        dimensionsFromShape(info) {
            if (info[0].type == "Cylinder") {
                let diameter = Math.round(2*info[0].radius*100)/100
                let length = Math.round(info[0]["V"][1]-info[0]["V"][0]*100)/100
                this.$store.commit("changeSubprocessListValue", ["finishing", "areas", this.area_selected, 'diameter', diameter])
                this.$store.commit("changeSubprocessListValue", ["finishing", "areas", this.area_selected, 'inside', info[0].orientation])
                this.$store.commit("changeSubprocessListValue", ["finishing", "areas", this.area_selected, 'length', length])
                this.$store.dispatch("refresh")
            }
            else {
                console.log(info[0]);
            }
        }
    },
    watch: {
        area_selected() {
            var viewer = this.$store.getters.viewer
            if (this.area_selected == -1) delete viewer.onHover
            else {
                viewer.onHover = (mesh) => {
                    viewer.resetMaterials()
                    if(viewer.mouseDown) return
                    mesh.object.material = viewer.material_get("hover")
                    // console.log(mesh);
                    // setTimeout(() => {
                    //     mesh.object.material.color.set(0x00426D);
                    // }, 500)
                }
                viewer.onClick = (mesh, button) => {
                    if (button == "left") {
                        delete viewer.faceMaterials[this.g.face]
                        viewer.faceMaterials[mesh.object.name] = viewer.material_get("selected", this.values.areas[this.area_selected].color)
                        this.dimensionsFromShape(mesh.object.information)

                        this.$store.commit("changeSubprocessListValue", ["finishing", "areas", this.area_selected, 'face', mesh.object.name])
                    }
                    else {
                        delete viewer.faceMaterials[mesh.object.name]
                    }
                    viewer.resetMaterials()                        
                    // mesh.object.material.color.set(0x004200);
                    // console.log(mesh);
                    // setTimeout(() => {
                    //     mesh.object.material.color.set(0x00426D);
                    // }, 500)
                }
                
            }   
        }
    }
}
</script>

<style scoped>
    .infoGrid{
        display: grid;
        grid-template-columns: auto auto auto;
        grid-column-gap: .5em;
        grid-row-gap: .25em;
        border-left: 4px solid;
        padding-left: 8px;
        border-radius: 4px 0 0 4px;
    }

    #gridContainer{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 2em;
    }

    .toolGrid>div{
        display: grid;
        grid-template-columns: 2em 1fr 4em;
        margin-bottom: 4px;
    }
</style>