<template>

    <div id="container">
        <div class="padding glass">            
            <b>Nuten</b>
            <div class="flex gridGap">
                <div class="flex padding_small radius" v-for="(t, i) in values.grooves" :key="i">
                    <img @click="groove_selected = i" class="radius_small pointer" :style="{backgroundColor: t.color}" src="https://dashboard.pixelprime.de/assets/icons/categories/interaction_clickmap.svg" title="Fläche auswählen">
                </div>
                <div id="grooveAdd" class="dark padding_small pointer radius flex fitContent" @click="addGroove()">
                    <img style="height: 1.5em; width: 1.5em; margin:auto" src="https://dashboard.pixelprime.de/assets/icons/add.svg" alt="">
                    <p class="marginAuto">Nut hinzufügen</p>
                </div>
            </div>

        </div>
        <div id="settings" :style="$store.getters.settingsPosition" class="padding glass">
            <div v-if="g">
                <b class="textspacing">Nut {{1+groove_selected}}:</b>
                <div class="infoGrid indent" :style="{borderColor: g.color}">
                    <p>Anzahl:</p>
                    <input type="number" min="1" :value="g.count" @input="changeGrooveProperty(groove_selected, 'count', $event.target.value)">
                    <p></p>
                    <p>Durchmesser:</p>
                    <input type="number" step=".5" min="0" :value="g.diameter" @input="changeGrooveProperty(groove_selected, 'diameter', $event.target.value)">
                    <p>mm</p>
                    <p>Tiefe:</p>
                    <input type="number" step=".5" min="0" :value="g.depth" @input="changeGrooveProperty(groove_selected, 'depth', $event.target.value)">
                    <p>mm</p>
                    <p>Breite:</p>
                    <input type="number" step=".5" min="0" :value="g.width" @input="changeGrooveProperty(groove_selected, 'width', $event.target.value)">
                    <p>mm</p>
                    <p title="Breite des Werkzeugs">Stechbreite:</p>
                    <input type="number" step=".1" min=".1" :value="g.toolWidth" @input="changeGrooveProperty(groove_selected, 'toolWidth', $event.target.value)">
                    <p>mm</p>
                    <p title="Vorschub">f:</p>
                    <input type="number" step="0.05" :value="g.f" @input="changeGrooveProperty(groove_selected, 'f', $event.target.value)">
                    <p> mm/U</p>
                    <p title="Schnittgeschwindigkeit">vc:</p>
                    <input type="number" step="10" :value="g.vc" @input="changeGrooveProperty(groove_selected, 'vc', $event.target.value)">
                    <p> m/min</p>
                </div>
                <img class="pointer" @click="removeGroove(groove_selected)" src="https://dashboard.pixelprime.de/assets/icons/delete.svg" alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            groove_selected: -1
        }
    },
    computed: {
        values() {
            // let values = this.$store.getters.subProcess()
            // if(!values.grooves) this.$store.commit("pushSubprocessValue", ["grooves", "grooves", []])
            return this.$store.getters.subProcess()
        },  
        g() {
            if(this.groove_selected < 0) return 0
            return this.values.grooves[this.groove_selected]
        },
        verify() {
            return this.$store.getters.verify.processes[this.$store.state.process_selected].subProcesses[this.$store.state.subProcess_selected]
        },  
    },
    methods: {  
        addGroove() {
            this.$store.commit("pushSubprocessValue", ["grooves", "grooves", {
                width: false, 
                diameter: false, 
                depth: false, 
                f: 0.05, 
                toolWidth: 1,
                vc: 130,
                count: 1,
                color: this.$store.getters.color()
            }])
            this.groove_selected = this.values.grooves.length-1
            this.$store.dispatch("refresh")
        },
        removeGroove(i) {
            this.$store.commit("deleteSubprocessList", ["grooves", "grooves", i])
            this.$store.dispatch("refresh")
        },
        changeGrooveProperty(tIndex, key, value) {
            this.$store.commit("changeSubprocessListValue", ["grooves", "grooves", tIndex, key, value])
            this.$store.dispatch("refresh")
        },
        dimensionsFromShape(info) {
            if (info[0].type == "Cylinder") {
                let diameter = Math.round(2*info[0].radius*1000)/1000
                let width = Math.round(info[0]["V"][1]-info[0]["V"][0]*1000)/1000
                this.$store.commit("changeSubprocessListValue", ["grooves", "grooves", this.groove_selected, 'diameter', diameter])
                this.$store.commit("changeSubprocessListValue", ["grooves", "grooves", this.groove_selected, 'inside', info[0].orientation])
                this.$store.commit("changeSubprocessListValue", ["grooves", "grooves", this.groove_selected, 'width', width])
                this.$store.dispatch("refresh")
            }
            else {
                console.log(info[0]);
            }
        }
    },
    watch: {
        groove_selected() {
            var viewer = this.$store.getters.viewer
            if (this.groove_selected == -1) delete viewer.onHover
            else {
                viewer.onHover = (mesh) => {
                    viewer.resetMaterials()
                    if(viewer.mouseDown) return
                    mesh.object.material = viewer.material_get("hover")
                    // console.log(mesh);
                    // setTimeout(() => {
                    //     mesh.object.material.color.set(0x00426D);
                    // }, 500)
                }
                viewer.onClick = (mesh, button) => {
                    if (button == "left") {
                        delete viewer.faceMaterials[this.g.face]
                        viewer.faceMaterials[mesh.object.name] = viewer.material_get("selected", this.values.grooves[this.groove_selected].color)
                        this.dimensionsFromShape(mesh.object.information)

                        this.$store.commit("changeSubprocessListValue", ["grooves", "grooves", this.groove_selected, 'face', mesh.object.name])
                    }
                    else {
                        delete viewer.faceMaterials[mesh.object.name]
                    }
                    viewer.resetMaterials()                        
                    // mesh.object.material.color.set(0x004200);
                    // console.log(mesh);
                    // setTimeout(() => {
                    //     mesh.object.material.color.set(0x00426D);
                    // }, 500)
                }
                
            }   
        }
    }
}
</script>

<style scoped>
    .infoGrid{
        display: grid;
        grid-template-columns: auto auto auto;
        grid-column-gap: .5em;
        grid-row-gap: .25em;
        border-left: 4px solid;
        padding-left: 8px;
        border-radius: 4px 0 0 4px;
    }

    #gridContainer{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 2em;
    }

    .toolGrid>div{
        display: grid;
        grid-template-columns: 2em 1fr 4em;
        margin-bottom: 4px;
    }
</style>