import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueMeta from 'vue-meta'

import pcMenu from "./components/Menu.vue";
import pcSummary from "./components/Summary.vue";
import login from "./components/Login.vue";
import setuptime from "./components/Setuptime.vue";
import loading from "./components/Loading.vue";
import search from "./components/Search.vue";
import searchlist from "./components/Searchlist.vue";
import counter from "./components/Counter.vue";
import indicator from "./components/Indicator.vue";
import pcInput from "./components/PcInput.vue";
Vue.component('pc-menu', pcMenu);
Vue.component('pc-summary', pcSummary);
Vue.component('login', login);
Vue.component('setuptime', setuptime);
Vue.component('loading', loading);
Vue.component('search', search);
Vue.component('searchlist', searchlist);
Vue.component('counter', counter);
Vue.component('indicator', indicator);
Vue.component('pc-input', pcInput);

import ThreeViewer from "./components/three/ThreeViewer.vue";
Vue.component('viewer', ThreeViewer);
import Preview3D from "./components/three/Preview3D.vue";
Vue.component('preview3d', Preview3D);


import File3d from "./components/processes/turning/File3d.vue";
import FileDrawing from "./components/processes/turning/FileDrawing.vue";
import Contour from "./components/processes/turning/Contour.vue";
import ExtraFeatures from "./components/processes/turning/ExtraFeatures.vue";
import Grooves from "./components/processes/turning/Grooves.vue";
import Finishing from "./components/processes/turning/Finishing.vue";
import Inside from "./components/processes/turning/Inside.vue";
import InsideComplexity from "./components/processes/turning/InsideComplexity.vue";
import Dimensions from "./components/processes/turning/Dimensions.vue";
import Sides from "./components/processes/turning/Sides.vue";
import RadialHole from "./components/processes/turning/RadialHole.vue";
import Thread from "./components/processes/turning/Thread.vue";
import Keyway from "./components/processes/turning/Keyway.vue";
import Shapemilling from "./components/processes/turning/Shapemilling.vue";
Vue.component('step_turning', File3d);
Vue.component('pdf_turning', FileDrawing);
Vue.component('dimensions_turning',Dimensions);
Vue.component('contour_turning',Contour);
Vue.component('extra-features_turning',ExtraFeatures);
Vue.component('grooves_turning',Grooves);
Vue.component('finishing_turning',Finishing);
Vue.component('inside_turning',Inside);
Vue.component('inside-complexity_turning',InsideComplexity);
Vue.component('sides_turning',Sides);
Vue.component('radialHole_turning',RadialHole);
Vue.component('thread_turning', Thread);
Vue.component('keyway_turning', Keyway);
Vue.component('shapemilling_turning', Shapemilling);


import File3dMilling from "./components/processes/milling/File3d.vue";
Vue.component('step_milling', File3dMilling);
// Vue.component('pdf_milling', FileDrawing);
import DimensionsMilling from "./components/processes/milling/Dimensions.vue";
Vue.component('dimensions_milling', DimensionsMilling);
import RoughingMilling from "./components/processes/milling/Roughing.vue";
Vue.component('roughing_milling', RoughingMilling);
import FinishingMilling from "./components/processes/milling/Finishing.vue";
Vue.component('finishing_milling', FinishingMilling);
import HolesMilling from "./components/processes/milling/Holes.vue";
Vue.component('holes_milling', HolesMilling);
import ChamferMilling from "./components/processes/milling/Chamfer.vue";
Vue.component('chamfer_milling', ChamferMilling);
import ThreadMilling from "./components/processes/milling/Thread.vue";
Vue.component('thread_milling', ThreadMilling);
import HoningMilling from "./components/processes/milling/Honing.vue";
Vue.component('honing_milling', HoningMilling);
import PocketsMilling from "./components/processes/milling/Pockets.vue";
Vue.component('pockets_milling', PocketsMilling);
import GroovesMilling from "./components/processes/milling/Grooves.vue";
Vue.component('grooves_milling', GroovesMilling);
import TolerancesMilling from "./components/processes/milling/Tolerances.vue";
Vue.component('tolerances_milling', TolerancesMilling);
import SurfaceMilling from "./components/processes/milling/Surface.vue";
Vue.component('surface_milling', SurfaceMilling);


import materialDatabase from "./components/material/Database.vue";
Vue.component('material-database', materialDatabase);
// import AvailableMachines from "./components/machines/AvailableMachines.vue";
// import NewMachine from "./components/machines/NewMachine.vue";
// Vue.component('available-machines', AvailableMachines);
// Vue.component('new-machine', NewMachine);



//////////AWS
import Amplify, * as AmplifyModules from "aws-amplify";
import { AmplifyPlugin } from "aws-amplify-vue"; 
import { I18n } from 'aws-amplify';
import awsmobile from "@/aws-exports";

Amplify.configure(awsmobile);
Vue.use(AmplifyPlugin, AmplifyModules);

I18n.setLanguage('de');
I18n.putVocabularies({
    "de": {
        // 'Enter your Nutzername': "Geben Sie Ihren Nutzername ein",
        'Enter your Nutzername': "Geben Sie Ihre EMail-Adresse ein",
        'Nutzername': "EMail",
        'Sign In': "Anmelden",
        "Sign in to your account": "Anmelden",
        "Forgot your password?": "Passwort vergessen?",
        'Confirm': "Bestätigen",
        'Back to Sign In': "Anmelden",
        'Confirmation Code': "Bestätigungscode",
        'Resend Code': "Code erneut senden",
        'Lost your code? ': "Code verloren?",
        'Have an account? ': "",
        'Confirm Sign Up': "Registrierung bestätigen",
        'Valid username not received.': "Keinen bestätigten Nutzernamen erhalten",
        'New Password': "Neues Passwort",
        'Send Code': "Code senden",
        'Reset your password': "Passwort zurücksetzen",
        'Submit': "Absenden",
        'Phone number': "Telefonnummer",
        'Enter new password': "Neues Passwort eingeben",
        'Enter your password': "Passwort eingeben",
        'Forgot your password? ': "Passwort vergessen?",
        'Reset password': "Passwort zurücksetzen",
        'No account? ': " ",
        'Create account': " ",
        'Sign Out': "Abmelden",
        'Enter your email': "Geben Sie Ihre EMail Adresse ein.",
        'Username cannot be empty': "Geben Sie einen Nutzernamen an",
        "Incorrect username or password.": "EMail oder Passwort falsch",
        'Custom auth lambda trigger is not configured for the user pool.': ""
    }
});

// AWS.config.update({ 
//     region: 'eu-central-1' ,
//     aws_access_key_id: "AKIAYREDS43NIOYPXQFP",
//     aws_secret_access_key: "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",
// });
// setTimeout(() => {
    
//     // AWS.config.credentials = new AWS.CognitoIdentityCredentials({ IdentityPoolId: 'eu-central-1:64994a61-b110-49c4-ab9e-6b2292bafac8' });
//     // AWS.config.credentials.get(callback => {
//     // })
//     var s3 = new AWS.S3({ apiVersion: '2006-03-01' });
//     console.log(s3);
//     s3.getObject({ Bucket: "primecalc-files", Key: "1609845659522_PrimeCalc_stp" }, (err, data) => {
    
//         console.log(process.env);
//         console.log(err, data);
//         // console.log(new TextDecoder("utf-8").decode(data.Body))
//     })
// }, 2500);

// //  Bucket
// // AWS.config.credentials = {
// //     aws_access_key_id: "AKIAYREDS43NBC7VWQY7",
// //     aws_secret_access_key: "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"
// // }
// // AWS.config.loadFromPath('credentials.json');
// // new AWS.Credentials("AKIAYREDS43NBC7VWQY7", "vK6PD7KwJso8z2fSNbcn1p/pLZMfiZIzK6k2JmqB")
// // AWS.config.getCredentials(function (err) {
// //     if (err) console.log(err.stack);
// //     // credentials not loaded
// //     else {
// //         console.log("Access key:", AWS.config.credentials.accessKeyId);
// //     }
// // });
// // AWS.config.userPoolId = 'eu-central-1_7vcmLz29L'; // Region
// // AWS.config.userPoolWebClientId = '303jpp2vivtmk36gtk6m5nufrq'; // Region
// // AWS.config.credentials = new AWS.CognitoIdentityCredentials({
// //     IdentityPoolId: 'eu-central-1:64994a61-b110-49c4-ab9e-6b2292bafac8',
// // });
// // let cred = new AWS.CognitoIdentityCredentials({
// //     IdentityPoolId: 'eu-central-1:64994a61-b110-49c4-ab9e-6b2292bafac8',
// // });
// // console.log(process.env);
// // AWS.config.update({
// //     region: "eu-central-1",
// //     accessKeyId: "AKIAYREDS43NIOYPXQFP",
// //     accessSecretKey: "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",
// //     // credentials: cred

// // });

// AWS.config.update({ region: 'eu-central-1' });
// AWS.config.credentials = new AWS.CognitoIdentityCredentials({ IdentityPoolId: 'eu-central-1:64994a61-b110-49c4-ab9e-6b2292bafac8' });
// AWS.config.credentials.get(callback => {
//     console.log(callback);
// })


// var s3 = new AWS.S3({ apiVersion: '2006-03-01'});
// s3.getObject({ Bucket: "primecalc-files", Key: "1609964894622_PrimeCalc_stp"}, (err, data) => {
//     const step = "hi"//new TextDecoder("utf-8").decode(data.Body)
//     console.log(err, data);
//     console.log(step);
// })
// // s3.listBuckets(function (err, data) {
// //     if (err) {
// //         console.log("Error", err);
// //     } else {
// //         console.log("Success", data.Buckets);
// //     }
// // });
// // s3.listBuckets()



// // async function testAPI() {
// //     // return Vue.prototype.$Amplify.Auth.currentAuthenticatedUser().then((user) => {
// //     //     let jwtToken = user.signInUserSession.idToken.jwtToken;
// //     //     const options = {
// //     //         mode: "no-cors",
// //     //         headers: {
// //     //             Authorization: jwtToken
// //     //         }
// //     //     }
// //     //     Vue.prototype.$Amplify.API.post('primecalc_clientAccess', '/', options).then(response => {
// //     //         // this.setState({
// //     //         //     xyz: response.message
// //     //         // });
// //     //         return response;
// //     //     }).catch(error => {
// //     //         console.log(error.response)
// //     //     });

// //     // });



// // }


// // Vue.prototype.$Amplify.Auth.currentAuthenticatedUser().then(response=>{
// //     console.log(response);
// // })
// //////////AWS

// return Vue.prototype.$Amplify.API.post("primecalc_clientAccess", "/prod", myInit).then(response => response)





Vue.config.productionTip = true;
Vue.use(VueMeta)

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");


