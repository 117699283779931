<template>
    <div id="container">
        <div class="padding glass">
            <b>Schlichten</b>
            <div class="flex gridGap">
                <div class="flex padding_small radius" v-for="(t, i) in values.areas" :key="i">
                    <img @click="area_selected = i" class="radius_small pointer" :style="{backgroundColor: t.color}" src="https://dashboard.pixelprime.de/assets/icons/categories/interaction_clickmap.svg" title="Bohrung">
                </div>
                <div class="dark padding_small pointer radius flex fitContent" @click="addArea()">
                    <img style="height: 1.5em; width: 1.5em; margin:auto" src="https://dashboard.pixelprime.de/assets/icons/add.svg" alt="">
                    <p class="marginAuto nowrap">Flächen hinzufügen</p>
                </div>
            </div>

        </div>

        <div v-if="area_selected > -1" id="settings" :style="$store.getters.settingsPosition" class="padding glass">
            
            <b>Schlichten {{1+area_selected}}:</b>
            <div class="infoGrid indent" :style="{borderColor: a.color}">
                <p>Anzahl:</p>
                <input type="number" min="1" :value="a.count" @input="changeProperty(area_selected, 'count', $event.target.value)">
                <p></p>
                <!-- <p>Gewählte Flächen:</p>
                <p>{{faceCount}}</p>
                <p></p> -->
                <p>Fläche:</p>
                <input type="number" min="0.1" :value="a.area" @input="changeProperty(area_selected, 'area', $event.target.value)">
                <p>mm^2</p>

                <p title="Fräserdurchmesser">Durchmesser:</p> 
                <input type="number" step="0.1" :value="a.diameter" @input="changeProperty(area_selected, 'diameter', $event.target.value)">
                <p> mm</p>
                <p title="Schnittiefe">ap:</p> 
                <input type="number" step="0.1" :value="a.ap" @input="changeProperty(area_selected, 'ap', $event.target.value)">
                <p> mm</p>
                <p title="Schnittgeschwindigkeit">vc:</p>
                <input type="number" steps="10" :value="a.vc" @input="changeProperty(area_selected, 'vc', $event.target.value)">
                <p>m/min</p>
                <p title="Vorschub pro Schneide">fz:</p>
                <input type="number" min="0.05" :value="a.fz" @input="changeProperty(area_selected, 'fz', $event.target.value)">
                <p>mm/U</p>                    
                <p title="Zähnezahl">z:</p>
                <input type="number" min="1" :value="a.z" @input="changeProperty(area_selected, 'z', $event.target.value)">
                <p></p>
                    
            </div>
            <img class="pointer" @click="remove(area_selected)" src="https://dashboard.pixelprime.de/assets/icons/delete.svg" alt="">
            
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            area_selected: -1,
        }
    },
    methods: {
        changeProperty(tIndex, key, value) {
            this.$store.commit("changeSubprocessListValue", ["finishing", "areas", tIndex, key, value])
            this.$store.dispatch("refresh")
        },
        addArea() {
            this.$store.commit("pushSubprocessValue", ["finishing", "areas", {
                faces: {}, 
                area: false,
                ap: 20,
                fz: 0.12,
                diameter: 20,
                vc: 150,
                z: 4,
                count: 1,
                color: this.$store.getters.color()
            }])
            this.area_selected = this.values.areas.length-1
            this.$store.dispatch("refresh")
        },
        remove(i) {
            this.area_selected = -1
            this.interactivity(0)
            this.$store.commit("deleteSubprocessList", ["finishing", "areas", i])
            this.$store.dispatch("refresh")
        },
        interactivity(state) {
            let viewer = this.$store.getters.viewer
            if (!state) {
                delete viewer.onHover
                delete viewer.onClick
                return
            }

            viewer.onHover = (mesh) => {
                viewer.resetMaterials()
                if(viewer.mouseDown) return
                mesh.object.material = viewer.material_get("hover")
            }
            viewer.onClick = (mesh, button) => {
                if (button == "left") {
                    viewer.faceMaterials[mesh.object.name] = viewer.material_get("selected", this.values.areas[this.area_selected].color)
                    this.dimensionsFromShape(mesh.object)
                }
                else {
                    delete viewer.faceMaterials[mesh.object.name]
                    this.dimensionsFromShape(mesh.object, 1)
                }
                viewer.resetMaterials()     
            }
        },
        dimensionsFromShape(object, remove=0) {
            console.log(object.information);
            const index = object.name.replace('face_', '')
            if(remove) {
                delete this.a.faces[index]
                this.changeProperty(this.area_selected, "faces", this.a.faces)
            }
            else {
                this.a.faces[index] = object.information[0].area
                this.changeProperty(this.area_selected, "faces", this.a.faces)
            }
            let area = 0
            for (const i in this.a.faces) {
                area += this.a.faces[i]
            }
            this.changeProperty(this.area_selected, "area", Math.round(10*Math.max(area))/10)
        }
    },
    computed: {
        values() {
            return this.$store.getters.subProcess("finishing")
        },
        a() {
            if(this.area_selected == -1) return 0
            return this.values.areas[this.area_selected]
        },
        verify() {
            return this.$store.getters.verify.processes[0].subProcesses["finishing"]
        },
    },
    watch: {
        area_selected() {
            // var viewer = this.$store.getters.viewer
            if (this.area_selected != -1) {
                this.interactivity(1)
            } 
        }
    }
}
</script>

<style scoped>

    .infoGrid{
        display: grid;
        grid-template-columns: auto auto auto;
        grid-column-gap: .5em;
        grid-row-gap: .25em;
        border-left: 4px solid;
        padding-left: 8px;
        border-radius: 4px 0 0 4px;
    }
</style>
