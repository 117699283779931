
import Vue from "vue";
// import store from '@/store/index.js'
const calculation = {
    state: {
        verify: {
            material: {
                messages: {}
            },
            processes: {
                messages: {}
            },
            machines: {
                messages: {}
            },
            tools: {
                messages: {}
            },
            setup: {
                messages: {}
            },
            other: {
                messages: {}
            }
        },
        verify_opened: {
            material: 0,
            processes: 0,
            machines: 0,
            tools: 0,
            setup: 0,
            other: 0
        },
        material: {
            demand: 0
        },
        machines: {
            name: ""
        },
        toolcost: "",
        manufacturing: {
            time: "",
            cost: "",
        },
        setup:{
            time: ""
        },
        other: {
            individual: 0,
            overhead: 0,
        },
        cost: {}
    },
    mutations: {
        setVerifyMessage(state, data) {
            let [type, message, condition] = data
            Vue.set(state.verify[type].messages, message, condition ? 1 : 0)
        },
        setVerifyOpened(state, category) {
            Vue.set(state.verify_opened, category, 1)
        }
    },
    actions: {
        calculate() {
            this.dispatch("clearVerify")
            this.dispatch("processes_evaluate")
            this.commit("tools_evaluate", [this.getters.tools_raw])
            this.dispatch("material_calculate")
            this.dispatch("manufacturing_calculate")
            this.dispatch("cost_calculate")
        },
        clearVerify(context) {
            for (const key in context.state.verify) {
                Vue.set(context.state.verify, key, {messages: {}})
            }
        },
        material_calculate(context) {
            const lotsize_customer = context.rootState.calculation.information.lotsize
            if (!context.rootState.calculation.other.rejected) context.rootState.calculation.other.rejected = 0
            const lotsize = lotsize_customer * (1 + context.rootState.calculation.other.rejected / 100) 
            const type = context.rootState.calculation.material.type
            if (!context.rootState.calculation.material.name) {
                this.commit("setVerifyMessage", ["material", "Wählen Sie ein Material.", 1])
            }
            if (type == "rod" || type == "m") {
                let length = (3 + parseInt(context.getters.subProcess("dimensions").length))/1000
                this.commit("setVerifyMessage", ["material", "Setzen Sie eine Losgröße.", !lotsize])
                let price_meter = context.rootState.calculation.material.price_meter
                let meters = length * lotsize
                if (type == "m") {
                    this.commit("setVerifyMessage", ["material", "Wählen Sie einen Meterpreis.", !price_meter])
                    context.state.material.demand = meters+"m"
                    context.state.material.cost = meters * price_meter
                }
                else if(type == "rod"){
                    let rodlength = context.rootState.calculation.material.rod_length
                    let remainder = context.rootState.calculation.material.rod_remainder/100
                    let price_rod = context.rootState.calculation.material.price_rod
                    this.commit("setVerifyMessage", ["material", "Wählen Sie einen Stangenpreis.", !price_rod])
                    this.commit("setVerifyMessage", ["material", "Wählen Sie eine Stangenlänge.", !rodlength])
                    context.state.material.demand = (meters / (rodlength - remainder)).toFixed(1) + " Stangen"
                    context.state.material.cost = (meters / (rodlength - remainder) * price_rod).toFixed(2)
                }
                else if(type == "m") {
                    let price_m = context.rootState.calculation.material.price_m
                    this.commit("setVerifyMessage", ["material", "Wählen Sie einen Materialpreis.", !price_m])
                    context.state.material.demand = (meters).toFixed(1) + " m"
                    context.state.material.cost = (meters * price_m).toFixed(2)

                }
            }
            else if (type == "piece") {
                let price_piece = context.rootState.calculation.material.price_piece
                this.commit("setVerifyMessage", ["material", "Wählen Sie einen Materialpreis.", !price_piece])
                context.state.material.demand = lotsize
                context.state.material.cost = lotsize*price_piece
            }
            else if (!type) {
                this.commit("setVerifyMessage", ["material", "Wählen Sie einen Materialtyp und Preis.", 1])                
            }
        },
        manufacturing_calculate(context) {
            let processes = context.rootState.calculation.processes
            let lotsize = context.rootState.calculation.information.lotsize
            if (!context.rootState.calculation.other.rejected) context.rootState.calculation.other.rejected = 0
            lotsize *= 1 + context.rootState.calculation.other.rejected/100 
            //  Check Machines
            if(!processes.length) {
                this.commit("setVerifyMessage", ["machines", "Fügen Sie zuerst Prozesse hinzu", 1])
                return
            }
            if (!processes[0].machine.name) {
                this.commit("setVerifyMessage", ["machines", "Wählen Sie einen Maschine Namen.", 1])
                return
            }
            processes.forEach(process => {
                context.state.machines.name = process.machine.name
                if (process.machine.machinecost_hourly == "" || process.machine.machinecost_hourly == undefined) {
                    this.commit("setVerifyMessage", ["machines", `Wählen Sie einen Stundensatz für Maschine: ${process.machine.name}.`, 1])
                }
                else context.state.machines.machinecost_hourly = process.machine.machinecost_hourly
                if (process.machine.toolchange_time == "" || process.machine.toolchange_time == undefined) {
                    this.commit("setVerifyMessage", ["machines", `Wählen Sie einen Werkzeugwechselzeit für Maschine: ${process.machine.name}.`, 1])
                }
            });
            //  Processes Time and Toolcost
            let time = context.getters.time
            let toolcost = 0
            let tools = context.rootState.calculation.tools
            let toolcount = 0
            for (const t in tools) {
                if(tools[t].used == 0) continue
                toolcount++
                //  Cost
                if (!tools[t].tool_price) this.commit("setToolValue", [t, "tool_price", 5])
                if (!tools[t].tool_life) this.commit("setToolValue", [t, "tool_life", 45])
                toolcost += (tools[t].time / tools[t].tool_life * tools[t].tool_price)
            }
            context.state.manufacturing.time = (time / 3600 * lotsize).toFixed(1)
            context.state.manufacturing.cost = (time / 3600 * lotsize * context.state.machines.machinecost_hourly).toFixed(2)
            context.state.toolcost = toolcost.toFixed(2)
            //  Setuptime
            let time_perTool = context.rootState.calculation.setup.time_perTool
            let cost_hourly = context.rootState.calculation.setup.cost_hourly
            let programmingtime = context.rootState.calculation.setup.programmingtime
            let otherTime = context.rootState.calculation.setup.otherTime
            if (time_perTool == "" || time_perTool == undefined) {
                this.commit("setVerifyMessage", ["setup", "Wählen Sie Rüstzeiten für die Werkzeuge.", 1])                
            }
            else context.state.setup.time = toolcount * context.rootState.calculation.setup.time_perTool
            if (programmingtime) context.state.setup.time += parseFloat(programmingtime)
            if (otherTime) context.state.setup.time += parseFloat(otherTime)
            if (cost_hourly == "" || cost_hourly == undefined) {
                this.commit("setVerifyMessage", ["setup", "Geben Sie Einen Rüstkostensatz an.", 1])
            }
            else context.state.setup.cost = (cost_hourly * context.state.setup.time / 60).toFixed(2)
            //  Other
            let [other_individual, other_overhead, other_margin] = [context.rootState.calculation.other.individual, context.rootState.calculation.other.overhead, context.rootState.calculation.other.margin]
            if (other_individual == "") other_individual = 0
            if (other_overhead == "") other_overhead = 0
            if (other_margin == "") other_margin = 0
            context.state.other.individual = other_individual
            context.state.other.overhead = other_overhead
            context.state.other.margin = context.rootState.calculation.other.margin
            context.state.other.discount = context.rootState.calculation.other.discount
            context.state.other.cashdiscount = context.rootState.calculation.other.cashdiscount

        },
        cost_calculate(context) {
            let lotsize_customer = context.rootState.calculation.information.lotsize
            let lotsize_manufacured = lotsize_customer * (1 + context.rootState.calculation.other.rejected / 100) 

            let s = context.state
            let cost_manufacturing = parseFloat(s.material.cost) + parseFloat(s.manufacturing.cost) + parseFloat(s.setup.cost) + parseFloat(s.toolcost)
            cost_manufacturing += parseFloat(s.other.individual) * lotsize_manufacured + parseFloat(s.other.overhead)
            s.other.cashdiscount = s.other.cashdiscount || 0
            s.other.discount = s.other.discount || 0
            Vue.set(context.state.cost, "manufacturing", cost_manufacturing.toFixed(2))
            if (s.other.margin == "" || s.other.margin == undefined) this.commit("setVerifyMessage", ["other", "Geben Sie einen Gewinn an", 1])
            let price_margin = cost_manufacturing*(1+s.other.margin/100)
            let price_discount = price_margin / (1 - s.other.discount/100 - s.other.cashdiscount/100)
            Vue.set(context.state.cost, "total", (price_discount).toFixed(2))
            Vue.set(context.state.cost, "piece", (price_discount / lotsize_customer).toFixed(2))
        }
    },
    getters: {
        calcInfo: (state) => state,
        verifiedSteps: (state, getters, rootState) => {
            let verified = {}
            for (const key in state.verify) {
                verified[key] = ""
                for (const msg in state.verify[key].messages) {
                    if (state.verify[key].messages[msg]) {
                        verified[key] += msg+" \n "
                    }
                }
            }
            //  Processes
            // verified.processes = ""
            if (!rootState.calculation.processes.length) {
                verified.processes = "Noch keine Prozesse definiert"
            }
            // else {
            //     const dict = (c) => { return rootState.dict[c] || c}
            //     let processVerify = getters.verify
            //     processVerify.processes.forEach((process, p) => {
            //         if (process.met == 0) {
            //             verified.processes += "    " + dict(rootState.calculation.processes[p].type)+"\n"
            //         }
            //     });
            //     if (verified.processes) verified.processes = "Definieren Sie noch folgende Prozesse:\n" + verified.processes
            // }

            return verified
        },
        verifyOpened: (state) => state.verify_opened,
        cost_piece: (state) => state.cost.piece.toFixed(2)
    }
}

export default calculation