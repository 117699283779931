<template>
    <div id="container">
        <div class="padding glass flex gridGap_large">
            <div id="dimensioning">
                <!-- <img src="https://development.primecalc.de/assets/images/dimension.png" alt=""> -->
                <div style="height: fit-content; margin: auto">
                    <p class="textspacing"><b>Bauteilmaße</b></p>
                    <div class="spacingbottom indent dimGrid">
                        <p class="spacingright">Bauteillänge:</p>
                        <input type="number" :value="values.length" @input="setValue('length', $event.target.value)" min=1>
                        <p>mm</p>
                        <p class="spacingright">Durchmesser:</p>
                        <input type="number" :value="values.diameter" @input="setValue('diameter', $event.target.value)" min=1>
                        <p>mm</p>
                    </div>
                </div>
                <!-- <p></p>
                <p v-if="verify" @click="$store.dispatch('subProcesses_next')" class="blue padding_small radius fitContent pointer" style="margin: .5em 0 0 auto;">weiter</p> -->
            </div>
            <!-- <div id="subProcessOptions" class="card" v-if="subProcess_selected">
                <h3 class="card_title">Nötige Prozessparameter</h3>
            </div> -->
            <div>
                <p class="textspacing"><b>Halbzeugmaße</b></p>
                <div class="indent dimGrid">
                    <p class="spacingright">Halbzeuglänge:</p>
                    <input type="number" :value="values.raw_length" @input="setValue('raw_length', $event.target.value)">
                    <p>mm</p>
                    <p class="spacingright">Halbzeugdurchmesser:</p>
                    <input type="number" :value="values.raw_diameter" @input="setValue('raw_diameter', $event.target.value)">
                    <p>mm</p>
                </div>
            </div>

            <!-- <div class="card" v-if="verify">
                <h3 class="card_title">Prozess genauer definieren</h3>
            </div> -->
        </div>
        
    </div>
</template>

<script>
export default {
    methods: {
        setValue(key, value) {
            switch (key) {
                case "length":
                    value <= 0 ? value = 1 : ""
                    break;
                case "diameter":
                    value <= 0 ? value = 1 : ""
                    break;
                // case "raw_length":
                //     value < 0 ? value = 1 : ""
                //         console.log(parseFloat(value) , parseFloat(this.values.length));
                //         parseFloat(value) < parseFloat(this.values.length) ? value = this.values.length : ""
                //         console.log(value);
                //     break;
                // case "raw_diameter":
                //         value < 0 ? value = 1 : ""
                //         parseFloat(value) < parseFloat(this.values.diameter) ? value = this.values.diameter : ""
                //     break;
            }
            this.$store.commit("changeSubprocessValue", ["dimensions", key, value]);
            this.$store.dispatch("refresh")
        }
    },
    computed: {
        values() {
            let values = this.$store.getters.subProcess()
            console.log(this.step);
            if (values.diameter === "" && this.step.dimensions) {
                values.diameter = Math.round(this.step.dimensions.value.diameter)
                this.$store.commit("changeSubprocessValue", ["dimensions", "diameter", values.diameter]);
            }
            if (values.length === "" && this.step.dimensions) {
                values.length = Math.round(this.step.dimensions.value.length)
                this.$store.commit("changeSubprocessValue", ["dimensions", "length", values.length]);
            }
            return values
        },
        step() {
            return this.$store.getters.subProcess("step")
        },
        subProcess_selected() {
            return this.$store.state.subProcess_selected
        },
        verify() {
            return this.$store.getters.verify.processes[this.$store.state.process_selected].subProcesses[this.$store.state.subProcess_selected]
        },
    }
}
</script>

<style scoped>
    #box>.card:first-child{margin-bottom: 2em;}
    #box>.card{width: fit-content;}
    #dimensioning{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    img{
        margin: auto;
        width: auto;
        /* height: 100%; */
        height: 10em;

    }
    .dimGrid{
        display: grid;
        grid-gap: 4px;
        grid-template-columns: 2fr 4em 1fr;
    }
</style>

    // <div id="box">
    //     <div id="subProcessOptions" class="card" v-if="subProcess_selected">
    //         <h3 class="padding_small dark radius">Nötige Prozessparameter</h3>
    //         <div class="padding" id="dimensioning">
    //         </div>
    //     </div>

    //     <div id="manufacturingtime" class="card">
    //         <h3 class="card_title padding_small dark radius">Prozess genauer definieren</h3>
    //         <div class="padding">
    //             <component :is="subProcess_selected+'-evaluation'"></component>
    //         </div>
    //     </div>
    // </div>
